import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Modal from "react-modal";
import { Checkbox } from 'antd';
import { Button, Col, Form, InputGroup, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BottomModal } from "../../Modal/BottomModal";
import ModalHeard from "../../Modal/ModalHeard";
import { FormLabelModal } from "../../Modal/LabelModal";
import InputMask from "react-input-mask";

@inject("ClienteStores")
@observer
class ModalEditarCliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false
    };
  }

  handleCloseModal = () => {
    this.setState({ isActive: false });
  };
  handleOpenModal = () => {
    this.props.ClienteStores.valueDefalutEditar(this.props.user);
    this.setState({ isActive: true });
  };

  handleUpdateClienteCloseModal = e => {
    e.preventDefault();
    if (this.props.ClienteStores.ChecksInputs()) {
      this.props.ClienteStores.UpdateClientesId(this.props.user.id);
      this.setState({ isActive: false });
    }
  };

  render() {
    const {
      first_name,
      last_name,
      email,
      phone,
      onChange,
      is_active,
      setISActive
    } = this.props.ClienteStores;

    return (
      <>
        <ButtonGroup aria-label="Basic example">
          <Button
            style={{ fontSize: "10px" }}
            size="sm"
            variant="warning"
            onClick={() => this.handleOpenModal()}
            title="EDITAR REGISTRO"
          >
            <FontAwesomeIcon style={{ color: "white" }} icon={"edit"} />{" "}
          </Button>
        </ButtonGroup>
        <Modal
          className="Modal_Load"
          style={{
            content: { width: "40vw", height: "650px", left: "47%", top: "36%" }
          }}
          overlayClassName="Overlay_Load"
          isOpen={this.state.isActive}
        >
          <ModalHeard
            icon={"user-tie"}
            titulo={"EDITAR CLIENTE"}
            onClick={() => this.handleCloseModal()}
          />
          <br />
          <div>
            <Col md={12} style={{ marginTop: "1%" }}>
              <Form>
                <Form.Group controlId="formBasicEmail">
                  {FormLabelModal("nome do cliente")}
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon={"user-circle"} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      name={"first_name"}
                      type="text"
                      placeholder="nome"
                      defaultValue={first_name}
                      onChange={e => onChange(e)}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  {FormLabelModal("sobrenome")}
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon={"user-circle"} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      name={"last_name"}
                      type="text"
                      placeholder="sobrenome"
                      defaultValue={last_name}
                      onChange={e => onChange(e)}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  {FormLabelModal("email")}
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        @
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      name={"email"}
                      type="email"
                      placeholder="email@email.com"
                      defaultValue={email}
                      onChange={e => onChange(e)}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  {FormLabelModal("password")}
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        **
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      name={"password"}
                      type="text"
                      placeholder="password"
                      onChange={e => onChange(e)}
                    />
                  </InputGroup>
                </Form.Group>

                {FormLabelModal("telefone")}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%"
                  }}
                >
                  <div style={{ display: "flex", width: "100%" }}>
                    <InputGroup.Text
                      style={{ height: "41px", width: "42px" }}
                      id="inputGroupPrepend"
                    >
                      <FontAwesomeIcon icon="phone-square" />
                    </InputGroup.Text>

                    <InputMask
                      name={"phone"}
                      style={{
                        marginLeft: "-0.7%",
                        width: "100%",
                        border: "1.2px",
                        borderRadius: "4px",
                        height: "41px",
                        borderColor: "#ced4da",
                        borderStyle: "solid"
                      }}
                      mask="(99) 99999-9999"
                      maskChar={null}
                      placeholder="  telefone..."
                      defaultValue={phone}
                      onChange={e => onChange(e)}
                    />
                  </div>
                </div>

                <div style={{
                  textAlign: "right",
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: 'space-between'
                }}>
                  <Checkbox
                    checked={is_active}
                    onChange={setISActive}
                  >
                    {is_active ? "Ativo" : "Inativo"}
                  </Checkbox>

                  <div>
                    <Button
                      variant="outline-success"
                      size="sm"
                      onClick={e => this.handleUpdateClienteCloseModal(e)}
                    >
                      Cadastrar
                    </Button>
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() => this.handleCloseModal()}
                      style={{ marginLeft: "10px" }}
                    >
                      Fechar
                    </Button>
                  </div>
                </div>
              </Form>
            </Col>
          </div>
          {BottomModal}
        </Modal>
      </>
    );
  }
}

export default ModalEditarCliente;
