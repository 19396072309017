import React from "react";
import ReactDOM from "react-dom";
import {LoadingOverlay, LoadingQuadrado, LoadingTexto, LoadingWrapper} from "./style";

export const Loading = () => {
    return (
        ReactDOM.createPortal(
            <>

                <> <LoadingOverlay/>
                    <LoadingWrapper>
                        <LoadingQuadrado/>
                        <LoadingTexto><b>CARREGANDO</b></LoadingTexto>
                    </LoadingWrapper>
                </>


            </>, document.body
        )
    )

}

export default Loading;