export const customStyleLoad = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.90)",
      zIndex: "5555"
    },
    focus: { outline: "none !important" },
    content: {
      color: "white",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      position: "absolute",
      zIndex: "5555",
      padding: "10px"
    }
  };