import React from "react";
import { Col, Row } from "react-bootstrap";
import ComprasNetMinuto from "./ComprasNet/ComprasNetMinuto";
import LicitacoesEMinuto from "./LicitacoesE/LicitacoesEMinuto";
import ComprasNetHora from "./ComprasNet/ComprasNetHora";
import LicitacoesEHora from "./LicitacoesE/LicitacoesEHora";
import InfoNav from "../../Nav/InfoNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

export default function Home() {
  return (
    <>
      <InfoNav
        height={"100px"}
        title="Gráficos"
        getIcon={() => <FontAwesomeIcon icon={faChartLine} />}
      />
      <Col style={{ marginTop: "15px", zIndex: '-1' }} md={12}>
        <Row>
          <Col md={12}>
            <Row style={{ marginTop: "10px" }}>
              <Col md={6}>
                <Row>
                  <ComprasNetMinuto />
                  <ComprasNetHora />
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <LicitacoesEMinuto />
                  <LicitacoesEHora />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
}
