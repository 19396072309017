import React from 'react';
import {Container} from "./styles";

const Bageg = ({icon, background, color, number, name}) => {
	return (
		<Container background={background} color={color}>
			<div className={'icon'}>{icon}</div>
			<div className={'textos'}>
				<div className={'number'}>{number}</div>
				<div className={'name'}>{name}</div>
			</div>
		</Container>
	);
};

export default Bageg;
