import React, { Component } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";
import { Button, Col, Form, Image, Row, InputGroup } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import HaerdModal from "../Modal/HeardModal";
import { User, AlertTriangle } from "react-feather";

@inject("LoginStores")
@observer
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: true,
    };
  }

  render() {
    const { onChange, logar, status } = this.props.LoginStores;
    return (
      <Modal
        className="Modal_Load"
        style={{
          content: { width: "450px", height: "400px", left: "48%", top: "38%" },
        }}
        overlayClassName="Overlay_Load"
        isOpen={this.state.isActive}
      >
        <HaerdModal icon={<User />} titulo={"LOGIN"} />
        <div style={{ padding: "20px" }}>
          <Form>
            <Col md={12}>
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={2}>
                      <Image
                        style={{ width: "50px", marginTop: "7px" }}
                        src="/img/logo_wavecode.png"
                      />
                    </Col>
                    <Col md={10}>
                      <h2>Faça seu login</h2>
                      <p>Digite seus dados e acesse o sistema.</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <br />
            </Col>
            <div>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="email"
                    aria-describedby="inputGroupPrepend"
                    onChange={(e) => onChange(e)}
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroupPrepend">
                      <FontAwesomeIcon icon="key" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    name="password"
                    type="password"
                    placeholder="senha"
                    aria-describedby="inputGroupPrepend"
                    onChange={(e) => onChange(e)}
                  />
                </InputGroup>
              </Form.Group>

              {status === "2" && (
                <span style={{ color: "#751217", fontSize: "12px" }}>
                  <AlertTriangle /> CREDENCIAIS INCORRETAS!
                </span>
              )}
              {status === "1" && (
                <span style={{ color: "#006500", fontSize: "12px" }}>
                  LOGIN REALIZADO COM SUCESSO!
                </span>
              )}

              <div style={{ marginTop: "25px" }}>
                <Button
                  onClick={(e) => logar(e, this.props)}
                  style={{ width: "100%" }}
                  size={"sm"}
                  bsClass="btn"
                  bsStyle="primary"
                  type={"submit"}
                  active={true}
                >
                  ACESSAR
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    );
  }
}

export default Login;
