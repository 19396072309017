import { observable, action } from "mobx";
import $ from "jquery";
import Api from "./Conexao/conexao";
import { AlertaError, AlertaSuccess } from "./Funcoes/FuncoesGerais";
import { globalDebug } from "./Funcoes/Debug";
import * as moment from "moment";
// import MaquinaDeEstadoStores from './MaquinaDeEstadoStores'
import ClienteEmpresaStores from "./ClienteEmpresaStores";
import ClienteStores from "./ClienteStores";

class ContratosStores {
  @observable company_fk = "";
  @observable ends_in = "";
  @observable combo_fk = "";
  @observable plano_fk = undefined;
  @observable contratos = [];
  @observable company_name = "";
  @observable nome_combo = "";
  @observable combo_total = 0;
  @observable pagina_atual = 1;
  @observable empresa = [];
  @observable portal_fk = [];
  @observable planosMensal = [];
  @observable planosAnual = [];
  @observable dadosEmpresa = [];
  @observable haveAContact = false;
  @observable users = []

  @action consultaEmpresasId = (id) => {
    this.ChecksCuurentPage();
    Api.get("empresa/" + id)
      .then((response) => {
        console.log("consultaEmpresasId");
        this.haveAContact = response.data.contract[0].id ? true : false;
        this.contratos = response.data.contract;
        this.users = response.data.user;
      })
      .catch((error) => {
        globalDebug("consulta/EMPRESAS", error);
      });
  };

  @action consultaPlanoNovo = () => {
    this.ChecksCuurentPage();
    Api.get("platform_plans?page=" + this.pagina_atual)
      .then((response) => {
        this.plano_total = response.data.count;

        var mensal = response.data.results;
        mensal = mensal.filter((item) => {
          return item.type_plan_fk.name === "MENSAL";
        });
        this.planosMensal = mensal;

        var anual = response.data.results;
        anual = anual.filter((item) => {
          return item.type_plan_fk.name === "ANUAL";
        });
        this.planosAnual = anual;
      })
      .catch((error) => {
        globalDebug("CONSULTA/PLANO", error);
      });
  };

  @action consultaContratoEmpresaID = (id) => {
    Api.get(`empresa/${id}`)
      .then((response) => {
        this.haveAContact = response.data.contract[0] ? true : false;
        this.contratos = response.data.contract;
        this.dadosEmpresa = response.data;
        this.users = response.data.user;
        console.log(response.data);
      })
      .catch((error) => {
        this.contratos = [];
        globalDebug("consulta/CONTRATO", error);
      });
  };

  @action consultaContratoEmpresa = (id) => {
    Api.get("platform_contracts?page=" + this.pagina_atual)
      .then((response) => {
        this.combo_total = response.data.count;
        this.contratos = response.data.results;
      })
      .catch((error) => {
        globalDebug("consulta/CONTRATO", error);
      });
  };

  @action consultaContrato = () => {
    this.consultaPlanoNovo();
    Api.get("platform_contracts?page=" + this.pagina_atual)
      .then((response) => {
        this.combo_total = response.data.count;
        this.contratos = response.data.results;
      })
      .catch((error) => {
        globalDebug("consulta/CONTRATO", error);
      });
  };

  @action addContratoNovo = (id) => {
    Api.post("contract/add", {
      company_fk: id,
      ends_in: this.ends_in  + " 06:00:00",
      combo_fk: this.combo_fk,
      plano_fk: this.plano_fk != null ? [this.plano_fk] : [],
    })

      .then(() => {
        AlertaSuccess("CONTRATO adicionado com sucesso!");
        this.consultaEmpresasId(id);

        ContratosStores.consultaContratoEmpresaID(id);
        ClienteEmpresaStores.consultaEmpresasSemLoading();
        ClienteStores.consultaClientesSemLoading();

        this.ClearInputs();
      })
      .catch((error) => {
        globalDebug("ADD/CONTRATO", error);
      });
  };

  @action UpdateContratoId = (id) => {
    if (this.ChecksInputs())
      Api.patch(`contract/${id}`, {
        combo_fk: this.combo_fk,
        ends_in: this.ends_in + " 06:00:00",
      })
        .then(() => {
          AlertaSuccess("CONTRATO  editado com sucesso!");
          this.ClearInputs();
          this.consultaContratoEmpresaID(this.dadosEmpresa.id);
          ClienteEmpresaStores.consultaEmpresasSemLoading();
          ClienteStores.consultaClientesSemLoading();
        })
        .catch((error) => {
          globalDebug("UpDate/CONTRATO", error);
        });
  };

  @action delContrato = (id, nome) => {

    Api.delete("contract/" + id)
      .then(() => {
        this.consultaContratoEmpresaID(this.dadosEmpresa.id);
        AlertaSuccess("CONTRATO " + nome + " excluido com sucesso!");
      })
      .catch((error) => {
        globalDebug("DEL/CONTRATO", error);
      });
  };

  @action ChecksInputs = () => {
    if (this.ends_in === "") {
      AlertaError("Campo DATA não pode ser em branco!");
      return false;
    }

    if (this.combo_fk === "") {
      AlertaError("Campo PLANO E CAMBO não pode ser em branco!");
      return false;
    }

    if (this.ends_in !== "") {
      if (this.plano_fk !== undefined) {
        return true;
      } else if (this.combo_fk !== "") {
        return true;
      }
    }
  };

  @action ClearInputs = () => {
    this.company_fk = "";
    this.ends_in = "";
    this.combo_fk = "";
    this.contratos = [];
    this.plano_fk = undefined;
  };

  @action onChange = (evento) => {
    const campo = evento.target.name;
    this[campo] = evento.target.value;

    if (
      $("#planosMensal").val() === undefined &&
      $("#planosAnual").val() === undefined
    ) {
    } else if ($("#planosMensal").val() !== undefined) {
      this.plano_fk = $("#planosMensal").val();
    } else if ($("#planosAnual").val() !== undefined) {
      this.plano_fk = $("#planosAnual").val();
    } else if (campo === "ends_in") {
      this.ends_in = moment(this.ends_in).format("YYYY-MM-DD") + " 06:00:00";
    }
    console.log(this.ends_in)
  };

  @action removeComboAndDate = () => {
    this.ends_in = "";
    this.combo_fk = "";
  };

  @action ChecksCuurentPage = () => {
    if (this.pagina_atual === 0) {
      this.pagina_atual = 1;
    }
  };

  @action onPageChanged = (data) => {
    const { currentPage } = data;
    this.pagina_atual = currentPage;
    this.consultaContrato();
  };
}

const contratosStores = new ContratosStores();
export default contratosStores;
