import styled from "styled-components";

export const ContainerAvisos = styled.div`
  margin-top: 28vh;
  border-radius: 10px;
  background: white;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
