import styled from "styled-components";

export const divUserEmail = styled.div`   
    margin: 0 10%;
`;

export const DivUser = styled.div`
    display: flex;
    justify-content: space-between;

    .user-mail {
        margin: 0 10%;
    }

`;