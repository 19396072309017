import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import { AlertaError, AlertaSuccess } from "./Funcoes/FuncoesGerais";
import { globalDebug } from "./Funcoes/Debug";

class ProdutosStores {
  @observable description = "";
  @observable name = "";
  @observable produtos = [];
  @observable produto_total = 0;
  @observable pagina_atual = 1;

//   @action consultaProduto = () => {
//     console.log("consultaProduto");
//     this.ChecksCuurentPage();
//     Api.get("products?page=" + this.pagina_atual)
//       .then((response) => {
//         this.produto_total = response.data.count;
//         this.produtos = response.data.results;
//       })
//       .catch((error) => {
//         globalDebug("CONSULTA/PRODUTO", error);
//       });
//   };

  @action consultarProduto = () => {
    this.ChecksCuurentPage();
    Api.get("products?page=" + this.pagina_atual)
      .then((response) => {
        this.produto_total = response.data.count;
        this.produtos = response.data.results;
      })
      .catch((error) => {
        globalDebug("CONSULTA/PRODUTO", error);
      });
  };

  @action addProduto = () => {
    Api.post("product/add", { name: this.name, description: this.description })
      .then(() => {
        AlertaSuccess("PRODUTO " + this.name + " adicionado com sucesso!");
        // this.consultaProduto();
        this.ClearInputs();
      })
      .catch((error) => {
        globalDebug("ADD/PRODUTO", error);
      });
  };

  @action UpdateProdutoId = (id) => {
    if (this.ChecksInputs())
      Api.patch("product/" + id, {
        name: this.name,
        description: this.description,
      })
        .then(() => {
          AlertaSuccess("PRODUTO " + this.name + " editado com sucesso!");
          //   this.consultaProduto();
          this.ClearInputs();
        })
        .catch((error) => {
          globalDebug("UpDate/PRODUTO", error);
        });
  };

  @action delProduto = (id, nome) => {
    Api.delete("product/" + id)
      .then(() => {
        AlertaSuccess("PRODUTO " + nome + " excluido com sucesso!");
        this.onPageChanged({ currentPage: 1 });
        // this.consultaProduto();
      })
      .catch((error) => {
        globalDebug("DEL/PRODUTO", error);
      });
  };

  @action ChecksInputs = () => {
    if (this.name === "" && this.description === "") {
      AlertaError("Campos NOME E DESCRIÇÃO não podem ser em brancos!");
      return false;
    } else {
      if (this.name === "") {
        AlertaError("Campo NOME não pode ser em branco!");
        return false;
      }
      if (this.description === "") {
        AlertaError("Campo DESCRIÇÃO não pode ser em branco!");
        return false;
      }
      return true;
    }
  };

  @action ClearInputs = () => {
    this.name = "";
    this.description = "";
  };

  @action onChange = (evento) => {
    const campo = evento.target.name;
    this[campo] = evento.target.value;
  };

  @action ChecksCuurentPage = () => {
    if (this.pagina_atual === 0) {
      this.pagina_atual = 1;
    }
  };

  @action onPageChanged = (data) => {
    const { currentPage } = data;
    this.pagina_atual = currentPage;
    // this.consultaProduto();
  };
}

const produtosStores = new ProdutosStores();
export default produtosStores;
