import React, { Component } from "react";
import { Row, Table } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import Pagination from "../../../../Stores/Funcoes/Paginacao/Pagination";
import ModalExcluirBase from "../../../Modal/ModalExcluirBase";
import ModalEditarTipoPlano from "../../ModalConfiguracoes/ModalEditarTipoPlano";

@inject("TiposPlanosStores")
@observer
class TabTipoPlanosConf extends Component {
  componentDidMount() {
    this.props.TiposPlanosStores.consultaTipoPlano();
  }

  render() {
    return (
      <Row
        style={{
          paddingLeft: "0.8%",
          paddingRight: "0.8%",
          marginTop: "1%",
          marginBottom: "5%",
          fontSize: "15px",
        }}
      >
        <Table striped bordered condensed hover>
          <thead>
            <tr>
              <th style={{ width: "5%", textAlign: "center" }}>#</th>
              <th style={{ width: "40%" }}>NOME TIPO</th>
              <th style={{ width: "40%" }}>DESCRIÇÃO</th>
              <th style={{ width: "8%", textAlign: "center" }}>AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {this.props.TiposPlanosStores.tipo_plano.map((tipo_plano, i) => {
              return (
                <tr key={tipo_plano.id}>
                  <td style={{ textAlign: "center" }}>{tipo_plano.id}</td>
                  <td>{tipo_plano.name}</td>
                  <td>{tipo_plano.description}</td>
                  <td style={{ textAlign: "center" }}>
                    <ModalEditarTipoPlano tipo_plano={tipo_plano} />
                    &nbsp;
                    <ModalExcluirBase
                      nome={tipo_plano.name}
                      onClick={() =>
                        this.props.TiposPlanosStores.delTipoPlano(
                          tipo_plano.id,
                          tipo_plano.name
                        )
                      }
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Pagination
          totalRecords={this.props.TiposPlanosStores.tipo_plano_total}
          pageLimit={20}
          pageNeighbours={1}
          defaultCurrent={this.props.TiposPlanosStores.pagina_atual}
          onPageChanged={this.props.TiposPlanosStores.onPageChanged}
          key={this.props.TiposPlanosStores.tipo_plano_total}
        />
      </Row>
    );
  }
}

export default TabTipoPlanosConf;
