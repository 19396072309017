import { combineReducers, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
// import { composeWithDevTools } from 'redux-devtools-extension';
import rootSagas from './rootsSagas';
import editalReducer from "../redux/loginRedux";


const sagaMiddleware = createSagaMiddleware();
const reducer = combineReducers({
    edital: editalReducer
});
const store = createStore(reducer, applyMiddleware(sagaMiddleware),);
sagaMiddleware.run(rootSagas);

export default store;
