import React, { Component } from "react";
import "antd/dist/antd.css";
import { Button } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import { Modal } from "antd";

@inject("LoginStores")
@observer
class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
  }

  showModal = () => {
    this.setState({ isActive: true });
  };

  handleOk = () => {
    this.props.LoginStores.logout()
    this.setState({ isActive: false });
  };

  handleCancel = () => {
    this.setState({ isActive: false });
  };

  render() {
    return (
      <>
        <Button
          size={"sm"}
          onClick={() => this.showModal()}
          style={{ background: "#F02C2B", border: "none", width: "60px" }}
        >
          Sair
        </Button>
        <Modal
          title="Wavecode diz:"
          visible={this.state.isActive}
          onOk={() => this.handleOk()}
          onCancel={() => this.handleCancel()}
        >
          <h3>Tem certeza que deseja sair?</h3>
        </Modal>
      </>
    );
  }
}

export default Logout;
