import { observable, action } from "mobx";
import $ from "jquery";
import Api from "./Conexao/conexao";
import { AlertaError, AlertaSuccess } from "./Funcoes/FuncoesGerais";
import { globalDebug } from "./Funcoes/Debug";

class ComboStores {
  @observable name = "";
  @observable description = "";
  @observable value = "";
  @observable plano_fk = "";
  @observable combos = [];
  @observable combo_total = 0;
  @observable pagina_atual = 1;
  @observable planosMensal = [];
  @observable planosAnual = [];
  @observable plano = [];

  @action consultaPlanoNovo = () => {
    this.ChecksCuurentPage();
    Api.get("all_plans")
      .then((response) => {
        this.plano = response.data;
      })
      .catch((error) => {
        globalDebug("CONSULTA/PLANO", error);
      });
  };

  @action consultaCombo = async () => {
    this.ChecksCuurentPage();
    try {
      const res = await Api.get(`platform_combos?page=${this.pagina_atual}`);
      console.log(res.data)
      this.combo_total = res.data.length;
      this.combos = res.data;
    } catch (err) {
      globalDebug("consulta/COMBO", err);
    }
  };

  @action addCombo = () => {
    Api.post("combo/add", {
      name: this.name,
      description: this.description,
      value: this.value,
      plano_fk: this.plano_fk,
    })
      .then(() => {
        AlertaSuccess("COMBO " + this.name + " adicionado com sucesso!");
        this.consultaCombo();
        this.ClearInputs();
      })
      .catch((error) => {
        globalDebug("ADD/COMBO", error);
      });
  };

  @action UpdateComboId = (id) => {
    Api.patch("combo/" + id, {
      name: this.name,
      description: this.description,
      value: this.value,
      plano_fk: this.plano_fk,
    })
      .then(() => {
        AlertaSuccess("COMBO " + this.name + " editado com sucesso!");
        this.consultaCombo();
        this.ClearInputs();
      })
      .catch((error) => {
        globalDebug("UpDate/COMBO", error);
      });
  };

  @action delCombo = (id, nome) => {
    Api.delete("combo/" + id)
      .then(() => {
        this.onPageChanged({ currentPage: 1 });
        AlertaSuccess("COMBO " + nome + " excluido com sucesso!");
        this.consultaCombo();
      })
      .catch((error) => {
        globalDebug("DEL/COMBO", error);
      });
  };

  @action ChecksInputs = () => {
    console.log("this.plano_fk", this.plano_fk);
    if (
      this.name === "" &&
      this.description === "" &&
      this.value === "" &&
      this.plano_fk.length === 0
    ) {
      AlertaError("Campos não podem ser em brancos!");
      return false;
    } else {
      if (this.name === "") {
        AlertaError("Campo NOME não pode ser em branco!");
        return false;
      }
      if (this.description === "") {
        AlertaError("Campo DESCRIÇÃO não pode ser em branco!");
        return false;
      }
      if (this.value === "") {
        AlertaError("Campo VALOR não pode ser em branco!");
        return false;
      }
      if (this.plano_fk.length === 0) {
        AlertaError("Campo PLANO não pode ser em branco!");
        return false;
      }
    }
    return true;
  };

  @action ClearInputs = () => {
    this.name = "";
    this.description = "";
    this.value = "";
    this.plano_fk = [];
  };

  @action onChange = (evento) => {
    const campo = evento.target.name;
    this[campo] = evento.target.value;
    this.plano_fk = $("#plano_fk").val();
  };

  @action ChecksCuurentPage = () => {
    if (this.pagina_atual === 0) {
      this.pagina_atual = 1;
    }
  };

  @action onPageChanged = (data) => {
    const { currentPage } = data;
    this.pagina_atual = currentPage;
  };
}

const comboStores = new ComboStores();
export default comboStores;
