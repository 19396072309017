import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import InfoNav from "../Nav/InfoNav";
import { CardDashboard } from "./CardDashBoard";
import { ContainerDashboatd } from "./styleDashboard";
import Api from "../../Stores/Conexao/conexao";
import { globalDebug } from "../../Stores/Funcoes/Debug";
import LastCompaniesRegistered from "./LastCompaniesRegistered";
import LastAccessToTheSystem from "./LastAccess";
import ModalLoading from "../../Stores/ModalLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const data = dados => {

  let novo = []
  let data = []
  if (dados){
    novo= dados.map(({data}) => {
      return data
    }).reverse()

    data = dados.map(({qtd}) => {
      return qtd
    }).reverse()
  }

  return {
    labels: novo,
    datasets: [
      {
        label: "EMPRESAS CADASTRADAS NOS ÚLTIMOS 30 DIAS",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 5,
        pointHitRadius: 10,
        data: data
      }
    ]
  };
};

const colorCardAzul =
  "radial-gradient(circle at 10% 20%, rgb(0, 107, 141) 0%, rgb(0, 69, 91) 90%)";
const colorCardLaranja =
  "linear-gradient( 89.9deg,  rgba(255,190,32,1) 0.6%, rgba(251,112,71,1) 124.3% )";
const colorCardVerde = "linear-gradient(to left, #00b09b, #96c93d)";

export const IndexDashboard = () => {
  const [cadastro, setCadastro] = useState(0);

  const consultaDashboard = () => {
    ModalLoading.openModal();
    Api.get(`dashboard/`)
      .then(response => {
        setCadastro(response.data);
        ModalLoading.clouseModal();
      })
      .catch(error => {
        globalDebug("consulta/CONTRATO", error);
      });
  };

  useEffect(() => {
    consultaDashboard();
  }, []);


  const {
    cadastros_30_dias,
    cadastros_7_dias,
    cadastros_1_dia,
    datas_30_dias,
    last_empresas_cadastrada,
    last_login
  } = cadastro;

  const dadoNovos = () => {
    return datas_30_dias;
  };

  return (
    <>
      <InfoNav height={"100px"} title="Dashboard" getIcon={() => <FontAwesomeIcon icon={"tachometer-alt"} />} />
      <ContainerDashboatd>
        <div style={{ display: "flex", width: "100%" }}>
          <div>
            <CardDashboard
              background={colorCardAzul}
              texto={"ÚLTIMOS 30 DIAS"}
              icone={"users"}
              numero={cadastros_30_dias}
            />
            <CardDashboard
              background={colorCardLaranja}
              texto={"ÚLTIMOS 7 DIAS"}
              icone={"user-plus"}
              numero={cadastros_7_dias}
            />
            <CardDashboard
              background={colorCardVerde}
              texto={"HOJE"}
              icone={"user-plus"}
              numero={cadastros_1_dia}
            />
          </div>
          <div style={{ width: "84%", background: "#fff", height: "370px" }}>
            <Line data={data(dadoNovos())} largura={99} height={70} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            overflowX: "hidden",
            marginTop: "50px",
            textAlign: "center"
          }}
        >
          <div style={{ width: "40%" }}>
            <div
              style={{
                background: "#007900",
                color: "white",
                padding: "7px",
                fontSize: "13px"
              }}
            >
              ÚLTIMAS EMPRESAS CADASTRADAS
            </div>
            <LastCompaniesRegistered data={last_empresas_cadastrada} />
          </div>
          <div
            style={{
              width: "1px",
              background: "#677a78",
              marginLeft: "30px",
              marginRight: "30px"
            }}
          />
          <div style={{ width: "58%", textAlign: "center" }}>
            <div
              style={{
                background: "#065168",
                color: "white",
                padding: "7px",
                fontSize: "13px"
              }}
            >
              ÚLTIMOS ACESSOS
            </div>
            <LastAccessToTheSystem data={last_login} />
          </div>
        </div>
      </ContainerDashboatd>
    </>
  );
};
