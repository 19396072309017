import React from 'react';
import InfoHeadCliente from "./InfoHeadCliente";
import {NavTabsCliente} from "./TableClientes/TabsCliente";

export  const ClienteIndex = () => {
    return(
        <div>
            <InfoHeadCliente/>
            <NavTabsCliente/>
        </div>
    )
};

