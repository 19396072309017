import React from "react";
import { ContainerNavInfoContrato } from "../Nav/styleNav";

export const Header = ({ name, email, phone }) => {
  return (
    <ContainerNavInfoContrato>
      <div style={{ marginBottom: "30px" }}>
        <h4>
          {name}
        </h4>
        <h6>E-MAIL: &nbsp; {email}</h6>
        <h6>TELEFONE: {phone}</h6>
      </div>     

      {/* <div style={{ marginBottom: "30px" }}>
        <ModalCadEmpresaContratos />
      </div> */}
    </ContainerNavInfoContrato>
  );
};
