import Alert from "react-s-alert";


export function AlertaSuccess(dados) {
    return(
        Alert.success(dados, {
            offset: '10px',
            position: 'bottom-right',
            effect: 'genie',
            timeout: 3000
        })
    )
}

export function AlertaError(dados) {

    return(
        Alert.error(dados, {
            position: 'bottom-left',
            effect: 'genie',
            timeout: 8000
        })
    )
}


export function AlertaInfo(dados) {
    return(
        Alert.info(dados, {
            position: 'bottom-left',
            effect: 'genie',
            timeout: 3000
        })
    )
}

export function FormatDate (data) {
    return  new Intl.DateTimeFormat('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false}).format(new Date(data))
}

export function AlertaVerde (msg) {
    return Alert.success(''+msg, {
        position: 'bottom-right',
        effect: 'genie',
        timeout: 10000
    });
}

export function AlertaVermelho (msg) {
    return Alert.error(''+msg, {
        position: 'bottom-right',
        effect: 'genie',
        timeout: 10000
    });
}

export function AlertaAzul (msg) {
    return Alert.info(''+msg, {
        position: 'bottom-right',
        effect: 'genie',
        timeout: 10000,
        html: true
    });
}


export function FormatDateData(data) {
    return  new Intl.DateTimeFormat('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour12: false}).format(new Date(data))
}

export function FormatDateSemHora(data) {
    let newData = data.toString()
    return  new Intl.DateTimeFormat('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',}).format(new Date(newData))
}

export function FormatDateHora(data) {
    return  new Intl.DateTimeFormat('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false}).format(new Date(data))
}



export function FormatDateSemHoraEn(data) {
    let newData = data.toString()
    return  new Intl.DateTimeFormat('en', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',}).format(new Date(newData))
}

