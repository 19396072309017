import React, { useEffect } from "react";
import { Badge, Col, Container, Row, Table } from "react-bootstrap";
import { inject, observer } from "mobx-react";

import ModalEditarCombo from "./ModalPlanos/ModalEditarCombo";
import Pagination from "../../Stores/Funcoes/Paginacao/Pagination";
import ModalExcluirBase from "../Modal/ModalExcluirBase";

const styleTabela = { verticalAlign: "middle", textAlign: "center" };

const TabComboPlanos = inject(
  "ComboStores",
  "PlanosStores"
)(
  observer(({ ComboStores }) => {
    const {
      combos,
      delCombo,
      combo_total,
      pagina_atual,
      onPageChanged,
      consultaCombo,
    } = ComboStores;
    return (
      <TabComboPlanosStore
        combos={combos}
        delCombo={delCombo}
        combo_total={combo_total}
        pagina_atual={pagina_atual}
        onPageChanged={onPageChanged}
        consultaCombo={consultaCombo}
      />
    );
  })
);

const TabComboPlanosStore = ({
  combos,
  delCombo,
  combo_total,
  pagina_atual,
  onPageChanged,
  consultaCombo,
}) => {
  useEffect(() => {
    consultaCombo();
  }, []);

  console.log(combos)

  return (
    <Container fluid style={{ marginBottom: "3%", marginTop: "10px" }}>
      <Col xs={12} md={12} >
        <Row>
          <Table striped bordered condensed hover>
            <thead>
              <tr>
                <th style={{ width: "5%", textAlign: "center" }}>#</th>
                <th style={{ width: "20%", verticalAlign: "middle" }}>NOME</th>
                <th style={{ width: "40%", verticalAlign: "middle" }}>
                  DESCRICAO
                </th>
                <th style={{ width: "10%", textAlign: "center" }}>PLANO</th>
                <th style={{ width: "10%", textAlign: "center" }}>VALOR</th>
                <th style={{ width: "8%", textAlign: "center" }}>AÇÃO</th>
              </tr>
            </thead>
            <tbody>
              {combos &&
                combos.map((combo, i) => {
                  console.log(combo)
                  return (
                    <tr key={i}>
                      <td style={styleTabela}>{combo.id}</td>
                      <td style={styleTabela}>{combo.name}</td>
                      <td style={styleTabela}>{combo.description}</td>

                      <td style={styleTabela}>
                        {combo.plano_fk.map((plano) => {
                          return (
                            <span key={plano.id}>
                              <Badge variant="primary">{plano.name}</Badge>
                              <br />
                            </span>
                          );
                        })}
                      </td>
                      <td style={styleTabela}>R$ {combo.value}</td>
                      <td style={styleTabela}>
                        <ModalEditarCombo
                          combo={combo}
                          plano_fk={combo.plano_fk}
                        />
                        &nbsp;
                        <ModalExcluirBase
                          nome={combo.name}
                          onClick={() => delCombo(combo.id, combo.name)}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <Pagination
            totalRecords={combo_total}
            pageLimit={20}
            pageNeighbours={1}
            defaultCurrent={pagina_atual}
            onPageChanged={onPageChanged}
            key={combo_total}
          />
        </Row>
      </Col>
    </Container>
  );
};

export default TabComboPlanos;
