import React, { Component } from "react";
import {
  Button,
  Col,
  Row,
  Form,
  ButtonGroup,
  InputGroup,
} from "react-bootstrap";
import Modal from "react-modal";
import { inject, observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";
import { BottomModal } from "../../Modal/BottomModal";
import ModalHeard from "../../Modal/ModalHeard";
import InputMask from "react-input-mask";
import { FormLabelModal } from "../../Modal/LabelModal";

@inject("PlanosStores", "ComboStores")
@observer
class ModalEditarCombo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      selectedOption: "mensal",
    };
  }

  // componentDidMount() {
  //   console.log("HELLO WORD!")
  //   this.props.PlanosStores.consultaPlanoNovo();
  // }

  handleCloseModal = () => {
    this.setState({ isActive: false });
  };

  handleOpenModal = () => {
    this.props.ComboStores.consultaPlanoNovo();
    let dados = this.props.plano_fk.map(({ id }) => {
      return id;
    });

    this.props.ComboStores.name = this.props.combo.name;
    this.props.ComboStores.description = this.props.combo.description;
    this.props.ComboStores.value = this.props.combo.value;
    this.props.ComboStores.plano_fk = dados;
    setTimeout(() => {
      this.setState({ isActive: true });
    }, 800);
  };

  handleUpdateComboCloseModal = (evento) => {
    evento.preventDefault();
    if (this.props.ComboStores.ChecksInputs()) {
      this.props.ComboStores.UpdateComboId(this.props.combo.id);
      this.handleCloseModal();
    } else {
    }
  };

  render() {
    const { plano_fk } = this.props.ComboStores;
    return (
      <span>
        <ButtonGroup aria-label="Basic example">
          <Button
            style={{ fontSize: "10px" }}
            size="sm"
            variant="warning"
            onClick={() => this.handleOpenModal()}
            title="EDITAR REGISTRO"
          >
            <FontAwesomeIcon style={{ color: "white" }} icon={"edit"} />{" "}
          </Button>
        </ButtonGroup>
        <Modal
          className="Modal_Load"
          style={{
            content: {
              width: "600px",
              height: "560px",
              left: "47%",
              top: "38%",
            },
          }}
          overlayClassName="Overlay_Load"
          isOpen={this.state.isActive}
        >
          <ModalHeard
            icon={"database"}
            titulo={"EDITAR COMBO"}
            onClick={() => this.handleCloseModal()}
          />
          <br />
          <div>
            <Col md={12}>
              <Form>
                <Form.Group>
                  {FormLabelModal("nome combo")}
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon="database" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      name={"name"}
                      type="text"
                      placeholder="Nome Combo..."
                      defaultValue={this.props.combo.name}
                      onChange={(e) => this.props.ComboStores.onChange(e)}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  {FormLabelModal("descrição")}
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon="align-center" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      name={"description"}
                      componentClass="textarea"
                      placeholder="Descrição..."
                      defaultValue={this.props.combo.description}
                      onChange={(e) => this.props.ComboStores.onChange(e)}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  {FormLabelModal("valor total")}
                  <Row style={{ marginLeft: "0.4%" }}>
                    <div>
                      <InputGroup.Text
                        style={{ height: "41px" }}
                        id="inputGroupPrepend"
                      >
                        R$
                      </InputGroup.Text>
                    </div>
                    <div style={{ width: "91%" }}>
                      <InputMask
                        defaultValue={this.props.combo.value}
                        name={"value"}
                        style={{
                          marginLeft: "-0.4%",
                          width: "100%",
                          border: "1.2px",
                          borderRadius: "4px",
                          height: "41px",
                          borderColor: "#ced4da",
                          borderStyle: "solid",
                        }}
                        mask="  9999.00"
                        maskChar={null}
                        placeholder="  Valor..."
                        onChange={(e) => this.props.ComboStores.onChange(e)}
                      />
                    </div>
                  </Row>
                </Form.Group>
                <Form.Group>
                  {FormLabelModal("plano")}
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon="boxes" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      as="select"
                      multiple
                      id={"plano_fk"}
                      defaultValue={plano_fk}
                      onChange={(e) => this.props.ComboStores.onChange(e)}
                    >
                      <option disabled={true}>Selecione um Plano Mensal</option>
                      {this.props.ComboStores.plano.map((plano, i) => {
                        return (
                          <option key={i} value={plano.id}>
                            {plano.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </InputGroup>
                </Form.Group>

                <div style={{ textAlign: "right" }}>
                  <Button
                    variant="outline-success"
                    size={"sm"}
                    onClick={(evento) =>
                      this.handleUpdateComboCloseModal(evento)
                    }
                  >
                    Cadastrar
                  </Button>
                  <Button
                    variant="outline-danger"
                    size={"sm"}
                    onClick={() => this.handleCloseModal()}
                    style={{ marginLeft: "10px" }}
                  >
                    Fechar
                  </Button>
                </div>
              </Form>
            </Col>
          </div>
          {BottomModal}
        </Modal>
      </span>
    );
  }
}

export default ModalEditarCombo;
