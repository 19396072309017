import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Modal from "react-modal";
import * as axios from "axios";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BottomModal } from "../../Modal/BottomModal";
import ModalHeard from "../../Modal/ModalHeard";
import InputMask from "react-input-mask";
import {
  AlertaSuccess,
  AlertaVermelho,
} from "../../../Stores/Funcoes/FuncoesGerais";
import ModalLoading from "../../../Stores/ModalLoading";
import { getToken } from "../../../LocalStore/Actions";

@inject("ClienteStores", "VedStores", "ClienteEmpresaStores")
@observer
class ModalCadastroClienteCompleto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      first_name: "",
      last_name: "",
      email_cobranca: "",
      password: "",
      razao_social: "",
      nome_fantasia: "",
      repeat_password: "",
      cnpj: "",
      phone: "",
    };
  }

  limparState = () => {
    this.setState({
      first_name: "",
      last_name: "",
      email_cobranca: "",
      password: "",
      razao_social: "",
      nome_fantasia: "",
      repeat_password: "",
      cnpj: "",
      phone: "",
    });
  };

  cadastrar = (e) => {
    e.preventDefault();
    ModalLoading.openModal();
    const data = new FormData();
    data.set("first_name", this.state.first_name);
    data.set("last_name", this.state.last_name);
    data.set("username", this.state.email_cobranca);
    data.set("email_cobranca", this.state.email_cobranca);
    data.set("password", this.state.password);
    data.set("repeat_password", this.state.repeat_password);
    data.set("razao_social", this.state.razao_social);
    data.set("nome_fantasia", this.state.nome_fantasia);
    data.set("cnpj", this.state.cnpj);
    data.set("phone", this.state.phone);

    axios({
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: getToken(),
      },
      url: global.url + "cadastro_inical/",
      data: data,
    })
      .then((response) => {
        ModalLoading.clouseModal();
        this.handleCloseModal();
        AlertaSuccess(
          "CLIENTE " + this.state.first_name + " adicionado com sucesso!"
        );
        this.props.ClienteStores.consultaClientesSemLoading();
        this.props.ClienteEmpresaStores.consultaEmpresasSemLoading();
      })
      .catch((error) => {
        AlertaVermelho(error.response.data);
        ModalLoading.clouseModal();
      });
  };

  handleCloseModal = () => {
    this.setState({ isActive: false });
    this.limparState();
  };
  handleOpenModal = () => {
    this.setState({ isActive: true });
  };

  onChange = (evento) => {
    let campo = evento.target.name;
    const value = evento.target.value;
    this.setState({ [campo]: value });
  };

  validacao_cadastro = () => {
    if (this.state.first_name === "") {
      AlertaVermelho("nome não pode ser em branco");
      return false;
    }
    if (this.state.last_name === "") {
      AlertaVermelho("sobrenome não pode ser em branco");
      return false;
    }
    if (this.state.email_cobranca === "") {
      AlertaVermelho("email não pode ser em branco");
      return false;
    }
    if (this.state.password === "") {
      AlertaVermelho("password não pode ser em branco");
      return false;
    }
    if (this.state.repeat_password === "") {
      AlertaVermelho("password não pode ser em branco");
      return false;
    }
    if (this.state.password !== this.state.repeat_password) {
      AlertaVermelho("password  não pode ser em deferentes");
      return false;
    }
    if (this.state.phone === "") {
      AlertaVermelho("Telefone não pode ser em branco");
      return false;
    }
    if (this.state.razao_social === "") {
      AlertaVermelho("Razão Social não pode ser em branco");
      return false;
    }
    if (this.state.nome_fantasia === "") {
      AlertaVermelho("Nome Fantasia não pode ser em branco");
      return false;
    }
    if (this.state.cnpj === "") {
      AlertaVermelho("CNPJ não pode ser em branco");
      return false;
    }
    return true;
  };

  cadastarUsuarioEmpresa = (e) => {
    if (this.validacao_cadastro()) {
      this.cadastrar(e);
    }
  };

  render() {
    return (
      <span>
        <Button
          onClick={() => this.handleOpenModal()}
          style={{ marginRight: "20px" }}
          variant="success"
          size="sm"
        >
          <FontAwesomeIcon icon={"user-shield"} /> CADASTRO COMPLETO
        </Button>
        <Modal
          className="Modal_Load"
          style={{
            content: {
              width: "40vw",
              height: "650px",
              left: "47%",
              top: "36%",
            },
          }}
          overlayClassName="Overlay_Load"
          isOpen={this.state.isActive}
        >
          <ModalHeard
            icon={"user-tie"}
            titulo={"CADASTRAR CLIENTE"}
            onClick={() => this.handleCloseModal()}
          />
          <br />
          <div>
            <Col md={12}>
              <Form>
                <div
                  style={{
                    padding: "15px",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  DADOS DO USUARIO
                </div>

                <Form.Group controlId="formBasicEmail">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon={"user-circle"} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      name={"first_name"}
                      type="text"
                      placeholder="primeiro nome"
                      onChange={(e) => this.onChange(e)}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon={"user-circle"} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      name={"last_name"}
                      type="text"
                      placeholder="último nome"
                      onChange={(e) => this.onChange(e)}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        @
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      name={"email_cobranca"}
                      type="email"
                      placeholder="email@email"
                      onChange={(e) => this.onChange(e)}
                    />
                  </InputGroup>
                </Form.Group>

                <div style={{ display: "flex", width: "100%" }}>
                  <Form.Group
                    controlId="formBasicEmail"
                    style={{ marginRight: "10px", width: "100%" }}
                  >
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">
                          **
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        name={"password"}
                        type="text"
                        placeholder="password"
                        onChange={(e) => this.onChange(e)}
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group
                    controlId="formBasicEmail"
                    style={{ marginLeft: "10px", width: "100%" }}
                  >
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">
                          **
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        name={"repeat_password"}
                        type="text"
                        placeholder="repetir password "
                        onChange={(e) => this.onChange(e)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div style={{ display: "flex", width: "100%" }}>
                    <InputGroup.Text
                      style={{ height: "41px", width: "42px" }}
                      id="inputGroupPrepend"
                    >
                      <FontAwesomeIcon icon="phone-square" />
                    </InputGroup.Text>

                    <InputMask
                      name={"phone"}
                      style={{
                        marginLeft: "-0.7%",
                        width: "100%",
                        border: "1.2px",
                        borderRadius: "4px",
                        height: "41px",
                        borderColor: "#ced4da",
                        borderStyle: "solid",
                      }}
                      mask="(99) 99999-9999"
                      maskChar={null}
                      placeholder="  telefone.."
                      onChange={(e) => this.onChange(e)}
                    ></InputMask>
                  </div>
                </div>

                <div
                  style={{
                    padding: "15px",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  DADOS DA EMPRESA
                </div>

                <Form.Group controlId="formBasicEmail">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon={"briefcase"} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      name={"nome_fantasia"}
                      type="text"
                      placeholder="nome fantasia"
                      onChange={(e) => this.onChange(e)}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon={"briefcase"} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      name={"razao_social"}
                      type="text"
                      placeholder="razão social"
                      onChange={(e) => this.onChange(e)}
                    />
                  </InputGroup>
                </Form.Group>

                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "98%",
                      marginRight: "10px",
                    }}
                  >
                    <div style={{ display: "flex", width: "100%" }}>
                      <InputGroup.Text
                        style={{ height: "41px", width: "42px" }}
                        id="inputGroupPrepend"
                      >
                        <FontAwesomeIcon icon="code" />
                      </InputGroup.Text>
                      <InputMask
                        name={"cnpj"}
                        style={{
                          marginLeft: "-0.7%",
                          width: "100%",
                          border: "1.2px",
                          borderRadius: "4px",
                          height: "41px",
                          borderColor: "#ced4da",
                          borderStyle: "solid",
                        }}
                        mask="   99.999.999/9999-99"
                        maskChar={null}
                        placeholder="  cnpj.."
                        onChange={(e) => this.onChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ textAlign: "right", marginTop: "10px" }}>
                  <Button
                    variant="outline-success"
                    size="sm"
                    onClick={(e) => this.cadastarUsuarioEmpresa(e)}
                  >
                    Cadastrar
                  </Button>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => this.handleCloseModal()}
                    style={{ marginLeft: "10px" }}
                  >
                    Fechar
                  </Button>
                </div>
              </Form>
            </Col>
          </div>
          {BottomModal}
        </Modal>
      </span>
    );
  }
}

export default ModalCadastroClienteCompleto;
