import React from "react";
import { NavTabsConf } from "./TableConfiguracoes/NavTabsConf";
import InfoNav from "../Nav/InfoNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const indexCofiguracoes = () => {
  return (
    <>
      <InfoNav title="Configurações" getIcon={() => <FontAwesomeIcon icon={"cogs"} />} height={"110px"} />
      <NavTabsConf />
    </>
  );
};