import React from 'react';
import {ToastContainer} from "react-toastify";
import {Provider} from "react-redux";
import store from "./store/store";
import Portais from "./componentes/Portais";
import 'bootstrap/dist/css/bootstrap.min.css';

function Wavebots() {
    return (        
        <>          
            <Provider store={store}>
                <Portais />
            </Provider>
            <ToastContainer/>
        </>
    );
}

export default Wavebots;