import React from "react";
import { Badge } from "react-bootstrap";
import { FormatDateSemHora } from "../../../Stores/Funcoes/FuncoesGerais";
import ModalEditarContrato from "../Modal/ModalEditarContrato";
import ModalExcluirEmpresasContratos from "../Modal/ModalExcluirEmpresasContratos";

const HendlerContrato = ({ contrato, dadosEmpresa }) => {
  const { combo_fk, ends_in, id } = contrato;
  const { created_at } = dadosEmpresa;
  return (
    <TamplayteHendleModal
      created_at={created_at}
      contratos={combo_fk}
      ends_in={ends_in}
      id={id}
    />
  );
};
export default HendlerContrato;

const TamplayteHendleModal = ({ contratos, ends_in, id, created_at }) => {
  const { name, value } = contratos;

  return (
    <div
      style={{
        minHeight: "30px",
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "15px",
        borderRadius: "7px",
        color: "rgb(6,81,104)",
        textTransform: "uppercase",
      }}
    >
      <div style={{ marginTop: "7px" }}>
        <h6>{name}</h6>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "66%",
          justifyContent: "space-between",
        }}
      >
        <div>VALOR R$ {value}</div>
        <div> {dateCreation(created_at)}</div>
        <div> {checarDatas(ends_in)}</div>
        <div
          style={{
            display: "flex",
            minWidth: "100px",
            justifyContent: "space-between",
          }}
        >
          <div>
            <ModalEditarContrato
              contratos={contratos}
              ends_in={ends_in}
              idContrato={id}
            />
          </div>
          <div>
            <ModalExcluirEmpresasContratos id={id} name={name} />
          </div>
        </div>
      </div>
    </div>
  );
};

const dateCreation = (date) => {
  if (date) {
    let creationDate = date.slice(0, 19);
    let dateFormatted = FormatDateSemHora(creationDate);
    return (
      <Badge pill variant="success">
        Data de Criação: &nbsp;&nbsp; {dateFormatted}
      </Badge>
    );
  }
};

const checarDatas = (data_contrato) => {
  let dataAtual = new Date();
  let mydate = new Date(data_contrato);
  let newData = FormatDateSemHora(data_contrato);

  if (mydate < dataAtual) {
    return (
      <Badge pill variant="danger">
        Data Expiração: &nbsp;&nbsp; {newData}
      </Badge>
    );
  } else {
    return (
      <Badge pill variant="success">
        Data Expiração: &nbsp;&nbsp; {newData}
      </Badge>
    );
  }
};
