import styled from "styled-components";

export const ContainerDashboatd = styled.div`
      padding: 20px;
`;

export const CardDashboatd = styled.div`
    width: 300px;
    background: ${props => props.background};
    height: 110px;
    margin-right: 30px;
    margin-bottom: 20px;
    color: white;
    border-radius: 10px;
`;

export const CardBodyDashboatd = styled.div`
    display: flex;
    place-content: center space-between;
    padding: 20px;
    align-items: center;
`;


