import React, {Component} from 'react';
import {inject, observer} from 'mobx-react'
import Modal from 'react-modal';
import {Button, Col, Row, Form, ButtonGroup, InputGroup} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {BottomModal} from "../../Modal/BottomModal";
import ModalHeard from "../../Modal/ModalHeard";
import {FormLabelModal} from "../../Modal/LabelModal";
import InputMask from 'react-input-mask';

@inject('FeaturesStores', 'ProdutosStores')
@observer
class ModalCadFeature extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false
        };
    }

    handleCloseModal = () => {
        this.setState({ isActive: false });
        this.props.FeaturesStores.ClearInputs()
    };
    handleOpenModal = () => {
        this.setState({ isActive: true });
    };

    handleAddFeatureCloseModal = (evento) => {
        evento.preventDefault();
        if (this.props.FeaturesStores.ChecksInputs()){
            this.props.FeaturesStores.addFeatures();
            this.setState({ isActive: false });
        }else {
        }
    };




    render() {


        return (
            <span>
                <ButtonGroup aria-label="Basic example">
                    <Button size="sm" disabled variant="outline-primary">&nbsp;+</Button>
                    <Button size="sm" variant="primary"  onClick={() => this.handleOpenModal()}>Features</Button>
                </ButtonGroup>

                 <Modal
                     className="Modal_Load"
                     style={{content: {width: '600px', height: '540px', left: '47%', top: '38%' }}}
                     overlayClassName="Overlay_Load"
                     isOpen={this.state.isActive}>
                        <ModalHeard titulo={"CADASTRAR FEATURES"}  icon={'bookmark'} onClick={()=> this.handleCloseModal()}/><br/>
                        <div>
                            <Col md={12} style={{marginTop: '1%'}}>
                                <Form>
                                    <Form.Group>
                                           {FormLabelModal("feature")}
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend"><FontAwesomeIcon icon="bookmark"/></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            name="name"
                                            type="text"
                                            placeholder="..."
                                            aria-describedby="inputGroupPrepend"
                                            onChange={(e) => this.props.FeaturesStores.onChange(e)}/>
                                    </InputGroup>
                                    </Form.Group>


                                    <Form.Group>
                                        {FormLabelModal("limite")}
                                        <Row style={{marginLeft: '0.4%', }}>
                                            <div>
                                                <InputGroup.Text style={{ height: '41px' }} id="inputGroupPrepend">$$</InputGroup.Text>
                                            </div>
                                            <div style={{ width: '91%'}}>
                                                <InputMask   name="limit" style={{ marginLeft: '-0.4%', width: '100%', border: '1.2px', borderRadius: '4px', height: '41px', borderColor: '#ced4da', borderStyle: 'solid'}} mask="  9999.00" maskChar={null} placeholder="  Valor..."  onChange={(e) => this.props.FeaturesStores.onChange(e)} />
                                            </div>
                                        </Row>
                                    </Form.Group>

                                    <Form.Group>
                                             {FormLabelModal("descrição")}
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend"><FontAwesomeIcon icon="align-center"/></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            as="textarea" rows="3"
                                            name="description"
                                            type="text"
                                            placeholder="..."
                                            aria-describedby="inputGroupPrepend"
                                            onChange={(e) => this.props.FeaturesStores.onChange(e)}/>
                                    </InputGroup>
                                    </Form.Group>
                                     <Form.Group>
                                         {FormLabelModal("produto")}
                                         <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text id="inputGroupPrepend"><FontAwesomeIcon icon="dice-d6"/></InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control as="select" name="product_fk"  onChange={(e) => this.props.FeaturesStores.onChange(e)}>
                                                         <option disabled={false} value={null}>Selecione um produto</option>
                                                        {this.props.ProdutosStores.produtos.map((produtos, i) => {
                                                            return (
                                                                <option    key={i} value={produtos.id}>{produtos.name}</option>
                                                            );
                                                        })}
                                                    </Form.Control>
                                                </InputGroup>
                                     </Form.Group>

                                    <div style={{textAlign: 'right'}}>
                                        <Button variant="outline-success" size='sm' onClick={(evento) => this.handleAddFeatureCloseModal(evento)}>Cadastrar</Button>
                                        <Button variant="outline-danger" size='sm' onClick={() => this.handleCloseModal()} style={{marginLeft: '10px'}}>Fechar</Button>
                                    </div>
                                </Form>
                            </Col>
                        </div>
                        {BottomModal}
                </Modal>
            </span>
        );
    }
}

export default ModalCadFeature;

