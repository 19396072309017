import  {observable, action} from 'mobx'
import {AlertaError, AlertaSuccess} from "./Funcoes/FuncoesGerais";
import Api from "./Conexao/conexao";
import {globalDebug} from "./Funcoes/Debug";

class VedStores {
    @observable name = '';
    @observable email = '';
    @observable vendedor_total = 0;
    @observable pagina_atual = 1;
    @observable vendedores = [];

    @action consultaVendedor= () => {
        this.ChecksCuurentPage();
        Api.get("sellers?page="+this.pagina_atual).then((response) => {
            this.vendedor_total = response.data.count;
            this.vendedores = response.data.results;
        }).catch((error) => {
            globalDebug('consulta/VENDEDOR', error);
        })
    };

    @action addVendedor = () => {
        Api.post("seller/add",{ "name": this.name, "email": this.email},).then(() => {
            AlertaSuccess("VENDEDOR "+this.name+  ' adicionado com sucesso!');
            this.consultaVendedor();
            this.ClearInputs()
        }).catch((error) => {
            globalDebug('ADD/VENDEDOR', error);
        })
    };

    @action delVendedor = (id, nome ) =>{
        Api.delete("seller/"+id,).then(() => {
            AlertaSuccess('VENDEDOR '+nome+' excluido com sucesso!');
            this.onPageChanged({currentPage:1});
            this.consultaVendedor();
        }).catch((error) => {
            globalDebug('DEL/VENDEDOR', error);
        })
    };


    @action UpdateVendedorId = (id) => {
        if (this.ChecksInputs())
            Api.patch("seller/"+id, {"name": this.name, "email": this.email}).then(() => {
                AlertaSuccess("VENDEDOR "+this.name+  " editado com sucesso!");
                this.consultaVendedor();
                this.ClearInputs();
            }).catch((error) => {
                globalDebug('UpDate/VENDEDOR', error);
            })
    };

    @action ChecksInputs = () => {

        if (this.name === "" && this.email === "") {
            AlertaError("Campos NOME E EMAIL não podem ser em brancos!");
            return (false)
        } else {
            if (this.name === '') {
                AlertaError("Campo NOME não pode ser em branco!");
                return (false)
            }
            if (this.email === "") {
                AlertaError("Campo EMAIL não pode ser em branco!");
                return (false)
            }

            return true
        }

    };

    @action ClearInputs = () => {
        this.name = '';
        this.email = '';
    };

    @action onChange = (evento) => {
        const campo = evento.target.name;
        this[campo] = evento.target.value;
    };

    @action ChecksCuurentPage = () => {
        if (this.pagina_atual === 0) {
            this.pagina_atual = 1
        }
    };

    @action onPageChanged = (data) => {
        const {currentPage} = data;
        this.pagina_atual = currentPage;
    };

}

const storeVed = new VedStores();
export default storeVed;