import  {observable, action} from 'mobx'
import Api from "./Conexao/conexao";
import {AlertaError, AlertaSuccess} from "./Funcoes/FuncoesGerais";
import {globalDebug} from "./Funcoes/Debug";

class TiposPlanosStores {
    @observable name = '';
    @observable description = '';
    @observable tipo_plano = [];
    @observable tipo_plano_total = 0;
    @observable pagina_atual = 1;

    @action consultaTipoPlano = () => {
        this.ChecksCuurentPage();
        Api.get("type-plans?page="+this.pagina_atual).then((response) => {
            this.tipo_plano_total = response.data.count;
            this.tipo_plano = response.data.results;
        }).catch((error) => {
            globalDebug('consulta/TIPO PLANO', error);
        })
    };

    @action addTipoPlano= () => {
        if (this.ChecksInputs())
        Api.post("type-plan/add",{ "name": this.name, "description": this.description},).then(() => {
            this.consultaTipoPlano();
            AlertaSuccess("TIPO PLANO " +this.name + ' adicionado com sucesso!');
            this.ClearInputs()
        }).catch((error) => {
            globalDebug('add/TIPO PLANO', error);
        })

    };

    @action UpdateTipoPlanoId = (id) => {
        if (this.ChecksInputs())
            Api.patch("type-plan/"+id, {"name": this.name, "description": this.description}).then(() => {
                AlertaSuccess("TIPO DE PLANO "+this.name+  " editado com sucesso!");
                this.consultaTipoPlano();
                this.ClearInputs();
            }).catch((error) => {
                globalDebug('UpDate/TIPO DE PLANO', error);
            })
    };


    @action delTipoPlano = (id, nome ) =>{
        Api.delete("type-plan/"+id,).then(() => {
            this.consultaTipoPlano();
            AlertaSuccess('TIPO PLANO '+nome+' excluido com sucesso!');
            this.onPageChanged({currentPage:1})
        }).catch((error) => {
            globalDebug('del/TIPO PLANO', error);
        })
    };


    @action ClearInputs = () => {
        this.name = '';
        this.description = '';
    };


    @action ChecksInputs = () => {
        if (this.name === '' && this.description === "" ){
            AlertaError("Campos NOME e DESCRIÇÃO não podem ser em brancos!");
            return(false)
        }else {
            if (this.name === ''){
                AlertaError("Campo NOME não pode ser em branco!");
                return(false)
            }if(this.description === ""){
                AlertaError("Campo DESCRIÇÃO não pode ser em branco!");
                return(false)
            }
        }
        return true
    };


    @action onChange = (evento) => {
        const campo = evento.target.name;
        this[campo] = evento.target.value;
    };

    @action ChecksCuurentPage = () => {
        if (this.pagina_atual === 0){
            this.pagina_atual = 1
        }
    };

    @action onPageChanged = (data) => {
        const {currentPage} = data;
        this.pagina_atual = currentPage;
        this.consultaTipoPlano();
    };

}

const tiposPlanosStores = new TiposPlanosStores();
export default tiposPlanosStores;