import { observable, action } from "mobx";
import $ from "jquery";
import Api from "./Conexao/conexao";
import { AlertaError, AlertaSuccess } from "./Funcoes/FuncoesGerais";
import { globalDebug } from "./Funcoes/Debug";
import ModalLoading from "./ModalLoading";

class PlanosStores {
  @observable name = "";
  @observable description = "";
  @observable value = "";
  @observable limit = "";
  @observable product_fk = "";
  @observable type_plan_fk = "";
  @observable feature_fk = [];
  @observable feature_fk_defalut = [];
  @observable portal_fk = [];
  @observable planos = [];
  @observable plano_total = 0;
  @observable pagina_atual = 1;
  @observable combos = [];

  @action consultaProdutoID = () => {
    Api.get("platform_features_all")
      .then((response) => {
        let product = response.data;
        this.feature_fk_defalut = product.filter((features) => {
          return features.product_fk.id === parseInt(this.product_fk);
        });
      })
      .catch((error) => {
        globalDebug("CONSULTA/PRODUTO", error);
      });
  };

  @action consultaPlanoNovo = async (page) => {
    ModalLoading.openModal();
    this.ChecksCuurentPage();
    try {
      const res = await Api.get(`platform_plans?page=${page ? page : 1}`);
      this.combo_total = res.data.count;
      this.combos = res.data.results;
    } catch (err) {
      globalDebug("consulta/COMBO", err);
    } finally {
      ModalLoading.clouseModal();
    }
  };

  @action addPlano = () => {
    Api.post("plan/add", {
      name: this.name,
      description: this.description,
      value: this.value,
      limit: this.limit,
      product_fk: this.product_fk,
      type_plan_fk: this.type_plan_fk,
      feature_fk: this.feature_fk,
      portal_fk: this.portal_fk,
    })
      .then(() => {
        AlertaSuccess("PLANO " + this.name + " adicionado com sucesso!");
        this.consultaPlanoNovo();
        this.ClearInputs();
      })
      .catch((error) => {
        globalDebug("ADD/PLANO", error);
      });
  };

  @action UpdatePlanoId = (id) => {
    if (this.ChecksInputs())
      Api.patch("plan/" + id, {
        name: this.name,
        description: this.description,
        value: this.value,
        limit: this.limit,
        product_fk: this.product_fk,
        type_plan_fk: this.type_plan_fk,
        feature_fk: this.feature_fk,
        portal_fk: this.portal_fk,
      })
        .then(() => {
          AlertaSuccess("PLANO " + this.name + " editado com sucesso!");
          this.consultaPlanoNovo();
          this.ClearInputs();
        })
        .catch((error) => {
          globalDebug("UpDate/PLANO", error);
        });
  };

  @action delPlano = (id, nome) => {
    Api.delete("plan/" + id)
      .then(() => {
        this.onPageChanged({ currentPage: 1 });
        AlertaSuccess("PLANO " + nome + " excluido com sucesso!");
        this.consultaPlanoNovo();
      })
      .catch((error) => {
        globalDebug("DEL/PLANO", error);
      });
  };

  @action ChecksInputs = () => {
    if (
      this.name === "" &&
      this.description === "" &&
      this.value === "" &&
      this.product_fk === "" &&
      this.type_plan_fk === "" &&
      this.feature_fk.length === 0 &&
      this.portal_fk.length === 0
    ) {
      AlertaError("Campos não podem ser em brancos!");
      return false;
    } else {
      if (this.name === "") {
        AlertaError("Campo NOME não pode ser em branco!");
        return false;
      }
      if (this.description === "") {
        AlertaError("Campo DESCRIÇÃO não pode ser em branco!");
        return false;
      }

      if (this.value === "") {
        AlertaError("Campo VALOR não pode ser em branco!");
        return false;
      }
      if (this.limit === "") {
        AlertaError("Campo LIMITE não pode ser em branco!");
        return false;
      }

      if (this.product_fk === "") {
        AlertaError("Campo PRODUTO não pode ser em branco!");
        return false;
      }
      if (this.type_plan_fk === "") {
        AlertaError("Campo TIPO PLANO não pode ser em branco!");
        return false;
      }
      if ($("#feature_fk").val().length === 0) {
        AlertaError("Campo FEATURE não pode ser em branco!");
        return false;
      }

      if (this.portal_fk.length === 0) {
        AlertaError("Campo PORTAL não pode ser em branco!");
        return false;
      }
      return true;
    }
  };

  @action ClearInputs = () => {
    this.name = "";
    this.description = "";
    this.value = "";
    this.limit = "";
    this.product_fk = "";
    this.type_plan_fk = "";
    this.feature_fk = [];
    this.portal_fk = [];
  };

  @action onChange = (evento) => {
    const campo = evento.target.name;
    this[campo] = evento.target.value;

    if (campo === "product_fk") {
      // this.consultaProdutoID();
    }

    this.feature_fk = $("#feature_fk").val();
    this.portal_fk = $("#portal_fk").val();
  };

  @action ChecksCuurentPage = () => {
    if (this.pagina_atual === 0) {
      this.pagina_atual = 1;
    }
  };
}

const planosStores = new PlanosStores();
export default planosStores;
