import React, { Component } from "react";
import TabComboPlanos from "./TabComboPlanos";
import InfoNav from "../Nav/InfoNav";
import ModalCadCombo from "./ModalPlanos/ModalCadCombo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class IndexPlanos extends Component {
  render() {
    return (
      <div>
        <InfoNav
          title="Produtos Cadastrados"
          height={"110px"}
          children={<ModalCadCombo />}
          getIcon={() =>  <FontAwesomeIcon icon={"database"} />}
        />
        <TabComboPlanos />
      </div>
    );
  }
}
