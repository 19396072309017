import React from "react";
import DataTable from "react-data-table-component";
import { columnsDashboardLogin, customStyles } from "./styleTabelaDashboard";
import { AvisoEsclamacao } from "../ComponentsPadrao/MensagemArrayVazio";
import ContratosStores from '../../Stores/ContratosStores'
import MaquinaDeEstadoStores from '../../Stores/MaquinaDeEstadoStores'


const LastAccessToTheSystem = ({ data }) => {
	const onRedirect = ({ id }) => {
		ContratosStores.consultaContratoEmpresaID(id);
    MaquinaDeEstadoStores.redirect("contratos");
	};

	return (
		<DataTable
			columns={columnsDashboardLogin}
			customStyles={customStyles}
			data={data !== undefined ? data : []}
			pointerOnHover
			onRowClicked={(row) => onRedirect(row)}
			striped
			highlightOnHover
			noDataComponent={<AvisoEsclamacao texto={'Cliente não localizado'} />
			}
		/>

	)
};

export default LastAccessToTheSystem
