import React from "react";
import {CardBodyDashboatd, CardDashboatd} from "./styleDashboard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const CardDashboard = ({background, numero, texto, icone}) => {
    return(
        <CardDashboatd background={background} >
            <CardBodyDashboatd>
                <div style={{marginTop: '-10px'}}>
                    <FontAwesomeIcon icon={icone} size={'3x'}/>
                </div>
                <div style={{textAlign: 'center', width: '80%'}}>
                    <span> {texto}</span>
                    <div style={{fontSize: '50px', marginTop: '-7px'}}>{numero}</div>
                </div>
            </CardBodyDashboatd>
        </CardDashboatd>
    )
};