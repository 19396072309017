import  { observable, action } from 'mobx'

class ModalLoading {
    @observable status = false;
    @observable requests = 0;

    @action toogleModal = () => {
        this.status = !this.status
    };

    @action clouseModal = () => {
        if (!--this.requests) this.status = false
    };

    @action openModal = () => {
        if (!this.requests++) this.status = true
    };

}

const modalLoading = new ModalLoading();
export default modalLoading;