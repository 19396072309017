import React, {Component} from 'react';
import Modal from 'react-modal';
import {Button, Col, Form, ButtonGroup, InputGroup} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {BottomModal} from "../../Modal/BottomModal";
import ModalHeard from "../../Modal/ModalHeard";
import {inject, observer} from "mobx-react";
import {FormLabelModal} from "../../Modal/LabelModal";

@inject('TiposPlanosStores')
@observer
class ModalEditarTipoPlano extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false
        };
    }

    handleCloseModal = () => {
        this.setState({ isActive: false });
    };

    handleOpenModal = () => {
        this.props.TiposPlanosStores.description = this.props.tipo_plano.description;
        this.props.TiposPlanosStores.name = this.props.tipo_plano.name;
        this.setState({ isActive: true });
    };

    handleUpdateTipoPlanoCloseModal = (evento) => {
        evento.preventDefault();
        if (this.props.TiposPlanosStores.ChecksInputs()){
            this.props.TiposPlanosStores.UpdateTipoPlanoId(this.props.tipo_plano.id);
            this.handleCloseModal()
        }else {
        }
    };

    render() {

        return (
            <span>
               <ButtonGroup aria-label="Basic example">
                    <Button style={{fontSize: '10px'}} size="sm" variant="warning"  onClick={()=> this.handleOpenModal()} title="EDITAR REGISTRO"><FontAwesomeIcon style={{color: 'white'}} icon={'edit'}/> </Button>
                </ButtonGroup>

                 <Modal
                     className="Modal_Load"
                     style={{content: {width: '600px', height: '360px', left: '47%', top: '38%' }}}
                     overlayClassName="Overlay_Load"
                     isOpen={this.state.isActive}>
                        <ModalHeard icon={'list-alt'} titulo={"Cadastrar Tipo Plano"}  onClick={()=> this.handleCloseModal()}/><br/>
                        <div>
                            <Col md={12} style={{marginTop: '1%'}}>
                                <Form>
                                    <Form.Group>
                                        {FormLabelModal("nome tipo produto")}
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend"><FontAwesomeIcon icon="bookmark"/></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            name="name"
                                            type="text"
                                            placeholder="..."
                                            aria-describedby="inputGroupPrepend"
                                            defaultValue= {this.props.tipo_plano.name}
                                            onChange={(e) => this.props.TiposPlanosStores.onChange(e)}/>
                                    </InputGroup>
                                    </Form.Group>

                                    <Form.Group>
                                        {FormLabelModal("descrição")}
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend"><FontAwesomeIcon icon="align-center"/></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            as="textarea" rows="3"
                                            name="description"
                                            type="text"
                                            placeholder="..."
                                            aria-describedby="inputGroupPrepend"
                                            defaultValue= {this.props.tipo_plano.description}
                                            onChange={(e) => this.props.TiposPlanosStores.onChange(e)}/>
                                    </InputGroup>
                                    </Form.Group>
                                    <div  style={{textAlign: 'right'}}>
                                        <Button variant="outline-success" size='sm' onClick={(evento) => this.handleUpdateTipoPlanoCloseModal(evento)}>Editar</Button>
                                        <Button variant="outline-danger" size='sm' onClick={() => this.handleCloseModal()} style={{marginLeft: '10px'}}>Fechar</Button>
                                    </div>
                                </Form>
                            </Col>
                        </div>
                        {BottomModal}
                </Modal>
            </span>
        );
    }
}

export default ModalEditarTipoPlano;

