import React from "react";
import DataTable from "react-data-table-component";
import { columnsDashboard, customStyles } from "./styleTabelaDashboard";
import { AvisoEsclamacao } from "../ComponentsPadrao/MensagemArrayVazio";
import ContratosStores from "../../Stores/ContratosStores";
import MaquinaDeEstadoStores from "../../Stores/MaquinaDeEstadoStores";

const LastCompaniesRegistered = ({ data }) => {
	const onRedirect = ({ id }) => {
		ContratosStores.consultaContratoEmpresaID(id);
		MaquinaDeEstadoStores.redirect("contratos");
	};

	return (
		<DataTable
			columns={columnsDashboard}
			customStyles={customStyles}
			allowOverflow={false}
			overflowY={false}
			data={data !== undefined ? data : []}
			onRowClicked={(row) => onRedirect(row)}
			pointerOnHover
			striped
			highlightOnHover
			noDataComponent={<AvisoEsclamacao texto={"Cliente não localizado"} />}
		/>
	);
};

export default LastCompaniesRegistered