import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyCfeRwGmuIRjCwXvqy9vpzp3_yf9pp4cYk",
    authDomain: "bots-editais.firebaseapp.com",
    databaseURL: "https://bots-editais.firebaseio.com",
    projectId: "bots-editais",
    storageBucket: "bots-editais.appspot.com",
    messagingSenderId: "50681324011",
    appId: "1:50681324011:web:49216e2aa9760805f5b9ae"
};

export const firebaseImpl = firebase.initializeApp(config);
export const firebaseDatabase = firebase.database();

