import React, { Component } from "react";
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";
import { inject, observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, InputGroup } from "react-bootstrap";

import ModalHeard from "../../Modal/ModalHeard";
import { BottomModal } from "../../Modal/BottomModal";
import { FormLabelModal } from "../../Modal/LabelModal";

@inject(
  "ContratosStores",
  "ComboStores",
  "ProdutosStores",
  "PlanosStores",
  "MaquinaDeEstadoStores"
)
@observer
class ModalCadEmpresaContratos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      selectedOption: "combo",
    };
  }

  handleCloseModal = () => {
    this.setState({ isActive: false });
  };

  handleOpenModal = () => {
    this.props.ContratosStores.removeComboAndDate();

    this.props.ContratosStores.consultaPlanoNovo();
    this.props.ComboStores.consultaCombo();
    this.setState({ isActive: true });
  };

  handleAddContratoCloseModal = (e, id) => {
    e.preventDefault();
    this.props.ComboStores.consultaCombo();
    if (this.props.ContratosStores.ChecksInputs()) {
      this.props.ContratosStores.addContratoNovo(id);
      this.setState({ isActive: false });
    } else {
    }
  };

  handleOptionChange = (changeEvent, option) => {
    if (changeEvent.target.value === "") {
      this.setState({
        selectedOption: option,
      });
    } else {
      this.setState({
        selectedOption: changeEvent.target.value,
      });
    }
  };

  render() {
    const { haveAContact, combo_fk, ends_in } = this.props.ContratosStores;
    const { id, nome_fantasia } = this.props.ContratosStores.dadosEmpresa;

    return (
      <span>
        <div
          data-place="bottom"
          data-tip={
            haveAContact
              ? "Empresa já possui um plano, para adicionar ou substitui-lo, remova o atual"
              : ""
          }
          effect="solid"
          disabled
        >
          <Button
            onClick={() => this.handleOpenModal()}
            style={{ marginRight: "20px" }}
            variant="success"
            size="sm"
            disabled={haveAContact ? true : false}
          >
            <ReactTooltip />
            <FontAwesomeIcon icon={"id-card"} /> CADASTRAR CONTRATO
          </Button>
        </div>

        <Modal
          className="Modal_Load"
          style={{
            content: {
              width: "600px",
              height: "420px",
              left: "47%",
              top: "38%",
            },
          }}
          overlayClassName="Overlay_Load"
          isOpen={this.state.isActive}
        >
          <ModalHeard
            icon={"clipboard-list"}
            titulo={"CADASTRAR CONTRATO"}
            onClick={() => this.handleCloseModal()}
          />
          <br />
          <div>
            <Col md={12} style={{ marginTop: "1%" }}>
              <Form>
                <Form.Group>
                  {FormLabelModal("empresa")}
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon="clipboard-list" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      disabled={true}
                      name="company_fk"
                      type="text"
                      placeholder="data"
                      defaultValue={nome_fantasia}
                      aria-describedby="inputGroupPrepend"
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  {FormLabelModal("combo")}
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon="dice-d6" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      as="select"
                      name="combo_fk"
                      onChange={(e) => this.props.ContratosStores.onChange(e)}
                    >
                      <option disabled={false} value={""}>
                        Selecione um combo
                      </option>
                      {this.props.ComboStores.combos.length &&
                        this.props.ComboStores.combos.map((combo, i) => {
                          return (
                            <option key={i} value={combo.id}>
                              {combo.name}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  {FormLabelModal("data")}
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon="calendar" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      name="ends_in"
                      onChange={(e) => this.props.ContratosStores.onChange(e)}
                      type="date"
                      placeholder="data"
                      aria-describedby="inputGroupPrepend"
                    />
                  </InputGroup>
                </Form.Group>
                <div style={{ textAlign: "right" }}>
                  <Button
                    disabled={ends_in && combo_fk ? false : true}
                    variant="outline-success"
                    size={"sm"}
                    onClick={(e) => this.handleAddContratoCloseModal(e, id)}
                  >
                    Cadastrar
                  </Button>
                  <Button
                    variant="outline-danger"
                    size={"sm"}
                    onClick={() => this.handleCloseModal()}
                    style={{ marginLeft: "10px" }}
                  >
                    Fechar
                  </Button>
                </div>
              </Form>
            </Col>
          </div>
          {BottomModal}
        </Modal>
      </span>
    );
  }
}

export default ModalCadEmpresaContratos;
