import React, { Component } from "react";
import { Row, Table } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import ModalExcluirBase from "../../Modal/ModalExcluirBase";
import ModalEditarVendedor from "../ModalConfiguracoes/ModalEditarVendedor";

@inject("VedStores")
@observer
class TabVendedoresConf extends Component {
  constructor(props) {
    super(props);
    this.state = "";
  }

  componentDidMount() {
    this.props.VedStores.consultaVendedor();
  }

  render() {
    return (
      <Row
        style={{
          paddingLeft: "14px",
          paddingRight: "14px",
          marginTop: "10px",
          fontSize: "15px",
        }}
      >
        <Table striped bordered condensed hover>
          <thead>
            <tr>
              <th style={{ width: "5%", textAlign: "center" }}>#</th>
              <th style={{ width: "30%" }}>VENDEDOR</th>
              <th style={{ width: "40%" }}>DESCRIÇÃO</th>
              <th style={{ width: "8%", textAlign: "center" }}>AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {this.props.VedStores.vendedores.map((vendedor) => {
              return (
                <tr key={vendedor.id}>
                  <td style={{ textAlign: "center" }}>{vendedor.id}</td>
                  <td>{vendedor.name}</td>
                  <td>{vendedor.email}</td>
                  <td style={{ textAlign: "center" }}>
                    <ModalEditarVendedor vendedor={vendedor} />
                    &nbsp;
                    <ModalExcluirBase
                      nome={vendedor.name}
                      onClick={() =>
                        this.props.VedStores.delVendedor(
                          vendedor.id,
                          vendedor.name
                        )
                      }
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Row>
    );
  }
}

export default TabVendedoresConf;
