import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Modal from "react-modal";
import { Button, Col, Form, ButtonGroup, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BottomModal } from "../../Modal/BottomModal";
import ModalHeard from "../../Modal/ModalHeard";
import { FormLabelModal } from "../../Modal/LabelModal";

@inject("PortaisStores")
@observer
class ModalCadPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
  }

  handleCloseModal = () => {
    this.setState({ isActive: false });
    this.props.PortaisStores.ClearInputs();
  };
  handleOpenModal = () => {
    this.setState({ isActive: true });
  };

  handleAddPortalCloseModal = (evento) => {
    evento.preventDefault();
    if (this.props.PortaisStores.ChecksInputs()) {
      this.props.PortaisStores.addPortal(evento);
      this.handleCloseModal();
    } else {
    }
  };

  render() {
    return (
      <span>
        <ButtonGroup aria-label="Basic example">
          <Button size="sm" disabled variant="outline-primary">
            &nbsp;+
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={() => this.handleOpenModal()}
          >
            Portal
          </Button>
        </ButtonGroup>

        <Modal
          className="Modal_Load"
          style={{
            content: {
              width: "600px",
              height: "440px",
              left: "47%",
              top: "38%",
            },
          }}
          overlayClassName="Overlay_Load"
          isOpen={this.state.isActive}
        >
          <ModalHeard
            icon={"door-open"}
            titulo={"CADASTRAR PORTAL"}
            onClick={() => this.handleCloseModal()}
          />
          <br />
          <div>
            <Col md={12} style={{ marginTop: "1%" }}>
              <Form>
                <Form.Group>
                  {FormLabelModal("portal")}
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon="door-open" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      name="nome"
                      type="text"
                      placeholder="..."
                      aria-describedby="inputGroupPrepend"
                      onChange={(e) => this.props.PortaisStores.onChange(e)}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  {FormLabelModal("descrição")}
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon="align-center" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      name="descricao"
                      type="text"
                      placeholder="..."
                      aria-describedby="inputGroupPrepend"
                      onChange={(e) => this.props.PortaisStores.onChange(e)}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  {FormLabelModal("url")}
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        URL
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      name="url"
                      type="url"
                      placeholder="https://..."
                      aria-describedby="inputGroupPrepend"
                      onChange={(e) => this.props.PortaisStores.onChange(e)}
                    />
                  </InputGroup>
                </Form.Group>

                <div style={{ textAlign: "right" }}>
                  <Button
                    variant="outline-success"
                    size="sm"
                    onClick={(evento) => this.handleAddPortalCloseModal(evento)}
                  >
                    Cadastrar
                  </Button>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => this.handleCloseModal()}
                    style={{ marginLeft: "10px" }}
                  >
                    Fechar
                  </Button>
                </div>
              </Form>
            </Col>
          </div>
          {BottomModal}
        </Modal>
      </span>
    );
  }
}
export default ModalCadPortal;
