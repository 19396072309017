import React from 'react';
import {Container, NumeroCapitura, Portal, Status, Tempo} from "./styles";
import Loading from "../../loading";

const BadegPortais = ({data, loading}) => {

    const statusColor = (text_error, registered_items, registered_notices) => {
        if (text_error){
            return '#c41d1d'
        }
        if ((registered_items === 0) && (registered_notices === 0)) {
            return 'orange'
        } else {
            return '#169111'
        }
    }

    return (
        <>
            {loading  && <Loading/>}

            {data.map((item) => {
                const {time_start, registered_notices, registered_items, portal, duration, date_start, text_error} = item.val()
                const cor = statusColor(text_error, registered_items, registered_notices)
                return (
                    <Container status={cor}>
                        <Status status={cor}/>
                        <Portal>
                            {portal}
                        </Portal>

                        <NumeroCapitura status={cor}>
                            {!text_error ?

                            <>
                                <div className={'wrapper'}>
                                    <div className={'edital'}>{registered_notices}</div>
                                    <div className={'texto'}>EDITAL</div>
                                </div>
                                <div className={'wrapper'}>
                                    <div className={'item'}>{registered_items}</div>
                                    <div className={'texto'}>ITENS</div>
                                </div>
                            </>

                                : <div className={'texto-error'}>
                                    {text_error}
                                 </div>
                                }
                        </NumeroCapitura>

                        <Tempo>
                            <div className={'start'}>
                                {time_start}
                            </div>
                            <div className={'duracao'}>
                                {duration}
                                <div> {date_start}</div>
                            </div>
                        </Tempo>
                    </Container>
                )
            }).reverse()}
        </>
    );
};

export default BadegPortais;

