import { observable, action } from "mobx";
import { AlertaError, AlertaSuccess } from "./Funcoes/FuncoesGerais";
import Api from "./Conexao/conexao";
import { globalDebug } from "./Funcoes/Debug";
import ClienteStores from "./ClienteStores";
import ModalLoading from "./ModalLoading";

class ClienteEmpresaStores {
  @observable nome_fantasia = "";
  @observable razao_social = "";
  @observable cnpj = "";
  @observable phone = "";
  @observable user = [];
  @observable count = null;
  @observable empresas_total = 0;
  @observable pagina_atual = 1;
  @observable Empresas = [];
  @observable idCliente = "";
  @observable text = "";
  @observable resultSearch = [];
  @observable valueSearch = false;
  @observable countSearch = null;
  @observable previus = null;
  @observable next = null;

  @observable isModalVisible = false
  @observable loadingModal = false

  @action showModal = () => {
    this.isModalVisible = true
  };

  @action handleOk = () => {
    this.isModalVisible = false
  };

  @observable userInfo = {}

  @action getUserInfo = (idUser) => async () => {
    this.showModal()
    this.loadingModal = true
    try {
      const res = await Api.get(
        `platform_user/${idUser}`
      );
      this.userInfo = res.data
    } catch (err) {
      globalDebug("info/USER", err);
    } finally {
      this.loadingModal = false
    }
  }

  @action setText = (txt) => {
    this.text = txt;
    if (!txt) this.valueSearch = false;
  };

  @action searchCompanys = async (text, page) => {
    ModalLoading.openModal();
    this.resultSearch = [];
    this.valueSearch = true;
    try {
      const res = await Api.get(
        Number.isInteger(page)
          ? `search_empresa?filter=${text}&page=${page}`
          : `search_empresa?filter=${text}`
      );
      this.resultSearch = res.data.results;
      this.countSearch = res.data.count;
      this.next = res.data.next;
      this.previus = res.data.previus;
    } catch (err) {
      globalDebug("consulta/CONTRATO", err);
    } finally {
      ModalLoading.clouseModal();
    }
  };

  @action consultaEmpresas = async (value) => {
    ModalLoading.openModal();
    this.Empresas = [];
    this.ChecksCuurentPage();
    try {
      const res = await Api.get(
        value ? `empresas/?page=${value + 1}&pagina=1` : "empresas/?pagina=1"
      );
      this.Empresas = res.data.results;
      this.count = res.data.count;
    } catch (err) {
      ModalLoading.clouseModal();
      globalDebug("consulta/EMPRESAS", err);
    } finally {
      ModalLoading.clouseModal();
    }
  };

  @action consultaEmpresasSemLoading = async (value) => {
    this.ChecksCuurentPage();
    try {
      const res = await Api.get(
        value ? `empresas/?page=${value}&pagina=1` : "empresas/?pagina=1"
      );
      this.Empresas = res.data.results;
      this.count = res.data.count;
      this.nextPage = res.data.next;
      this.previousPage = res.data.previous;
    } catch (err) {
      globalDebug("consulta/EMPRESAS", err);
    }
  };

  @action addEmpresa = (id) => {
    this.user = [parseInt(localStorage.getItem("id_cliente"))];
    let gerencial = parseInt(localStorage.getItem("gerencia"));
    Api.post("empresa/add", {
      nome_fantasia: this.nome_fantasia,
      razao_social: this.razao_social,
      cnpj: this.cnpj,
      phone: this.phone,
      user: id,
      gerencial: gerencial,
    })
      .then(() => {
        AlertaSuccess(
          "EMPRESA " + this.nome_fantasia + " adicionado com sucesso!"
        );
        this.consultaEmpresas();
        this.ClearInputs();
      })
      .catch((error) => {
        globalDebug("ADD/EMPRESA", error);
      });
  };

  @action deletar = (id) => {
    console.log("DELETOU")
    Api.delete("empresa/" + id)
      .then(() => {
        this.onPageChanged({ currentPage: 1 }); 
        ClienteStores.consultaClienteId(localStorage.getItem("id_cliente"));
      })
      .catch((error) => {
        globalDebug("DEL/EMPRESA", error);
      })
      .finally(() => {
        AlertaSuccess("EMPRESA  excluida com sucesso!");
      });
  };

  @observable adminUser = null

  @action setAdminUser = (value) => {
    this.adminUser = value
  }

  @action UpdateEmpresasId = (id, idUsuario) => {
    this.user = [parseInt(localStorage.getItem("id_cliente"))];
    Api.patch("empresa/" + id, {
      nome_fantasia: this.nome_fantasia,
      razao_social: this.razao_social,
      cnpj: this.cnpj,
      phone: this.phone,
      user: idUsuario,
      gerencial: this.adminUser.gerencial,
    })
      .then(() => {
        AlertaSuccess(
          "Usuário adicionado a empresa com sucesso"
        );
        this.consultaEmpresas();

        this.ClearInputs();
      })
      .catch((error) => {
        globalDebug("ADD/EMPRESA", error);
      });
  };

  @action ChecksInputs = (idUsuario) => {
    if (
      this.nome_fantasia === "" &&
      this.cnpj === "" &&
      this.phone &&
      this.razao_social === ""
    ) {
      AlertaError("Campos não podem ser em brancos!");
      return false;
    } else {
      if (this.nome_fantasia === "") {
        AlertaError("Campo NOME FANTASIA não pode ser em branco!");
        return false;
      }
      if (this.razao_social === "") {
        AlertaError("Campo RAZÃO SOCIAL não pode ser em branco!");
        return false;
      }
      if (this.cnpj === "") {
        AlertaError("Campo CNPJ não pode ser em branco!");
        return false;
      }
      if (this.phone === "") {
        AlertaError("Campo TELEFONE não pode ser em branco!");
        return false;
      }
      if (idUsuario.length === 0) {
        AlertaError("Campo Usuario não pode ser em branco!");
        return false;
      }
    }
    return true;
  };

  @action ClearInputs = () => {
    this.nome_fantasia = "";
    this.razao_social = "";
    this.cnpj = "";
    this.phone = "";
    this.user = [];
  };

  @action onChangeIdCliente = (evento) => {
    console.log(evento);
    this.idCliente = evento.value;
  };

  @action onChange = (evento) => {
    const campo = evento.target.name;
    this[campo] = evento.target.value;
  };

  @action ChecksCuurentPage = () => {
    if (this.pagina_atual === 0) {
      this.pagina_atual = 1;
    }
  };

  // @action onPageChanged = (data) => {
  //   const { currentPage } = data;
  //   this.pagina_atual = currentPage;
  //   this.consultaEmpresas();
  // };
}

const clienteEmpresaStores = new ClienteEmpresaStores();
export default clienteEmpresaStores;
