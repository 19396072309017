import {Alert, Button, Col, Row} from "react-bootstrap";
import Modal from 'react-modal';
import {inject, observer} from 'mobx-react'
import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";
import MoldaHeardDanger from "./MoldaHeardDanger";

@inject('ClienteStores', 'ClienteEmpresaStores')
@observer
class ModalExcluirBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
        };
    };

    handleCloseModal = () => {
        this.setState({isActive: false});
    };


    handleCloseModalExcluir = () => {
        const {id, store} = this.props;
        this.setState({isActive: false});
        console.log(store, "store")
        this.props[store].deletar(id)
    };

    handleOpenModal = () => {
        this.setState({isActive: true});
    };

    render() {
        return (
            <span>
                 <Button style={{fontSize: '10px'}} size="sm" variant="danger" onClick={() => this.handleOpenModal()}
                         title="EXCLUIR REGISTRO"><FontAwesomeIcon icon='trash'/> </Button>
                 <Modal
                     className="Modal_Load"
                     style={{content: {width: '520px', height: '350px', left: '50%', top: '38%'}}}
                     overlayClassName="Overlay_Load"
                     isOpen={this.state.isActive}>
                        <MoldaHeardDanger icon={'trash'} titulo={"Excluir"}
                                          onClick={() => this.handleCloseModal()}/><br/>
                        <Row>
                            <Col md={12} style={{textAlign: 'center', marginTop: '1%'}}>
                                <span style={{color: '#EE5666', fontSize: '250%'}}><FontAwesomeIcon
                                    icon={'user-times'}/></span>
                                <h4>Deseja excluir este REGISTRO? </h4>
                                <Row className="justify-content-md-center">
                                     <Col md={10} style={{textAlign: 'center', marginTop: '1%', alignItems: 'center'}}>
                                    <Alert style={{textAlign: 'center', marginTop: '1%'}}
                                           variant="info">{this.props.nome}</Alert>
                                </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="text-center" style={{marginTop: '20px'}}>
                                <Button variant="outline-danger" onClick={() => this.handleCloseModal()}>
                                    Não
                                </Button>
                                <Button variant="outline-primary" type="submit" style={{marginLeft: '10px'}}
                                        onClick={this.handleCloseModalExcluir}>
                                   Sim
                                </Button>
                            </Col>
                        </Row>
                     <Row className="fixed-bottom" style={{marginLeft: '0px', marginRight: '0px'}}>
                        <Col md={12} style={{
                            borderBottomLeftRadius: '5px',
                            borderBottomRightRadius: '5px',
                            height: '18px',
                            background: '#d8d8d8',
                            color: 'white'
                        }}>
                        </Col>
                     </Row>
                </Modal>
            </span>
        );
    }
}

export default ModalExcluirBase;
