import Modal from "react-modal";
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Row } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import { customStyleLoad } from "./style/style";

@inject("ModalLoading")
@observer
class ModalLoading extends Component {
  render() {
    const { status } = this.props.ModalLoading;
    return (
      <Modal
        style={customStyleLoad}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        className="fi"
        isOpen={status}
      >
        <div style={{ height: "100%" }}>
          <Row className="text-center">
            <style type="text/css">
              {`
                .blur-container{
                  -webkit-filter: blur(5px);
                  -moz-filter: blur(5px);
                  -o-filter: blur(5px);
                  -ms-filter: blur(5px);
                  filter: blur(5px);
                }
              `}
            </style>
            <h3 style={{color: "#777"}}>
              <FontAwesomeIcon icon={faSpinner} spin /> CARREGANDO{" "}
            </h3>
          </Row>
        </div>
      </Modal>
    );
  }
}

export default ModalLoading;
