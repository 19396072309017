import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import { AlertaError, AlertaSuccess } from "./Funcoes/FuncoesGerais";
import { globalDebug } from "./Funcoes/Debug";
import ModalLoading from "./ModalLoading";

class PortaisStores {
  @observable nome = "";
  @observable url = "";
  @observable descricao = "";
  @observable portal = [];
  @observable portal_total = 0;
  @observable pagina_atual = 1;

  @action consultaPortal = async (value) => {
    ModalLoading.openModal();
    this.ChecksCuurentPage();
    try {
      const response = await Api.get(`portals?page=${value}`);
      this.portal_total = response.data.count;
      this.portal = response.data.results;
    } catch (err) {
      globalDebug("consulta/PORTAL", err);
    }finally {
      ModalLoading.clouseModal();
    }
  };

  @action addPortal = () => {
    Api.post("portal/add", {
      nome: this.nome,
      descricao: this.descricao,
      url: this.url,
    })
      .then(() => {
        AlertaSuccess("PORTAL " + this.nome + " adicionado com sucesso!");
        this.consultaPortal();
        this.ClearInputs();
        this.onPageChanged({ currentPage: 1 });
      })
      .catch((error) => {
        globalDebug("add/PORTAL", error);
      });
  };

  @action consultaPortalId = (id) => {
    this.ClearInputs();
    Api.get("portal/" + id)
      .then((response) => {
        this.nome = response.data.nome;
        this.url = response.data.url;
        this.descricao = response.data.descricao;
      })
      .catch((error) => {
        globalDebug("consulta/PORTAL", error);
      });
  };

  @action UpdatePortalId = (id) => {
    console.log("UpdatePortalId");
    if (this.ChecksInputs())
      Api.patch("portal/" + id, {
        nome: this.nome,
        descricao: this.descricao,
        url: this.url,
      })
        .then(() => {
          AlertaSuccess("PORTAL " + this.nome + " editado com sucesso!");
          this.consultaPortal();
          this.ClearInputs();
        })
        .catch((error) => {
          globalDebug("UpDate/PORTAL", error);
        });
  };

  @action delPortal = (id, nome) => {
    console.log("delPortal");
    Api.delete("portal/" + id)
      .then(() => {
        this.onPageChanged({ currentPage: 1 });
        AlertaSuccess("PORTAL " + nome + " excluido com sucesso!");
        this.consultaPortal();
      })
      .catch((error) => {
        globalDebug("del/PORTAL", error);
      });
  };

  @action ClearInputs = () => {
    this.nome = "";
    this.descricao = "";
    this.url = "";
  };

  @action ChecksInputs = () => {
    if (this.nome === "" && this.descricao === "" && this.url === "") {
      AlertaError("Campos NOME, DESCRIÇÃO E URL não podem ser em brancos!");
      return false;
    } else {
      if (this.nome === "") {
        AlertaError("Campo NOME não pode ser em branco!");
        return false;
      }
      if (this.descricao === "") {
        AlertaError("Campo DESCRIÇÃO não pode ser em branco!");
        return false;
      }
      if (this.url === "") {
        AlertaError("Campo URL não pode ser em branco!");
        return false;
      }
      return true;
    }
  };

  @action onChange = (evento) => {
    const campo = evento.target.name;
    this[campo] = evento.target.value;
  };

  @action ChecksCuurentPage = () => {
    if (this.pagina_atual === 0) {
      this.pagina_atual = 1;
    }
  };

  @action onPageChanged = (data) => {
    const { currentPage } = data;
    this.pagina_atual = currentPage;
    // this.consultaPortal();
  };
}

const portaisStores = new PortaisStores();
export default portaisStores;
