import React, { Component } from 'react';
import { inject, observer } from 'mobx-react'
import Modal from 'react-modal';
import { Button, Col, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { BottomModal } from "../../Modal/BottomModal";
import ModalHeard from "../../Modal/ModalHeard";
import { FormLabelModal } from "../../Modal/LabelModal";
import InputMask from "react-input-mask";

@inject('ClienteStores', 'VedStores')
@observer
class ModalCadCliente extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
        };
    };

    handleCloseModal = () => {
        this.setState({ isActive: false });
        this.props.ClienteStores.ClearInputs()
    };
    handleOpenModal = () => {
        this.setState({ isActive: true });
        this.props.ClienteStores.ClearInputs();
    };


    handleAddClienteCloseModal = (e) => {
        e.preventDefault();
        if (this.props.ClienteStores.ChecksInputs()) {
            this.props.ClienteStores.addClientes();
            this.handleCloseModal()
        } else {
        }
    };

    render() {
        return (
            <span>
                <Button
                    onClick={() => this.handleOpenModal()}
                    variant="secondary"
                    size="sm"
                >
                    <FontAwesomeIcon icon={'user-circle'} /> CADASTRAR CLIENTE
                </Button>

                <Modal
                    className="Modal_Load"
                    style={{ content: { width: '40vw', height: '650px', left: '47%', top: '36%' } }}
                    overlayClassName="Overlay_Load"
                    isOpen={this.state.isActive}>

                    <ModalHeard icon={'user-tie'} titulo={"CADASTRAR CLIENTE"}
                        onClick={() => this.handleCloseModal()} /><br />
                    <div>
                        <Col md={12} style={{ marginTop: '1%' }}>
                            <Form>
                                <Form.Group controlId="formBasicEmail">
                                    {FormLabelModal("nome do cliente")}
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend"><FontAwesomeIcon
                                                icon={'user-circle'} /></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control name={'first_name'} type="text" placeholder="nome"
                                            onChange={(e) => this.props.ClienteStores.onChange(e)} />
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail">
                                    {FormLabelModal("sobrenome")}
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend"><FontAwesomeIcon
                                                icon={'user-circle'} /></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control name={'last_name'} type="text" placeholder="sobrenome"
                                            onChange={(e) => this.props.ClienteStores.onChange(e)} />
                                    </InputGroup>
                                </Form.Group>


                                <Form.Group controlId="formBasicEmail">
                                    {FormLabelModal("email")}
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control name={'email'} type="email" placeholder="email@email"
                                            onChange={(e) => this.props.ClienteStores.onChange(e)} />
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail">
                                    {FormLabelModal("password")}
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend">**</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control name={'password'} type="text" placeholder="password"
                                            onChange={(e) => this.props.ClienteStores.onChange(e)} />
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail">
                                    {FormLabelModal("digite password novamente")}
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend">**</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control name={'repeat_password'} type="text"
                                            placeholder="digite password novamente"
                                            onChange={(e) => this.props.ClienteStores.onChange(e)} />
                                    </InputGroup>
                                </Form.Group>

                                {FormLabelModal("telefone")}
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <div style={{ display: 'flex', width: '100%', }}>
                                        <InputGroup.Text style={{ height: '41px', width: '42px' }} id="inputGroupPrepend">
                                            <FontAwesomeIcon icon="phone-square" />
                                        </InputGroup.Text>

                                        <InputMask name={'phone'} style={{
                                            marginLeft: '-0.7%',
                                            width: '100%',
                                            border: '1.2px',
                                            borderRadius: '4px',
                                            height: '41px',
                                            borderColor: '#ced4da',
                                            borderStyle: 'solid'
                                        }} mask="(99) 99999-9999" maskChar={null} placeholder="  telefone.."
                                            onChange={(e) => this.props.ClienteStores.onChange(e)}>
                                        </InputMask>
                                    </div>
                                </div>

                                <div style={{ textAlign: 'right', marginTop: '10px' }}>
                                    <Button variant="outline-success" size="sm"
                                        onClick={(e) => this.handleAddClienteCloseModal(e)}>Cadastrar</Button>
                                    <Button variant="outline-danger" size="sm"
                                        onClick={() => this.handleCloseModal()}
                                        style={{ marginLeft: '10px' }}>Fechar</Button>
                                </div>
                            </Form>
                        </Col>
                    </div>

                    {BottomModal}

                </Modal>
            </span>

        );
    }
}

export default ModalCadCliente;


