import React from "react";
import styled from "styled-components";
import ModalClientesEmpresa from "./ModalCliente/ModalClientesEmpresa";
import ModalEditarCliente from "./ModalCliente/ModalEditarCliente";
import ModalExcluirBase from "../Modal/ModalExcluirBase";

export const Container = styled.div`
  padding: 6px 10px;
  margin-top: -49px;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const columnsCliente = [
  {
    name: "ID",
    selector: "id",
    sortable: true,
    hide: "md",
    width: "100px",
    style: {
      justifyContent: "center",
    },
  },
  {
    name: "NOME CLIENTE",
    selector: "first_name",
    sortable: true,
    style: {
      justifyContent: "center",
    },
  },
  {
    name: "EMAIL",
    selector: "username",
    sortable: true,
    style: {
      justifyContent: "center",
    },
  },
  {
    name: "TELEFONE",
    selector: "phone",
    sortable: true,
    style: {
      justifyContent: "center",
    },
  },
  {
    name: "EMPRESAS",
    style: {
      justifyContent: "center",
    },
    cell: (row) => <ModalClientesEmpresa user={row} />,
  },
  {
    name: "EDITAR/EXCLUIR",
    style: {
      justifyContent: "center",
    },
    cell: (row) => (
      <span>
        <ModalEditarCliente user={row} />
        &nbsp;
        <ModalExcluirBase
          nome={row.first_name}
          id={row.id}
          store={"ClienteStores"}
        />
      </span>
    ),
  },
];

export const customStyles = {
  headRow: {
    style: {
      borderBottom: "1.2px solid #a5a5a5",
    },
  },
  headCells: {
    style: {
      color: "#202124",
      fontSize: "14px",
      textAlign: "center",
      fontWeight: "bolder",
      justifyContent: "center",
    },
  },
  cells: {
    style: {
      textAlign: "center",
    },
  },

  rows: {
    style: {
      textAlign: "center",
    },
    highlightOnHoverStyle: {
      backgroundColor: "rgb(230, 244, 244)",
      borderBottomColor: "#FFFFFF",
      borderRadius: "10px",
      outline: "1px solid #FFFFFF",
    },
  },
  pagination: {
    style: {
      color: "black",
    },
  },
};

export const PaginationOptions = styled.div`
  margin: 9px;
`;

export const paginationOptions = {
  rowsPerPageText: "Clientes por página",
  rangeSeparatorText: "de",
};

export const ContainerTabelaCliente = styled.div`
  /* position: absolute;
  top: 169px; */
  background: white;
  border-radius: 10px;
  padding-top: 7px;
  /* width: 99%; */
  height: 72vh;
  box-shadow: 0px 1px 8px 1px #00000091;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
