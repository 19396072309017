import React, { Component } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { inject, observer } from "mobx-react";
import { Badge, Row, Table } from "react-bootstrap";

import ModalExcluirBase from "../../../Modal/ModalExcluirBase";
import ModalEditarFeature from "../../ModalConfiguracoes/ModalEditarFeature";

@inject("FeaturesStores")
@observer
class TabFeaturesConf extends Component {
  componentDidMount() {
    this.props.FeaturesStores.consultaFeatures();
  }

  render() {
    return (
      <Row
        style={{
          paddingLeft: "0.8%",
          paddingRight: "0.8%",
          marginTop: "1%",
          marginBottom: "5%",
          fontSize: "15px",
          alignItems: "center",
          flexWrap: "noWrap",
          flexDirection: "column",
          height: "59vh",
          justifyContent: "space-between",
        }}
      >
        <Table striped bordered condensed hover>
          <thead>
            <tr>
              <th style={{ width: "5%", textAlign: "center" }}>#</th>
              <th style={{ width: "20%" }}>FEATURES</th>
              <th style={{ width: "40%" }}>DESCRIÇÃO</th>
              <th style={{ width: "10%", textAlign: "center" }}>LIMITE</th>
              <th style={{ width: "15%", textAlign: "center" }}>PRODUTO</th>
              <th style={{ width: "10%", textAlign: "center" }}>AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {this.props.FeaturesStores.features.map((features, i) => {
              return (
                <tr key={i}>
                  <td style={{ textAlign: "center" }}>{features.id}</td>
                  <td>{features.name}</td>

                  <td>{features.description}</td>
                  <td style={{ textAlign: "center" }}>
                    <span>
                      <Badge pill variant="info">
                        {features.limit}
                      </Badge>
                    </span>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <span>
                      <Badge pill variant="warning">
                        {features.product_fk.name}
                      </Badge>
                    </span>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <ModalEditarFeature features={features} />
                    &nbsp;
                    <ModalExcluirBase
                      nome={features.name}
                      onClick={() =>
                        this.props.FeaturesStores.delFeatures(
                          features.id,
                          features.name
                        )
                      }
                      status={false}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div>
          <Pagination
            count={Math.ceil(this.props.FeaturesStores.features_total / 50)}
            onChange={this.props.FeaturesStores.handleChangePageTable}
            page={this.props.FeaturesStores.pagina_atual}
            variant="outlined"
            color="primary"
          />
        </div>
      </Row>
    );
  }
}

export default TabFeaturesConf;
