import React from "react";
import {
  AlertCircle,
  Bookmark,
  CheckCircle,
  FileText,
  XCircle,
} from "react-feather";
// import Hora from "./contexto/horas";
import { Container } from "./styles";
// import logo from '../../assets/img/icone_logox.png'
import Bageg from "./contexto/badeg";
import SearchNav from "./contexto/search";

const Nav = ({ status, upadteData, dia }) => {
  // const [audio, setAudio] = useState(false);

  const handleWarning = (warning, edital, itens) => {
    if (edital === 0 && itens === 0) {
      return warning + 1;
    }
    return warning;
  };

  const handleSucesso = (sucesso, edital, itens) => {
    if (edital !== 0 && itens !== 0) {
      return sucesso + 1;
    }
    return sucesso;
  };

  const handleErro = (error, textoError) => {
    if (textoError) {
      return error + 1;
    }

    return error;
  };

  const handleStatus = () => {
    let edital = 0;
    let itens = 0;
    let error = 0;
    let sucesso = 0;
    let warnign = 0;

    status.forEach((item) => {
      const { registered_notices, registered_items, text_error } = item.val();
      let sizeEdital = parseInt(registered_notices);
      let sizeItens = parseInt(registered_items);
      edital = edital + sizeEdital;
      itens = itens + sizeItens;
      error = handleErro(error, text_error);
      warnign = handleWarning(warnign, sizeItens, sizeEdital);
      sucesso = handleSucesso(sucesso, sizeItens, sizeEdital);
    });
    return { edital, itens, sucesso, warnign, error };
  };

  const { edital, itens, sucesso, warnign, error } = handleStatus();
  return (
    <Container>
      <header className={"wrapper"}>
        <Bageg
          icon={<CheckCircle />}
          color={"#0cba74"}
          background={"#0b5135"}
          name={"SUCESSO"}
          number={sucesso}
        />
        <Bageg
          icon={<AlertCircle />}
          color={"#e7aa0c"}
          background={"#866622"}
          name={"WARNING"}
          number={warnign}
        />
        <Bageg
          icon={<XCircle />}
          color={"#701919"}
          background={"#c41d1d"}
          name={"ERROS"}
          number={error}
        />
        <Bageg
          icon={<Bookmark />}
          color={"#9969a7"}
          background={"#4b344e"}
          name={"EDITAL"}
          number={edital}
        />
        <Bageg
          icon={<FileText />}
          color={"#6979a7"}
          background={"#343a4e"}
          name={"ITENS"}
          number={itens}
        />
      </header>
      <div
        style={{ width: "47%", display: "flex", justifyContent: "flex-end" }}
      >
        <SearchNav upadteData={upadteData} dia={dia} />
        {/* <Hora/> */}
      </div>
    </Container>
  );
};

export default Nav;
