import React from "react";
// import { Container } from "react-bootstrap";
import Alert from "react-s-alert";
import Home from "./Components/Home";

import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import "react-s-alert/dist/s-alert-css-effects/scale.css";
import "react-s-alert/dist/s-alert-css-effects/bouncyflip.css";
import "react-s-alert/dist/s-alert-css-effects/flip.css";
import "react-s-alert/dist/s-alert-css-effects/genie.css";
import "react-s-alert/dist/s-alert-css-effects/jelly.css";
import "react-s-alert/dist/s-alert-css-effects/stackslide.css";
import "react-s-alert/dist/s-alert-css-effects/genie.css";
import "react-s-alert/dist/s-alert-default.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free";
import "./Themes/Icons";

import { Provider } from "mobx-react";

class Graphics extends React.Component {
  constructor(props) {
    super(props);
    this.state = "";
  }

  render() {
    return (
      <Provider>
        <div>
          <Alert stack={{ limit: 5, spacing: 0 }} />
          <Home />
        </div>
      </Provider>
    );
  }
}

export default Graphics;
