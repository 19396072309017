import React, {Component} from 'react'
import {inject, observer} from "mobx-react";

@inject("ContratosStores")
@observer
class UsersTable extends Component {

  render() {
    const {users} = this.props.ContratosStores;

    return (
      <>
        {users.length ? users.map((user) => {
          return (
            <div
              style={{
                background: "white",
                padding: "10px",
                borderRadius: "10px",
                border: "1px solid rgb(223, 223, 223)",
                marginBottom: "20px",
                boxShadow: "0px 1px 8px 1px rgba(0,0,0,0.31)",
              }}
            >
              <div
                style={{
                  minHeight: "30px",
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "15px",
                  borderRadius: "7px",
                  color: "rgb(6,81,104)",
                  textTransform: "uppercase",
                }}
              >
                <div style={{marginTop: "7px"}}>
                  <h6>{user.first_name}</h6>
                </div>
              </div>
              <div style={{margin: '10px'}}>
                <div style={{
                  border: '1px solid #dfdfdf',
                  padding: '20px',
                  marginBottom: '5px',
                  borderRadius: '5px',
                }}>
                  <div style={{background: '#fff', borderRadius: '7px',}}>
                    <div style={{
                      display: 'flex',
                      fontSize: '12px',
                      fontWeight: 'bolder',
                    }}>
                      <div style={{width: '30%'}}>
                        {user.first_name && <SubTitulo titulo={user.first_name}/>}
                      </div>
                      <div style={{width: '30%'}}>
                        {user.email && <SubTitulo titulo={user.email}/>}
                      </div>
                      <div style={{width: '20%'}}>
                        {user.phone && <SubTitulo titulo={user.phone}/>}
                      </div>
                      <div style={{width: '20%'}}>
                        {user.is_superuser && <SubTitulo titulo="Administrador"/>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }) : (
          <h1 style={{
            textAlign: 'center',
            fontSize: '21px',
            color: '#777777'
          }}
          >
            Empresa não possui usuarios
          </h1>
        )}
      </>
    )
  }
}

export default UsersTable;

const SubTitulo = ({titulo}) => {
  return (
    <span style={{
      border: '1px solid #268ccf',
      color: '#268ccf',
      fontSize: '11px',
      padding: '5px 10px',
      borderRadius: '5px'
    }}>{titulo}</span>
  )
};
