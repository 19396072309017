import React, {Component} from 'react';
import {Form, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBriefcase} from "@fortawesome/free-solid-svg-icons/faBriefcase";

export class TabHistoricoCliente extends Component {

    render() {
        return (
            <Row style={{paddingLeft: '40px', paddingRight: '40px', marginTop: '10px'}}>

                <Table striped bordered condensed hover>
                    <thead>
                    <tr>
                        <th style={{fontSize: '18px', paddingLeft: '22px', height: '30px'}}><FontAwesomeIcon
                            icon={faBriefcase}/>&nbsp;HISTÓRICO DE AÇÕES
                        </th>
                        <th style={{textAlign: 'center'}}>EMPRESA</th>
                        <th style={{textAlign: 'center'}}>MÓDULO</th>
                        <th style={{textAlign: 'center'}}>AÇÕES</th>
                        <th style={{textAlign: 'center'}}>DATA</th>
                        <th style={{textAlign: 'center'}}>HORA</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style={{textAlign: 'center'}}>
                        <td style={{textAlign: 'left', paddingLeft: '22px'}}>usuario</td>
                        <td>Empresa 1</td>
                        <td>Módulo</td>
                        <td><Form.Label variant="warning">ALTEROU ITEM</Form.Label></td>
                        <td>12/10/2018</td>
                        <td>10:11:45</td>
                    </tr>
                    <tr style={{textAlign: 'center'}}>
                        <td style={{textAlign: 'left', paddingLeft: '22px'}}>usuario</td>
                        <td>Empresa 1</td>
                        <td>Módulo</td>
                        <td><Form.Label variant="warning">ALTEROU ITEM</Form.Label></td>
                        <td>12/10/2018</td>
                        <td>10:11:45</td>
                    </tr>
                    <tr style={{textAlign: 'center'}}>
                        <td style={{textAlign: 'left', paddingLeft: '22px'}}>usuario</td>
                        <td>Empresa 1</td>
                        <td>Módulo</td>
                        <td><Form.Label variant="warning">ALTEROU ITEM</Form.Label></td>
                        <td>12/10/2018</td>
                        <td>10:11:45</td>
                    </tr>
                    <tr style={{textAlign: 'center'}}>
                        <td style={{textAlign: 'left', paddingLeft: '22px'}}>usuario</td>
                        <td>Empresa 1</td>
                        <td>Módulo</td>
                        <td><Form.Label variant="warning">ALTEROU ITEM</Form.Label></td>
                        <td>12/10/2018</td>
                        <td>10:11:45</td>
                    </tr>
                    <tr style={{textAlign: 'center'}}>
                        <td style={{textAlign: 'left', paddingLeft: '22px'}}>usuario</td>
                        <td>Empresa 1</td>
                        <td>Módulo</td>
                        <td><Form.Label variant="warning">ALTEROU ITEM</Form.Label></td>
                        <td>12/10/2018</td>
                        <td>10:11:45</td>
                    </tr>
                    </tbody>
                </Table>
            </Row>
        );
    }
}

