import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";
import { faDiceD6 } from "@fortawesome/free-solid-svg-icons/index.es";
import { Tabs, Tab, Col } from "react-bootstrap";
import TabProdConf from "./TabProdConf";
import TabFeaturesConf from "./TabFeaturesConf";
import ModalCadFeature from "../../ModalConfiguracoes/ModalCadFeature";
import ModalCadProduto from "../../ModalConfiguracoes/ModalCadProduto";

const produtos = (
  <span>
    <FontAwesomeIcon icon={faDiceD6} />
    &nbsp;Produtos
  </span>
);
const features = (
  <span>
    <FontAwesomeIcon icon={"bookmark"} />
    &nbsp;Features
  </span>
);

export const NavTabsProdConf = () => {
  return (
    <Col md={12} style={{ marginTop: "1.5%" }}>
      <Tabs defaultActiveKey="produtos" id="uncontrolled-tab-example">
        <Tab eventKey="produtos" title={produtos}>
          <div
            style={{
              textAlign: "right",
              marginTop: "-2.5%",
              marginBottom: "2%",
            }}
          >
            <ModalCadProduto />
          </div>
          <TabProdConf />
        </Tab>
        <Tab eventKey={"features"} title={features}>
          <div
            style={{
              textAlign: "right",
              marginTop: "-2.5%",
              marginBottom: "2%",
            }}
          >
            <ModalCadFeature />
          </div>
          <TabFeaturesConf />
        </Tab>
      </Tabs>
    </Col>
  );
};
