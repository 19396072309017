import  {observable, action} from 'mobx'

class ModalStores{
    @observable status = false;

    @action toogleModal = () => {
        this.status = !this.status
    };

    @action clouseModal = () => {
        this.status = false
    };

    @action openModal = () => {
        this.status = true
    };

}

const modal = new ModalStores();
export default modal;