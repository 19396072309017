import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyB8MAiWUCbafRqBWL3S2UGFy1Fi6_Y4VR4",
    authDomain: "wavecode-a0556.firebaseapp.com",
    databaseURL: "https://wavecode-a0556.firebaseio.com",
    projectId: "wavecode-a0556",
    storageBucket: "wavecode-a0556.appspot.com",
    messagingSenderId: "927102072278"
};

export const firebaseImpl = firebase.initializeApp(config, "appGraphic");
export const firebaseDatabase = firebase.database(firebaseImpl);