import React from "react";
import { ContainerNavInfoContrato } from "../Nav/styleNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalCadEmpresaContratos from "../Empresas/Modal/ModalCadEmpresaContratos";

export const HeaderContrato = ({ razao_social, cnpj }) => {
  return (
    <ContainerNavInfoContrato>
      <div style={{ marginBottom: "30px" }}>
        <h4>
          <FontAwesomeIcon icon={"briefcase"} />
          &nbsp; {razao_social}
        </h4>
        <h6>CNPJ: &nbsp; {cnpj}</h6>
      </div>

      <div
        style={{
          marginBottom: "30px",
          maxWidth: "500px",
          width: "30vw",
          marginRight: "22px",
        }}
      ></div>

      <div style={{ marginBottom: "30px" }}>
        <ModalCadEmpresaContratos />
      </div>
    </ContainerNavInfoContrato>
  );
};
