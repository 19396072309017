import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faRobot } from "@fortawesome/free-solid-svg-icons";
import {
  BtnNav,
  logo,
  LogoEmpresa,
  NavBar,
  NavBarLogo,
  WrapperNav,
  Button,
} from "./styleNav";
import Logout from "../Home/Logout";

const activeBtn = (title, dados) => {
  if (title === dados) {
    return true;
  }
};

export const HomeNavBar = ({ title }) => {
  return (
    <NavBar>
      <WrapperNav>
        <NavBarLogo>
          <LogoEmpresa>
            {" "}
            <img src={logo} alt="wavecode" />{" "}
          </LogoEmpresa>
          <strong>
            Wave<span>ADMIN</span>
          </strong>
        </NavBarLogo>
        <BtnNav>
          <Link className='nav-options' to="/dashboard">
            <Button
              size="sm"
              active={activeBtn(title, "/dashboard")}
              variant="outline-warning"
            >
              <FontAwesomeIcon icon="tachometer-alt" />
              &nbsp;Dashboard
            </Button>
          </Link>
          <Link className='nav-options' to="/clientes">
            <Button
              size="sm"
              active={activeBtn(title, "/clientes")}
              variant="outline-success"
            >
              <FontAwesomeIcon icon="users" />
              &nbsp;Clientes
            </Button>
          </Link>
          <Link className='nav-options' to="/empresas">
            <Button
              size="sm"
              active={activeBtn(title, "/empresas")}
              variant="outline-primary"
            >
              <FontAwesomeIcon icon="briefcase" />
              &nbsp;Empresas
            </Button>
          </Link>
          <Link className='nav-options' to="/produtos">
            <Button
              size="sm"
              active={activeBtn(title, "/produtos")}
              variant="outline-info"
            >
              <FontAwesomeIcon icon="database" />
              &nbsp;Produtos
            </Button>
          </Link>
          <Link className='nav-options' to="/configuracoes">
            <Button
              size="sm"
              active={activeBtn(title, "/configuracoes")}
              variant="outline-secondary"
            >
              <FontAwesomeIcon icon="cogs" />
              &nbsp;Configurações
            </Button>
          </Link>
          <Link className='nav-options' to="/graficos">
            <Button
              size="sm"
              active={activeBtn(title, "/graficos")}
              variant="outline-secondary"
            >
              <FontAwesomeIcon icon={faChartLine} />
              &nbsp;Gráficos
            </Button>
          </Link>          
          <Link className='nav-options' to="/wavebots">
            <Button
              size="sm"
              active={activeBtn(title, "/wavebots")}
              variant="outline-secondary"
            >
              <FontAwesomeIcon icon={faRobot} />
              &nbsp;Wavebots
            </Button>
          </Link>          
        </BtnNav>
      </WrapperNav>
      <Logout />
    </NavBar>
  );
};
