import React, { Component } from "react";
import InfoHeadEmpresa from "./InfoHeadEmpresa";
import { TabsEmpresa } from "./TableEmpresas/TabsEmpresa";

export class EmpresasIndex extends Component {
  render() {
    return (
      <div>
        <InfoHeadEmpresa />
        <TabsEmpresa />
      </div>
    );
  }
}
