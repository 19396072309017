import React from "react";
import { Tabs, Tab, Col } from "react-bootstrap";
import TabTipoPlanosConf from "./TabTipoPlanosConf";

import ModalCadPlano from "../../ModalConfiguracoes/ModalCadPlano";
import ModalCadTipoPlano from "../../ModalConfiguracoes/ModalCadTipoPlano";
import TabPlanosConf from "./TabPlanosConf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const planos = (
  <span>
    <FontAwesomeIcon icon={"boxes"} />
    &nbsp;Planos
  </span>
);
const tiposPlanos = (
  <span>
    <FontAwesomeIcon icon={"list-alt"} />
    &nbsp;Tipos de Planos
  </span>
);

export const NavTabsPlanosConf = () => {
  return (
    <Col md={12} style={{ marginTop: "1.5%" }}>
      <Tabs defaultActiveKey="1" id="uncontrolled-tab-example">
        <Tab eventKey={1} title={planos}>
          <div
            style={{
              textAlign: "right",
              marginTop: "-2.5%",
              marginBottom: "2%",
            }}
          >
            <ModalCadPlano />
          </div>
          <TabPlanosConf />
        </Tab>
        <Tab eventKey={2} title={tiposPlanos}>
          <div
            style={{
              textAlign: "right",
              marginTop: "-2.5%",
              marginBottom: "2%",
            }}
          >
            <ModalCadTipoPlano />
          </div>
          <TabTipoPlanosConf />
        </Tab>
      </Tabs>
    </Col>
  );
};
