import React, { Component } from "react";
import { Tabs, Tab, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TabelaDeContratos from "../Contratos/TabelaDeContratos";

const painelAdministrativo = (
  <span>
    <FontAwesomeIcon icon={"globe"} />
    &nbsp;Painel Administrativo
  </span>
);
// const historicoContratacao = <span><FontAwesomeIcon icon={"cart-arrow-down"}/>&nbsp;Histórico de Contratações</span>;
// const historicoAcoes = <span><FontAwesomeIcon icon={"certificate"}/>&nbsp;Histórico de Ações</span>;

export class TabsEmpresa extends Component {
  render() {
    return (
      <Col md={12} style={{ marginTop: "45px" }}>
        <Tabs defaultActiveKey="1" id="uncontrolled-tab-example">
          <Tab eventKey="1" title={painelAdministrativo}>
            <TabelaDeContratos />
          </Tab>
        </Tabs>
      </Col>
    );
  }
}
