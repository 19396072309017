import { firebaseDatabase } from "../utils/firebaseUtils";
import { dataAtual } from "./ControllerData";

export function HandleHora(nodePath, callback) {
  let query = firebaseDatabase
    .ref(nodePath)
    .orderByChild("data")
    .equalTo(dataAtual());
  query.once("value", (dataSnapshot) => {
    let items = [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ];
    dataSnapshot.forEach((childSnapshot) => {
      let item = childSnapshot.val();
      let horaAbreviada = parseInt(item.hora.substr(0, 2)) - 3;
      items[horaAbreviada - 1] =
        items[horaAbreviada - 1] + item.lictacaoAtualizada;
    });
    callback(items);
  });
  return query;
}
