import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Modal, Button } from 'antd';
import ReactLoading from 'react-loading';
import DataTable from "react-data-table-component";
import { columnsEmpresa, customStyles } from "./styleTabelaEmpresa";
import { HeaderEmpresa } from "./ModalEmpresas/HeaderEmpresa";
import {
  ContainerTabelaCliente,
  Container,
  PaginationOptions,
} from "../Clientes/StyleTabela";
import { AvisoEsclamacao } from "../ComponentsPadrao/MensagemArrayVazio";
import Pagination from "@material-ui/lab/Pagination";

const IndexEmpresas = inject(
  "ClienteEmpresaStores",
  "ClienteStores",
  "ContratosStores",
  "MaquinaDeEstadoStores"
)(
  observer(
    ({ ClienteEmpresaStores, ContratosStores, MaquinaDeEstadoStores }) => {
      const {
        Empresas,
        count,
        consultaEmpresas,
        text,
        setText,
        valueSearch,
        resultSearch,
        searchClients,
        countSearch,
        previus,
        next,
        searchCompanys,
        isModalVisible,
        handleOk,
        loadingModal,
        userInfo
      } = ClienteEmpresaStores;
      const { consultaContratoEmpresaID } = ContratosStores;
      const { redirect } = MaquinaDeEstadoStores;
      return (
        <EmpresasComStore
          consultaContratoEmpresaID={consultaContratoEmpresaID}
          redirect={redirect}
          Empresas={Empresas}
          count={count}
          consultaEmpresas={consultaEmpresas}
          text={text}
          setText={setText}
          valueSearch={valueSearch}
          resultSearch={resultSearch}
          searchClients={searchClients}
          countSearch={countSearch}
          previus={previus}
          next={next}
          searchCompanys={searchCompanys}
          isModalVisible={isModalVisible}
          handleOk={handleOk}
          loadingModal={loadingModal}
          userInfo={userInfo}
        />
      );
    }
  )
);

const EmpresasComStore = ({
  consultaContratoEmpresaID,
  redirect,
  Empresas,
  count,
  consultaEmpresas,
  text,
  setText,
  valueSearch,
  resultSearch,
  searchCompanys,
  countSearch,
  isModalVisible,
  handleOk,loadingModal,
  userInfo
}) => {
  const [page, setPage] = useState(1);
  const [pageUsers, setPageUsers] = useState(1);

  useEffect(() => {
    consultaEmpresas(0);
  }, []);

  const handleChangePageTable = (event, newPage) => {
    setPage(newPage);
    searchCompanys(text, newPage);
  };

  const onRedirect = ({ id }) => {
    consultaContratoEmpresaID(id);
    redirect("contratos");
  };

  const onClick = () => {
    searchCompanys(text, null);
  };

  const handleChangePage = (e, value) => {
    setPageUsers(value);
    consultaEmpresas(Number(value - 1));
  };

  return (
    <>
      <Modal
        title="Dados do Cliente"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
        footer={[
          <Button  key="submit" type="primary" onClick={handleOk}>
            Ok
          </Button>,
        ]}
      >
        {
          loadingModal
            ? <ReactLoading
              style={{margin: '10% 40%'}}
              type='spin'
              color="#000"
              height={'20%'}
              width={'20%'}
            />
            : <>
              <p style={{
                marginBottom: 0,
                background: '#17612547',
                padding: '10px'
              }}>
                <strong>Email:</strong> {userInfo.email}
              </p>
              <p style={{
                marginBottom: 0,
                padding: '10px'
              }}><strong>Telefone:</strong> {userInfo.phone}</p>
              <p style={{
                marginBottom: 0,
                background: '#17612547',
                padding: '10px'
              }}>
                <strong>
                  Nome completo:
                </strong>
                {" " + userInfo.first_name + ' ' + userInfo.last_name}
              </p>
              <p style={{
                padding: '10px'
              }}>
                <strong>Fornecedores:</strong>
                {userInfo.fornecedor && userInfo.fornecedor.map(item => (
                  <div style={{margin: "5px 20px", fontSize: "13px", borderBottom: '1px solid'}}>
                    <div><strong>CNPJ:</strong> {item.cnpj}</div>
                    <div><strong>Nome Fantasia:</strong> {item.nome_fantasia}</div>
                    <div><strong>Razao Social:</strong> {item.razao_social}</div>
                    <div><strong>Telefone:</strong> {item.phone}</div>
                  </div>
                ))}
              </p>
            </>
        }

      </Modal>
      <HeaderEmpresa
        text={text}
        onClick={(e) => onClick(e)}
        onChange={setText}
      />
      <Container>
        <ContainerTabelaCliente>
          <DataTable
            fixedHeader
            style={{ height: "96%", marginBottom: "0" }}
            columns={columnsEmpresa}
            customStyles={customStyles}
            allowOverflow={true}
            overflowY={true}
            noHeader
            data={valueSearch ? resultSearch : Empresas}
            pointerOnHover
            onRowClicked={(row) => onRedirect(row)}
            striped
            paginationTotalRows={count}
            highlightOnHover
            noDataComponent={
              <AvisoEsclamacao texto={"Empresa não localizada"} />
            }
          />
          <PaginationOptions>
            {valueSearch ? (
              <Pagination
                count={Math.ceil(countSearch / 50)}
                onChange={handleChangePageTable}
                page={page}
                variant="outlined"
                color="primary"
              />
            ) : (
              <Pagination
                count={Math.ceil(count / 50)}
                onChange={handleChangePage}
                page={pageUsers}
                variant="outlined"
                color="primary"
              />
            )}
          </PaginationOptions>
        </ContainerTabelaCliente>
      </Container>
    </>
  );
};

export default IndexEmpresas;
