import {Form} from "react-bootstrap";
import React from "react";

export function FormLabelModal(nameLabel) {
    return(
        <Form.Label  style={{fontSize: '15px', color: '#848a90'}}>{nameLabel}</Form.Label>
    )
}

export function FormLabelModalRadio(nameLabel, name) {
    return(
        <Form.Label  type="radio" name={name} style={{fontSize: '15px', color: '#848a90'}}>{nameLabel}</Form.Label>
    )
}
