import { takeLatest, all, put } from "redux-saga/effects";
import { format } from "date-fns";

const data = format(new Date(), "dd/MM/yyyy");

const statusColor = (payload) => {
  const status = {
    error: 0,
    sucesso: 0,
    warning: 0,
    edital: 0,
    itens: 0,
  };

  payload.forEach(({ error_code, registered_items, registered_notices }) => {
    if (error_code === 400) {
      status.error = status.error + 1;
    }
    if (registered_items === 0 && registered_notices === 0) {
      status.warning = status.warning + 1;
    } else {
      status.edital = status.edital + parseInt(registered_notices);
      status.itens = status.itens + parseInt(registered_items);
      status.sucesso = status.sucesso + 1;
    }
  });
  return status;
};

const initialState = {
  data: [],
  statusError: 0,
  statusSucesso: 0,
  statusWarning: 0,
  statusEdital: 0,
  statusItens: 0,
  dia: data,
  isSucesso: false,
};

const editalTypes = {
  getOne: "editalTypes.getOne",
  setData: "editalTypes.setData",
};

const editalActions = {
  getOne: (data) => ({
    type: editalTypes.getOne,
    payload: data,
  }),
  setData: (data) => ({
    type: editalTypes.setData,
    payload: data,
  }),
};

const editalReducer = (state = initialState, action) => {
  switch (action.type) {
    case editalTypes.getOne:
      return { ...state };

    case editalTypes.setData:
      return { ...state, dia: action.dia, data: [], isSucesso: false };

    case "editalTypes.setData_SUCCESS":
      return {
        ...state,
        dia: action.dia,
        statusSucesso: 0,
        statusError: 0,
        statusWarning: 0,
        statusEdital: 0,
        statusItens: 0,
      };

    case `editalTypes.getOne_SUCCESS`: {
      const { error, sucesso, warning, edital, itens } = action.status;
      return {
        ...state,
        data: [...state.data, ...action.payload],
        statusSucesso: state.statusSucesso + sucesso,
        statusError: state.statusError + error,
        statusWarning: state.statusWarning + warning,
        statusEdital: edital
          ? state.statusEdital + edital
          : state.statusEdital + 0,
        statusItens: itens ? state.statusItens + itens : state.statusItens + 0,
        isSucesso: true,
      };
    }
    case `editalTypes.getOne_FAILURE`:
      return { ...state };
    default:
      return state;
  }
};

function* editalSagasGetOne({ payload }) {
  try {
    yield put({
      type: `editalTypes.getOne_SUCCESS`,
      payload: payload,
      status: statusColor(payload),
    });
  } catch (err) {
    yield put({
      type: `editalTypes.getOne_FAILURE`,
      payload: "Login ou password invalidos",
    });
  }
}

function* editalSagasData({ payload }) {
  try {
    yield put({ type: `editalTypes.setData_SUCCESS`, dia: payload });
  } catch (err) {}
}

function* editalSagas() {
  return yield all([
    takeLatest(editalTypes.getOne, editalSagasGetOne),
    takeLatest(editalTypes.setData, editalSagasData),
  ]);
}

export { editalSagas, editalActions };
export default editalReducer;
