export const TOKEN_KEY = "token";
export const CNPJ = "cnpj";
export const ID_CLIENTE = "id_cliente";
export const NOME_CLIENTE = "nome_cliente";
export const GERENCIAL = "gerencia";
export const STATUS = "status";
export const ID_EMPRESA = "status";
export const NOME_EMPRESA = "status";

export const getProps = (props) => {
    return props
};



export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getSTATUS = () => localStorage.getItem(STATUS);

export const setToken = token => {localStorage.setItem(TOKEN_KEY, token)};
export const setIdCliente = id => {localStorage.setItem(ID_CLIENTE, id)};
export const setNomeCliente = nome => {localStorage.setItem(NOME_CLIENTE, nome)};
export const setGerencial = gerencial => {localStorage.setItem(GERENCIAL, gerencial)};
export const setSTATUS = status => {localStorage.setItem(STATUS, status)};

export const setIdEmpresa = id => {localStorage.setItem(ID_EMPRESA, id)};
export const setNomeEmpresa = name => {localStorage.setItem(NOME_EMPRESA, name)};
export const setCNPJ = cnpj => {localStorage.setItem(CNPJ, cnpj)};


// export const limparLocalStore = () => localStorage.clear(STATUS, TOKEN_KEY, CNPJ, ID_CLIENTE, NOME_CLIENTE, GERENCIAL);
// export const removerLocalStore = () => localStorage.clear(STATUS, TOKEN_KEY, CNPJ, ID_CLIENTE, NOME_CLIENTE, GERENCIAL);

