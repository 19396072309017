import styled from "styled-components";

export const logo = "/img/logo_wavecode.png";

export const NavBar = styled.nav`
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1;
  top: 0;
  background-color: #343a40;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  padding: 8px;
  overflow: hidden;
`;

export const Button = styled.div`
  background: ${(props) => (props.active ? "#17A2B8" : "#343A40")};
  color: #fff;
  padding: 5px;
  border: ${(props) => (props.active ? "1px solid #17A2B8" : "1px solid #FFF")};
  border-radius: 6px;
`;

export const NavBarLogo = styled.nav`
  display: flex;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.5rem;
  line-height: inherit;
  white-space: nowrap;
  color: white;
`;

export const LogoEmpresa = styled.span`
  img {
    width: 40px;
    margin-right: 10px;
  }
  span {
    color: silver;
  }
`;

export const BtnNav = styled.div`
  margin-left: 30px;
  display: flex;
  align-items: center;
  width: auto;
  justify-content: space-between;
  a:hover {
    text-decoration: none;
  }
  .nav-options {
    margin-right: 10px;
  }
`;

export const WrapperNav = styled.div`
  display: flex;
  width: 72%;
`;

export const ContainerNavInfo = styled.div`
  height: ${(props) => (props.height ? props.height : "170px")};
  color: #fff !important;
  background: linear-gradient(to right, #0d98ce, #39586d);
  margin-top: 62px;
  width: 100%;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  .form-group {
    display: flex;
  }

  input[type="text"] {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .input-group-text {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    cursor: pointer;
  }

  .close {
    z-index: 3;
    margin-top: 5px;
    color: #495057;
    margin-left: -18px;
    cursor: pointer;
    opacity: 1;
  }

  h4 {
    color: #fff;
  }
`;

export const ContainerNavInfoContrato = styled.div`
  height: 170px;
  color: white;
  background: linear-gradient(to right, #0d98ce, #39586d);
  margin-top: 62px;
  width: 100%;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;
