import React, {Component} from 'react';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";

class MoldaHeardDanger extends Component {
    render() {
        return (
            <div style={{
                borderTopLeftRadius: '5px', borderTopRightRadius: '5px',
                background: '#ee5666', color: 'white', height: '40px', border: '2px solid #ee5666',
                display: 'flex', padding: '9px', justifyContent: 'space-between', fontSize: '14px'
            }}>
                <div>
                    <>
                        <span>
                            <FontAwesomeIcon icon={this.props.icon}/>
                        </span>
                        &nbsp;&nbsp;{this.props.titulo}
                    </>
                </div>
                <div>
                    <Button style={{color: '#cf123a', marginTop: '-5px'}}
                            onClick={this.props.onClick} size="sm" variant="">
                        <span>X</span>
                    </Button>
                </div>
            </div>
        );
    }
}

export default MoldaHeardDanger;

