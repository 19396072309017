import axios from "axios";
import "../../Debug";

export const urle = "https://api.wavecode.com.br/api_admin/";

const Api = axios.create({
  baseURL: global.url + "api_admin/",
  contentType: "application/x-www-form-urlencoded",
  headers: { "X-My-Custom-Header": "Header-Value" },
});

Api.interceptors.response.use(
  function(res) {
    return res;
  },
  function(err) {
    if (err.response.status === 401 || err.response.status === 403) {
      window.location.pathname = "/";
    }
    return Promise.reject(err);
  }
);

export default Api;

export const defaultsHeadersAxios = (token) => {
  Api.defaults.headers.common["Authorization"] = `Token ${token}`;
};
