import { observable, action } from "mobx";
import { defaultsHeadersAxios } from "./Conexao/conexao";
import { AlertaSuccess } from "./Funcoes/FuncoesGerais";
import Api from "./Conexao/conexao";
// import axios from "axios";

class LoginStores {
  @observable email = "";
  @observable password = "";
  @observable status = false;
  @observable status_rota = localStorage.getItem("status");

  @action onChange = (evento) => {
    const campo = evento.target.name;
    this[campo] = evento.target.value;
  };

  @action logar = async (e, props) => {
    e.preventDefault();
    try {
      this.status = "1";
      let response = await Api.post("login/user", {
        username: this.email,
        password: this.password,
      });
      const {
        data: { token },
      } = response;
      defaultsHeadersAxios(token);
      localStorage.setItem("token", token);
      localStorage.setItem("status", true);
      this.status_rota = localStorage.getItem("status");
      AlertaSuccess("Login realizado com sucesso!");
      // ClienteEmpresaStores.consultaEmpresas();
      // ClienteStores.consultaClientes();
      props.history.push("/dashboard");
    } catch (error) {
      this.status = "2";
      this.errorMessage = "Ocorreu um problema, tente mais tarde...";
      this.isFetting = true;
      this.isSuccess = false;
    }
  };

  @action logout = () => {
    this.status = false;
    localStorage.setItem("status", false);
    localStorage.clear("status");
    localStorage.removeItem("status");
    this.email = "";
    this.password = "";
    this.status_rota = localStorage.getItem("status");
    window.location.pathname = '/';
  };
}
const loginStores = new LoginStores();
export default loginStores;
