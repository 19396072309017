import React from "react";
import { X } from "react-feather";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContainerNavInfo } from "../Nav/styleNav";
import ModalCadastroClienteCompleto from "./ModalCliente/ModalCadastroClienteCompleto";
import ModalCadCliente from "./ModalCliente/ModalCadCliente";

const HeaderClientes = ({ text, onClick, onChange, valueInSearch }) => {
  const onSubmitSearch = (e) => {
    e.preventDefault()
    onClick()
  }

  return (
    <ContainerNavInfo>
      <div style={{ marginBottom: "50px" }}>
        <h4>
          <FontAwesomeIcon icon={"users"} /> Clientes Cadastrados
        </h4>
      </div>

      <div
        style={{
          marginBottom: "40px",
          maxWidth: "500px",
          width: "30vw",
          marginRight: "22px",
        }}
      >
        <form onSubmit={onSubmitSearch}>
          <Form.Group controlId="formBasicEmail">
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="pesquisar cliente"
                onChange={(e) => onChange(e.target.value)}
                value={text}
              />
            </InputGroup>
            {valueInSearch && (
              <div className="close" onClick={() => onChange("")}>
                <X size="15" />
              </div>
            )}
            <InputGroup.Prepend onClick={onClick}>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={"search"} />
              </InputGroup.Text>
            </InputGroup.Prepend>
          </Form.Group>
        </form>
      </div>

      <div style={{ marginBottom: "50px" }}>
        <ModalCadastroClienteCompleto />
        <ModalCadCliente />
      </div>
    </ContainerNavInfo>
  );
};

export default HeaderClientes;
