import styled from "styled-components";


export const LoadingOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: .7;
`;


export const LoadingWrapper = styled.div`
    position: absolute;
    top: 45%;
    left: 45%;
    z-index: 1045;
    color: white;

    
`;

export const LoadingTexto = styled.div`
    font-size: 13px;
     margin-top: 20px;
     
  -webkit-animation: blink-2 2s infinite both;
  animation: blink-2 2s infinite both;
  @-webkit-keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}


`;


export const LoadingQuadrado = styled.div`

  width: 60px;
  height: 60px;
  background-color: #1492b8;

   margin: auto;
  -webkit-animation: sk-rotateplane 2s infinite ease-in-out;
  animation: sk-rotateplane 2s infinite ease-in-out;
  
  @-webkit-keyframes sk-rotateplane {
  0% { -webkit-transform: perspective(120px) }
  50% { -webkit-transform: perspective(120px) rotateY(180deg) }
  100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes sk-rotateplane {
  0% { 
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
  } 50% { 
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg) 
  } 100% { 
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
`;