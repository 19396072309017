import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  height: 90px;  
  .wrapper{
    width: 47%;
    display: flex;
  }
  margin-top: 20px;
`;

export const WrapperLogo = styled.div`
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 25px;
  font-family: Roboto,serif;
  text-shadow: black;
  margin-right: 30px;

  img{
    width: 40px;
  }
  div{
    margin-left: 10px;
  }
  span{
    font-size: 25px;
    color: silver;
  }
  
  @media (max-width: 1200px) {
       flex-direction: column;
       font-size: 14px;
       div{
       margin: 0;
       }
       span{
        font-size: 14px;
       }
  }
  
`;
