import React, { Component } from "react";
import {
  Button,
  Col,
  Row,
  Form,
  InputGroup,
  ButtonGroup,
} from "react-bootstrap";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";
import { BottomModal } from "../../Modal/BottomModal";
import ModalHeard from "../../Modal/ModalHeard";
import { inject, observer } from "mobx-react";
import InputMask from "react-input-mask";
import { FormLabelModal } from "../../Modal/LabelModal";

@inject(
  "PlanosStores",
  "ProdutosStores",
  "TiposPlanosStores",
  "FeaturesStores",
  "PortaisStores"
)
@observer
class ModalCadPlano extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      feature_fk: [],
    };
  }

  handleCloseModal = () => {
    this.setState({ isActive: false });
  };
  handleOpenModal = () => {
    this.props.PlanosStores.ClearInputs();
    this.setState({ isActive: true });
  };

  handleAddPlanoCloseModal = (evento) => {
    evento.preventDefault();
    if (this.props.PlanosStores.ChecksInputs()) {
      this.props.PlanosStores.addPlano();
      this.handleCloseModal();
    } else {
    }
  };

//   componentDidMount() {
//     this.props.ProdutosStores.consultaProduto();
//   }

  render() {
    return (
      <span>
        <ButtonGroup aria-label="Basic example">
          <Button size="sm" disabled variant="outline-primary">
            &nbsp;+
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={() => this.handleOpenModal()}
          >
            Planos
          </Button>
        </ButtonGroup>

        <Modal
          className="Modal_Load"
          style={{
            content: {
              width: "700px",
              height: "740px",
              left: "47%",
              top: "38%",
            },
          }}
          overlayClassName="Overlay_Load"
          isOpen={this.state.isActive}
        >
          <ModalHeard
            icon={"project-diagram"}
            titulo={"Cadastrar Planos"}
            onClick={() => this.handleCloseModal()}
          />
          <br />
          <div>
            <Col md={12} style={{ marginTop: "1%" }}>
              <Form>
                <Form.Group>
                  {FormLabelModal("nome plano")}
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon="boxes" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      name="name"
                      type="text"
                      placeholder="..."
                      aria-describedby="inputGroupPrepend"
                      onChange={(e) => this.props.PlanosStores.onChange(e)}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  {FormLabelModal("descrição")}
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon="align-center" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      as="textarea"
                      rows="2"
                      name="description"
                      type="text"
                      placeholder="..."
                      aria-describedby="inputGroupPrepend"
                      onChange={(e) => this.props.PlanosStores.onChange(e)}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  {FormLabelModal("limite")}
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon="circle-notch" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      name="limit"
                      type="number"
                      placeholder="..."
                      aria-describedby="inputGroupPrepend"
                      onChange={(e) => this.props.PlanosStores.onChange(e)}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  {FormLabelModal("valor")}
                  <Row style={{ marginLeft: "0.4%" }}>
                    <div>
                      <InputGroup.Text
                        style={{ height: "41px" }}
                        id="inputGroupPrepend"
                      >
                        R$
                      </InputGroup.Text>
                    </div>
                    <div style={{ width: "91%" }}>
                      <InputMask
                        name={"value"}
                        style={{
                          marginLeft: "-0.4%",
                          width: "100%",
                          border: "1.2px",
                          borderRadius: "4px",
                          height: "41px",
                          borderColor: "#ced4da",
                          borderStyle: "solid",
                        }}
                        mask="  9999.00"
                        maskChar={null}
                        placeholder="  Valor..."
                        onChange={(e) => this.props.PlanosStores.onChange(e)}
                      />
                    </div>
                  </Row>
                </Form.Group>

                <Form.Row>
                  <Col>
                    <Form.Group>
                      {FormLabelModal("produto")}
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroupPrepend">
                            <FontAwesomeIcon icon="dice-d6" />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          as="select"
                          name="product_fk"
                          onChange={(e) => this.props.PlanosStores.onChange(e)}
                        >
                          <option disabled={false} value={null}>
                            Selecione um produto
                          </option>
                          {this.props.ProdutosStores.produtos.map(
                            (produtos, i) => {
                              return (
                                <option key={i} value={produtos.id}>
                                  {produtos.name}
                                </option>
                              );
                            }
                          )}
                        </Form.Control>
                      </InputGroup>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group>
                      {FormLabelModal("tipo plano")}
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroupPrepend">
                            <FontAwesomeIcon icon="list-alt" />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          as="select"
                          name={"type_plan_fk"}
                          onChange={(e) => this.props.PlanosStores.onChange(e)}
                        >
                          <option disabled={false} value={null}>
                            Selecione um tipo de plano
                          </option>
                          {this.props.TiposPlanosStores.tipo_plano.map(
                            (tipo_plano, i) => {
                              return (
                                <option key={i} value={tipo_plano.id}>
                                  {tipo_plano.name}
                                </option>
                              );
                            }
                          )}
                        </Form.Control>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Group>
                      {FormLabelModal("feature")}
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroupPrepend">
                            <FontAwesomeIcon icon="list-alt" />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          as="select"
                          multiple
                          id={"feature_fk"}
                          onChange={(e) => this.props.PlanosStores.onChange(e)}
                        >
                          <option disabled={true}>Selecione uma feaure</option>
                          {this.props.PlanosStores.feature_fk_defalut.map(
                            (features, i) => {
                              return (
                                <option key={i} value={features.id}>
                                  {features.name}
                                </option>
                              );
                            }
                          )}
                        </Form.Control>
                      </InputGroup>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group>
                      {FormLabelModal("portal")}
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroupPrepend">
                            <FontAwesomeIcon icon="door-open" />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          as="select"
                          multiple
                          id={"portal_fk"}
                          onChange={(e) => this.props.PlanosStores.onChange(e)}
                        >
                          <option disabled={true}>Selecione um portal</option>
                          {this.props.PortaisStores.portal.map((portal, i) => {
                            return (
                              <option key={i} value={portal.id}>
                                {portal.nome}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Form.Row>

                <div style={{ textAlign: "right" }}>
                  <Button
                    variant="outline-success"
                    size={"sm"}
                    onClick={(evento) => this.handleAddPlanoCloseModal(evento)}
                  >
                    Cadastrar
                  </Button>
                  <Button
                    variant="outline-danger"
                    size={"sm"}
                    onClick={() => this.handleCloseModal()}
                    style={{ marginLeft: "10px" }}
                  >
                    Fechar
                  </Button>
                </div>
              </Form>
            </Col>
          </div>
          {BottomModal}
        </Modal>
      </span>
    );
  }
}

export default ModalCadPlano;
