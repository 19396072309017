import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Badge, Row, Table } from "react-bootstrap";
import ModalExcluirBase from "../../../Modal/ModalExcluirBase";
import Pagination from "@material-ui/lab/Pagination";
import ModalEditarPlano from "../../ModalConfiguracoes/ModalEditarPlano";

const styleTabela = { verticalAlign: "middle", textAlign: "center" };

const TabPortaisConf = inject("PlanosStores", "FeaturesStores")(
  observer(({ PlanosStores, FeaturesStores }) => {
    const {
      consultaPlanoNovo,
      combos,
      delPlano,
      combo_total,
    } = PlanosStores;
    const {seeAllFeatures} = FeaturesStores
    return (
      <TabPortaisConfStore
        combos={combos}
        consultaPlanoNovo={consultaPlanoNovo}
        delPlano={delPlano}
        combo_total={combo_total}
        seeAllFeatures={seeAllFeatures}
      />
    );
  })
);

const TabPortaisConfStore = ({
  delPlano,
  consultaPlanoNovo,
  combos,
  combo_total,
  seeAllFeatures,
}) => {
  
  useEffect(() => {
    consultaPlanoNovo(1);
    seeAllFeatures()
  }, []);

  const onChangePage = (e, value) => {
    console.log(value)
    consultaPlanoNovo(value);
  };

  return (
    <>
      <Row
        id="top"
        style={{
          paddingLeft: "0.8%",
          paddingRight: "0.8%",
          marginTop: "1%",
          marginBottom: "5%",
          fontSize: "15px",
        }}
      >
        <Table striped bordered condensed hover>
          <thead style={styleTabela}>
            <tr>
              <th style={{ width: "4%", textAlign: "center" }}>#</th>
              <th style={{ width: "10%" }}>NOME PLANO</th>
              <th style={{ width: "20%" }}>DESCRIÇÃO</th>
              <th style={{ width: "8%" }}>LIMITE</th>
              <th style={{ width: "8%", textAlign: "center" }}>VALOR</th>
              <th style={{ width: "8%", textAlign: "center" }}>PRODUTO</th>
              <th style={{ width: "8%", textAlign: "center" }}>FEATURES</th>
              <th style={{ width: "10%", textAlign: "center" }}>PORTAL</th>
              <th style={{ width: "10%", textAlign: "center" }}>TIPO</th>
              <th style={{ width: "8%", textAlign: "center" }}>AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {combos.map((plano, i) => {
              return (
                <tr key={i}>
                  <td style={styleTabela}>{plano.id}</td>
                  <td style={styleTabela}>{plano.name}</td>
                  <td style={styleTabela}>{plano.description}</td>
                  <td style={styleTabela}>{plano.limit}</td>
                  <td style={styleTabela}> R$ {plano.value}</td>
                  <td style={styleTabela}>
                    {" "}
                    <Badge variant="warning">{plano.product_fk.name}</Badge>
                  </td>
                  <td style={styleTabela}>
                    {plano.feature_fk.length === 0
                      ? null
                      : plano.feature_fk.map((feature, i) => {
                          return (
                            <span key={i}>
                              <Badge variant="info">{feature.name}</Badge>
                              <br />
                            </span>
                          );
                        })}
                  </td>

                  <td style={styleTabela}>
                    {plano.portal_fk.map((portal) => {
                      return (
                        <span key={portal.id}>
                          <Badge variant="primary">{portal.nome}</Badge>
                          <br />
                        </span>
                      );
                    })}
                  </td>
                  <td style={styleTabela}>
                    {" "}
                    <Badge variant="success">{plano.type_plan_fk.name}</Badge>
                  </td>

                  <td style={styleTabela}>
                    <ModalEditarPlano plano={plano} />
                    &nbsp;
                    <ModalExcluirBase
                      nome={plano.name}
                      onClick={() => delPlano(plano.id, plano.name)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Pagination
          count={Math.ceil(combo_total / 50)}
          onChange={onChangePage}
          variant="outlined"
          color="primary"
        />
      </Row>
    </>
  );
};

export default TabPortaisConf;

// class TabPortaisConfStore extends Component {
//   componentDidMount() {
//     console.log("componentDidMount");
//     this.props.PlanosStores.consultaPlanoNovo();
//   }

//   render() {
//     return (
//       <Row
//         style={{
//           paddingLeft: "0.8%",
//           paddingRight: "0.8%",
//           marginTop: "1%",
//           marginBottom: "5%",
//           fontSize: "15px",
//         }}
//       >
//         <Table striped bordered condensed hover>
//           <thead style={styleTabela}>
//             <tr>
//               <th style={{ width: "4%", textAlign: "center" }}>#</th>
//               <th style={{ width: "10%" }}>NOME PLANO</th>
//               <th style={{ width: "20%" }}>DESCRIÇÃO</th>
//               <th style={{ width: "8%" }}>LIMITE</th>
//               <th style={{ width: "8%", textAlign: "center" }}>VALOR</th>
//               <th style={{ width: "8%", textAlign: "center" }}>PRODUTO</th>
//               <th style={{ width: "8%", textAlign: "center" }}>FEATURES</th>
//               <th style={{ width: "10%", textAlign: "center" }}>PORTAL</th>
//               <th style={{ width: "10%", textAlign: "center" }}>TIPO</th>
//               <th style={{ width: "8%", textAlign: "center" }}>AÇÃO</th>
//             </tr>
//           </thead>
//           <tbody>
//             {this.props.PlanosStores.combos &&
//               this.props.PlanosStores.combos.map((plano, i) => {
//                 return (
//                   <tr key={i}>
//                     <td style={styleTabela}>{plano.id}</td>
//                     <td style={styleTabela}>{plano.name}</td>
//                     <td style={styleTabela}>{plano.description}</td>
//                     <td style={styleTabela}>{plano.limit}</td>
//                     <td style={styleTabela}> R$ {plano.value}</td>
//                     <td style={styleTabela}>
//                       {" "}
//                       <Badge variant="warning">{plano.product_fk.name}</Badge>
//                     </td>
//                     <td style={styleTabela}>
//                       {plano.feature_fk.length === 0
//                         ? null
//                         : plano.feature_fk.map((feature, i) => {
//                             return (
//                               <span key={i}>
//                                 <Badge variant="info">{feature.name}</Badge>
//                                 <br />
//                               </span>
//                             );
//                           })}
//                     </td>

//                     <td style={styleTabela}>
//                       {plano.portal_fk.map((portal) => {
//                         return (
//                           <span key={portal.id}>
//                             <Badge variant="primary">{portal.nome}</Badge>
//                             <br />
//                           </span>
//                         );
//                       })}
//                     </td>
//                     <td style={styleTabela}>
//                       {" "}
//                       <Badge variant="success">{plano.type_plan_fk.name}</Badge>
//                     </td>

//                     <td style={styleTabela}>
//                       <ModalEditarPlano plano={plano} />
//                       &nbsp;
//                       <ModalExcluirBase
//                         nome={plano.name}
//                         onClick={() =>
//                           this.props.PlanosStores.delPlano(plano.id, plano.name)
//                         }
//                       />
//                     </td>
//                   </tr>
//                 );
//               })}
//           </tbody>
//         </Table>
//         <Pagination
//           count={this.props.PlanosStores.combo_total}
//           onChange={this.props.PlanosStores.onPageChanged}
//           variant="outlined"
//           color="primary"
//         />
//       </Row>
//     );
//   }
// }
