import React from "react";
import {Clock} from "react-feather";

export const columnsDashboard = [
    {
        name: 'RAZÃO SOCIAL',
        selector: 'razao_social',
        sortable: true,
    },
    {
        name: 'HORA DIA',
        selector: 'hora_dia',
        sortable: true,
        right: 'center',
        cell: row => <div>
            <div style={{  background: '#00a200', color: 'white', padding: '2px 5px', borderRadius: '7px', textAlign: 'center'}}><span style={{marginRight: '5px'}}><Clock size={'15px'}/></span>{row.hora_dia}</div></div>,
    },

];


export const columnsDashboardLogin = [
    {
        name: 'RAZÃO SOCIAL',
        selector: 'razao_social',
        sortable: true,
        width: '300px',
        grow: 1

    },
    {
        name: 'CNPJ',
        selector: 'cnpj',
        sortable: true,
        width: '200px',
        grow: 1

    },
    {
        name: 'LOGIN',
        selector: 'login',
        sortable: true,
        width: '300px',
        grow: 1

    },
    {
        name: 'DATA',
        selector: 'data_hora',
        sortable: true,
        width: '200px',
        cell: row => <div>
            <div style={{  background: '#268ccf', color: 'white', padding: '2px 5px', borderRadius: '7px'}}><span style={{marginRight: '5px'}}><Clock size={'15px'}/></span>{row.data_hora}</div></div>,


    },
];


export const customStyles = {
    headRow: {
        style: {
            fontSize: '12px',
            borderBottom: '1.2px solid #a5a5a5',
            overflow: 'hidden',
        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontWeight: 'bolder',
            overflow: 'hidden',
        },
    },

    cell:{
        style: {
            borderBottom: '1.2px solid #a5a5a5',
            overflow: 'hidden',
        },
    },

    rows: {
        style: {
            minWidth: '72px', // override the row height
            overflow: 'hidden',
        },

        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '10px',
            outline: '1px solid #FFFFFF',
            overflow: 'hidden',
        },
    },
};

