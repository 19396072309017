export function dadosMinutoComprasNet() {
  return ({
    key: 0,
    labels: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44,
      46, 48, 50],
    datasets: [
      dadosOrange('Processada'),
      dadosBlul('Monitorada'),
      dadosGreen('Tempo'),]
  })
}

export function dadosMinutoMsgComprasNet() {
  return ({

    key: 0,
    labels: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44,
      46, 48, 50, 52, 54, 56, 58],
    datasets: [
      dadosGreenData('Mensagem Adicionada'),]
  })
}

export function dadosMinutoLicitacoesE() {
  return ({

    key: 0,
    labels: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
    datasets: [
      dadosOrange('Processada'),
      dadosBlul('Monitorada'),
      dadosGreen('Tempo'),]
  })
}


export function dadosMinutoMsgLicitacoesE() {
  return ({

    key: 0,
    labels: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
    datasets: [
      dadosGreen('Mensagem Adicionada'),]
  })
}

export function dadosHora() {
  return ({

    key: 0,
    labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
    datasets: [
      dadosOrange('Atualizadas'),
    ]
  })
}

function dadosGreenData(label) {
  return (
    {
      label: label,
      fill: false,
      lineTension: 0.3,
      backgroundColor: "rgba(25,99,71,0.2)",
      borderColor: "rgba(20,100,90,1)",
      pointRadius: 5,
      pointBackgroundColor: "rgba(20,100,90,1)",
      pointBorderColor: "rgba(255,255,255,0.8)",
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(20,68,0,1)",
      pointHitRadius: 20,
      pointBorderWidth: 2,
      data: []
    })
}


function dadosOrange(label) {
  return (
    {
      label: label,
      lineTension: 0.3,
      pointRadius: 5,
      pointHoverRadius: 5,
      pointHitRadius: 20,
      pointBorderWidth: 2,
      backgroundColor: "rgba(255,99,71,0.2)",
      borderColor: "rgba(255,127,80,1)",
      pointBackgroundColor: "rgba(139,0,0,1)",
      pointBorderColor: "rgba(255,255,255,0.8)",
      pointHoverBackgroundColor: "rgba(128,0,0,1)",
      data: []
    })
}

function dadosBlul(label) {
  return (
    {
      label: label,
      lineTension: 0.3,
      backgroundColor: "rgba(2,117,216,0.2)",
      borderColor: "rgba(2,117,216,1)",
      pointRadius: 5,
      pointBackgroundColor: "rgba(2,117,216,1)",
      pointBorderColor: "rgba(255,255,255,0.8)",
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(2,117,216,1)",
      pointHitRadius: 20,
      pointBorderWidth: 2,
      data: []
    })
}

function dadosGreen(label) {
  return (
    {
      label: label,
      lineTension: 0.3,
      backgroundColor: "rgba(25,99,71,0.2)",
      borderColor: "rgba(20,100,90,1)",
      pointRadius: 5,
      pointBackgroundColor: "rgba(20,100,90,1)",
      pointBorderColor: "rgba(255,255,255,0.8)",
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(20,68,0,1)",
      pointHitRadius: 20,
      pointBorderWidth: 2,
      data: []
    })
}

export function optionsGragicos(max, limit) {
  return (
    {
      scales: {
        xAxes: [{
          time: {unit: 'date'},
          gridLines: {display: false},
          ticks: {maxTicksLimit: 50}
        }],
        yAxes: [
          {
            ticks: {min: 0, max: max, maxTicksLimit: limit},
            gridLines: {color: "rgba(0, 0, 0, .125)",}
          }],
      },
      legend: {
        display: true,
        position: 'bottom',
      }
    }
  )
}
