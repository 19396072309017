import React  from 'react';


import {Tabs, Tab, Col} from "react-bootstrap";
import TabEmpresasCliente from "./TabEmpresasCliente";
import {TabHistoricoCliente} from "./TabHistoricoCliente";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ModalCadEmpresaHome from "../../Empresa/ModalEmpresas/ModalCadEmpresaHome";


const empresas= <span><FontAwesomeIcon icon={"briefcase"}/>&nbsp;Empresas</span>;
const historico = <span><FontAwesomeIcon icon={"clock"}/>&nbsp;Histórico de Ações</span>;

    export const  NavTabsCliente = () => {
        return(
            <Col md={12} style={{marginTop: '45px', }}>
                <Tabs defaultActiveKey="empresas" id="uncontrolled-tab-example">
                    <Tab eventKey="empresas" title={empresas}>
                        <div style={{textAlign:'right', marginTop: '-45px', marginBottom: '30px', marginRight: '30px'}}>
                            <ModalCadEmpresaHome/></div>
                        <TabEmpresasCliente/>
                    </Tab>
                    <Tab eventKey="historico" title={historico}>
                        <TabHistoricoCliente/>
                    </Tab>
                </Tabs>
            </Col>
        )
    };






