import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {inject, observer} from "mobx-react";

const grad2 = {
    height: '130px',
    color: 'white',
    background: 'linear-gradient(141deg, #2cb5e8 0%, #1fc8db 51%, #0fb8ad 75%)',
    marginTop: '3.6%',
    marginLeft: '-0.8%',
    width: '101.6%'
};

@inject('MaquinaDeEstadoStores')
@observer

class InfoHeadCliente extends Component {
    render() {
        const {first_name} = this.props.MaquinaDeEstadoStores.user;
        return (
            <Container fluid style={grad2}>
                <Row>
                    <Col style={{marginTop: '1.8%', marginLeft: '1%'}} sm={6}>Cliente <h3><FontAwesomeIcon
                        icon='users'/> {first_name}</h3></Col>
                </Row>
            </Container>
        );
    }
}


export default InfoHeadCliente;
