import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";
import {
  faBoxes,
  faUser,
  faDoorOpen,
  faDiceD6,
} from "@fortawesome/free-solid-svg-icons/index.es";
import { Tabs, Tab, Col } from "react-bootstrap";
import TabPortaisConf from "./TabPortaisConf";
import TabVendedoresConf from "./TabVendedoresConf";
import { NavTabsProdConf } from "./TbsProdutos/NavTabsProdConf";
import { NavTabsPlanosConf } from "./TbsPlanos/NavTabsPlanos";
import ModalCadPortal from "../ModalConfiguracoes/ModalCadPortal";
import ModalCadVendedor from "../ModalConfiguracoes/ModalCadVendedor";

const portais = (
  <span>
    <FontAwesomeIcon icon={faDoorOpen} />
    &nbsp;Portais
  </span>
);
const produtos = (
  <span>
    <FontAwesomeIcon icon={faDiceD6} />
    &nbsp;Produtos
  </span>
);
const planos = (
  <span>
    <FontAwesomeIcon icon={faBoxes} />
    &nbsp;Planos
  </span>
);
const vendedores = (
  <span>
    <FontAwesomeIcon icon={faUser} />
    &nbsp;Vendedores
  </span>
);

export const NavTabsConf = () => {
  return (
    <Col md={12} style={{ marginTop: "1.5%" }}>
      <Tabs defaultActiveKey="portais" id="uncontrolled-tab-example">
        <Tab eventKey="portais" title={portais}>
          <div
            style={{
              textAlign: "right",
              marginTop: "-2.2%",
              marginBottom: "2%",
            }}
          >
            <ModalCadPortal />
          </div>
          <TabPortaisConf />
        </Tab>
        <Tab eventKey="produtos" title={produtos}>
          <NavTabsProdConf />
        </Tab>
        <Tab eventKey={"planos"} title={planos}>
          <NavTabsPlanosConf />
        </Tab>
        <Tab eventKey="venderoes" title={vendedores}>
          <div
            style={{
              textAlign: "right",
              marginTop: "-2.2%",
              marginBottom: "2%",
            }}
          >
            <ModalCadVendedor />
          </div>
          <TabVendedoresConf />
        </Tab>
      </Tabs>
    </Col>
  );
};
