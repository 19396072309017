import React, { useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import "date-fns";
import { Search } from "react-feather";
import { ContainerSearch } from "./styles";
import { format } from "date-fns";

const SearchNav = ({ upadteData, dia }) => {
  const dados = format(new Date(), "yyyy-MM-dd");
  const [data, setData] = useState(dados);

  const handelData = (data) => {
    let dia = data.split("-")[2];
    let mes = data.split("-")[1];
    let ano = data.split("-")[0];
    const dados = dia + "/" + mes + "/" + ano;
    upadteData(dados);
  };

  return (
    <ContainerSearch>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text
            style={{ cursor: "pointer" }}
            onClick={(e) => handelData(data)}
          >
            <Search />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          type={"date"}
          id="inlineFormInputGroup"
          placeholder="Username"
          value={data}
          onChange={(e) => setData(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") handelData(e.target.value);
          }}
        />
      </InputGroup>
    </ContainerSearch>
  );
};
export default SearchNav;
