import React, {Component} from 'react';
import {Badge, Col, Row, Table} from "react-bootstrap";
import {inject, observer} from 'mobx-react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ModalExcluirBase from "../../Modal/ModalExcluirBase";
import {Link} from "react-router-dom";
import ModalEditarEmpresaHome from "../../Empresa/ModalEmpresas/ModalEditarEmpresaHome";


@inject('ClienteStores', 'ClienteEmpresaStores', 'MaquinaDeEstadoStores')
@observer
class TabEmpresasCliente extends Component {
    constructor(props) {
        super(props);
        this.Redirect = this.Redirect.bind(this);
    }

    componentDidMount = () => {
        const {id} = this.props.MaquinaDeEstadoStores.user;
        this.props.ClienteStores.consultaClienteId(id)
    };

    Redirect = (empresa) => {
        this.props.MaquinaDeEstadoStores.setEmpresa(empresa)
    };

    handleData = (data) => {
        return new Intl.DateTimeFormat('pt-BR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour12: false
        }).format(new Date(data))
    };

    checarDatas = (data_contrato) => {
        let partesData = data_contrato.split("/");
        let data = new Date(partesData[2], partesData[1] - 1, partesData[0]);
        return data >= new Date();
    };

    data = ((contract) => {
        let dataAtual = new Date();
        let mydate = new Date(contract.ends_in);
        if (mydate > dataAtual) {
            return <Badge pill variant="success"> LIBERADA {this.handleData(contract.ends_in)}</Badge>
        } else {
            return <Badge pill variant="danger">EXPIRADO {this.handleData(contract.ends_in)}</Badge>
        }
    });


    delEmpresa = (fornecedor) => {
        this.props.ClienteEmpresaStores.delEmpresas(fornecedor)
    };

    render() {
        const {fornecedor} = this.props.ClienteStores;
        return (
            <Row style={{paddingLeft: '40px', paddingRight: '40px', marginBottom: '50px'}}>
                <style type="text/css">{`a:link {text-decoration: none}`}</style>
                <Table striped bordered condensed hover>
                    <thead>
                    <tr style={{textAlign: 'center', verticalAlign: 'middle'}}>
                        <th style={{
                            width: '90%',
                            fontSize: '18px',
                            paddingLeft: '22px',
                            height: '60px',
                            verticalAlign: 'middle'
                        }}><FontAwesomeIcon icon={"briefcase"}/>&nbsp;EMPRESAS
                        </th>
                        <th style={{width: '46%', textAlign: 'center', verticalAlign: 'middle'}}>AÇÕES</th>
                    </tr>
                    </thead>
                    <tbody>
                    {fornecedor.map((fornecedor) => {
                        const {contract, nome_fantasia, cnpj} = fornecedor;
                        return (
                            <tr key={fornecedor.id}>
                                <td>
                                    <Col md={12} style={{marginLeft: '20px', textDecoration: 'none'}}>
                                        <Link to="/empresas">
                                            <Row>
                                                <div onClick={() => this.Redirect(fornecedor)}>
                                                    <h4>{nome_fantasia}</h4>
                                                    <p>CNPJ: <b>{cnpj}</b></p>
                                                    {contract.length !== 0 ?
                                                        <div>
                                                            {contract.map((contract) =>
                                                                contract.ends_in ?
                                                                    <span>{this.data(contract)}

                                                                        {contract.combo_fk !== null ?
                                                                            <small>&nbsp;&nbsp;{contract.combo_fk.name}</small> : (contract.plano_fk.length !== 0 ?
                                                                                <small>&nbsp;&nbsp;{contract.plano_fk[0].name}</small> : null)}<br/></span> :
                                                                    <span>
                                                                         {this.data(contract)}

                                                                        {contract.combo_fk != null ?
                                                                            <small>&nbsp;&nbsp;{contract.combo_fk.name}</small> : null}<br/></span>
                                                            )}
                                                        </div>
                                                        : <span><Badge pill variant="warning">empresa não possui contrato</Badge></span>}
                                                </div>
                                            </Row>
                                        </Link>
                                    </Col>
                                </td>
                                <td style={{textAlign: 'center', verticalAlign: 'middle'}}><ModalEditarEmpresaHome
                                    empresa={fornecedor}/>&nbsp;<ModalExcluirBase nome={fornecedor.nome_fantasia}
                                                                                  onClick={() => this.delEmpresa(fornecedor.id)}/>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
            </Row>
        );
    }
}

export default TabEmpresasCliente;

