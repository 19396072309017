import React from "react";
import { X } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContainerNavInfo } from "../../Nav/styleNav";
import { Form, InputGroup } from "react-bootstrap";
import ModalCadastroClienteCompleto from "../../Clientes/ModalCliente/ModalCadastroClienteCompleto";
import ModalCadEmpresaHome from "./ModalCadEmpresaHome";

export const HeaderEmpresa = ({ text, onClick, onChange }) => {
  const onSubmitSearch = (e) => {
    e.preventDefault()
    onClick()
  }

  return (
    <ContainerNavInfo>
      <div style={{ marginBottom: "50px" }}>
        <h4>
          <FontAwesomeIcon icon={"building"} /> Empresas Cadastradas
        </h4>
      </div>

      <div
        style={{
          marginBottom: "40px",
          maxWidth: "500px",
          width: "30vw",
          marginRight: "22px",
        }}
      >
        <form onSubmit={onSubmitSearch}>
        <Form.Group controlId="formBasicEmail">
          <InputGroup>
            <Form.Control
              type="text"
              placeholder="pesquisar empresa"
              onChange={(e) => onChange(e.target.value)}
              value={text}
            />
          </InputGroup>
          {text && (
            <div className="close" onClick={() => onChange("")}>
              <X size="15" />
            </div>
          )}
          <InputGroup.Prepend onClick={onClick}>
            <InputGroup.Text id="inputGroupPrepend">
              <FontAwesomeIcon icon={"search"} />
            </InputGroup.Text>
          </InputGroup.Prepend>
        </Form.Group>
        </form>
      </div>

      <div style={{ marginBottom: "50px" }}>
        <ModalCadastroClienteCompleto />
        <ModalCadEmpresaHome />
      </div>
    </ContainerNavInfo>
  );
};
