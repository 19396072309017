import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { Badge, Button, ButtonGroup } from "react-bootstrap";
import { BottomModal } from "../../Modal/BottomModal";
import ModalHeard from "../../Modal/ModalHeard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import MaquinaDeEstadoStores from "../../../Stores/MaquinaDeEstadoStores";

@inject(
  "ClienteStores",
  "ModalStores",
  "MaquinaDeEstadoStores",
  "ContratosStores"
)
@observer
class ModalClientesEmpresa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false
    };
  }

  handleCloseModal = () => {
    this.setState({ isActive: false });
  };
  handleOpenModal = () => {
    this.setState({ isActive: true });
  };

  onRedirect = id => {
    console.log("id", id);
    this.props.ContratosStores.consultaContratoEmpresaID(id);
  };

  render() {
    const { first_name, fornecedor } = this.props.user;
    return (
      <>
        <ButtonGroup aria-label="Basic example">
          <Button
            style={{ fontSize: "12px" }}
            size="sm"
            variant="info"
            onClick={() => this.handleOpenModal()}
            title="Empresa Cadastradas"
          >
            <FontAwesomeIcon style={{ color: "white" }} icon={"briefcase"} />
          </Button>
        </ButtonGroup>

        <Modal
          className="Modal_Load"
          style={{
            content: { width: "40vw", height: "750px", left: "47%", top: "40%" },  zIndex: 3
          }}
          overlayClassName="Overlay_Load"
          isOpen={this.state.isActive}
        >
          <ModalHeard
            icon={"user-tie"}
            titulo={"EMPRESAS CADASTRADAS"}
            onClick={() => this.handleCloseModal()}
          />

          <HeaderCliente cliente={first_name} />
          <div style={{ overflow: "auto", height: "550px" }}>
            <BodyEmpresas
              fornecedor={fornecedor}
              onRedirect={this.onRedirect}
            />
          </div>
          {BottomModal}
        </Modal>
      </>
    );
  }
}

export default ModalClientesEmpresa;

const BodyEmpresas = ({ fornecedor, onRedirect }) => {
  return (
    <>
      {fornecedor.length ? (
        <>
          {fornecedor.map(empresa => {
            const {
              nome_fantasia,
              cnpj,
              contract,
              id,
              razao_social,
              phone
            } = empresa;
            console.log("teste empresa", empresa);
            return (
              <Link to="/contratos">
                <div
                  style={{ marginTop: "10px", display: "flex", width: "100" }}
                  onClick={() => onRedirect(id)}
                >
                  <div
                    style={{
                      borderRadius: "5px",
                      minHeight: "150px",
                      boxShadow:
                        "0 4px 25px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.08)",
                      border: "1px solid #CECECE",
                      width: "100%",
                      marginLeft: "20px",
                      marginRight: "20px",
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "20px"
                      }}
                    >
                      <div>
                        <FontAwesomeIcon
                          style={{
                            fontSize: "25px",
                            color: "#454545",
                            marginRight: "10px"
                          }}
                          icon={"briefcase"}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            fontSize: "20px",
                            color: "#3a566e",
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "10px"
                          }}
                        >
                          <b>{razao_social}</b>{" "}
                          <small style={{ fontSize: "60%" }}>
                            {nome_fantasia}
                          </small>
                        </div>
                        <div
                          style={{
                            fontSize: "15px",
                            color: "#454545",
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between"
                          }}
                        >
                          <div>
                            CNPJ: <b>{cnpj}</b>
                          </div>
                          <div>
                            Telefone: <b>{phone}</b>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        background: "#cccbc7",
                        height: "1px",
                        marginBottom: "10px"
                      }}
                    />

                    {contract.map(contrato => {
                      const { ends_in, combo_fk } = contrato;
                      return (
                        <>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "5px 20px"
                            }}
                          >
                            <div>
                              <FontAwesomeIcon
                                style={{
                                  fontSize: "15px",
                                  marginRight: "10px",
                                  color: "#cccbc7"
                                }}
                                icon={"handshake"}
                              />
                            </div>
                            <div>
                              <div
                                style={{ fontSize: "12px", color: "#454545" }}
                              >
                                <b>{combo_fk && combo_fk.name}&nbsp; &nbsp; </b>
                                <HandleDataContrato ends_in={ends_in} />
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </Link>
            );
          })}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          <FontAwesomeIcon
            style={{
              fontSize: "50px",
              marginRight: "10px",
              color: "#ccb026",
              margin: "15px"
            }}
            icon={"exclamation-circle"}
          />
          <div>CLIENTE NÃO POSSUI EMPRESA</div>
        </div>
      )}
    </>
  );
};

const HandleDataContrato = ({ ends_in }) => {
  let dataAtual = new Date();
  let mydate = new Date(ends_in);

  if (mydate > dataAtual) {
    return (
      <Badge pill variant="success">
        {" "}
        LIBERADA {handleData(ends_in)}
      </Badge>
    );
  } else {
    return (
      <Badge pill variant="danger">
        EXPIRADO {handleData(ends_in)}
      </Badge>
    );
  }
};

const handleData = data => {
  return new Intl.DateTimeFormat("pt-BR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour12: false
  }).format(new Date(data));
};

const HeaderCliente = ({ cliente }) => {
  return (
    <div
      style={{
        background:
          "linear-gradient(141deg, #2cb5e8 0%, #1fc8db 51%, #0fb8ad 75%)",
        color: "white",
        height: "100px",
        padding: "20px",
        display: "flex",
        alignItems: "center"
      }}
    >
      <div>
        <FontAwesomeIcon
          style={{ fontSize: "32px", marginRight: "10px" }}
          icon={"user-circle"}
        />
      </div>
      <div style={{ fontSize: "20px" }}>
        <div style={{ fontSize: "11px" }}>CLIENTE</div>
        <b>{cliente}</b>
      </div>
    </div>
  );
};
