import { observable, action } from "mobx";

class MaquinaDeEstadoStores {
  @observable props = false;
  @observable empresa = [];
  @observable user = [];

  @action setEmpresa = (empresa) => {
    this.empresa = empresa;
  };

  @action setUser = (user) => {
    this.user = user;
  };

  @action
  redireciona_login = () => {
    this.props.history.push('/')
  }

  @action setProps = (props) => {
    this.props = props;
  };

  @action redirect = (type) => {
    this.props.history.push(`/${type}`);
  };
}

const maquinaDeEstadoStores = new MaquinaDeEstadoStores();
export default maquinaDeEstadoStores;
