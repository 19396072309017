import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Modal from "react-modal";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BottomModal } from "../../Modal/BottomModal";
import ModalHeard from "../../Modal/ModalHeard";
import InputMask from "react-input-mask";
import { FormLabelModal } from "../../Modal/LabelModal";
import { ChevronsDown, ChevronsUp, X } from "react-feather";
import { DivUser } from "../Style/style";
import "../../Home/ModalHome/select.css";
import Api from "../../../Stores/Conexao/conexao";

@inject("ClienteStores", "ClienteEmpresaStores")

@observer
class ModalCadEmpresaHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      clientes: [],
      valueCliente: "",
      status: false,
      arrayUsuarios: [],
      loading: false,
    };
  }

  onChangeCliente = (e) => {
    this.setState({ valueCliente: e.target.value });
  };

  handleListaCliente = (e) => {
    this.setState({ status: true });
  };

  handleCloseListaClientes = () => {
    if (this.state.status) {
      this.setState({ valueCliente: "" });
    }
    this.setState({ status: !this.state.status });
  };

  handleCloseModal = () => {
    this.setState({ isActive: false, idCliente: "" });
    this.props.ClienteEmpresaStores.ClearInputs();
  };

  handleOpenModal = async () => {
    this.setState({ isActive: true, loading: true });
    const res = await Api.get('platform_all_users')
    this.setState({ clientes: res.data, loading: false });
  };

  removarUserLista = (user) => {
    let dados = [...this.state.clientes, user];
    this.setState({ clientes: dados });
    let newArray = [];
    newArray = this.state.arrayUsuarios.filter((item) => {
      return item.id !== user.id;
    });
    this.setState({ arrayUsuarios: newArray });
  };

  searchClientes = (user) => {
    console.log(user);
    let newUser;
    newUser = user.filter((item) => {
      return (
        item.first_name
          .toLowerCase()
          .includes(this.state.valueCliente.toLowerCase()) ||
        item.username
          .toLowerCase()
          .includes(this.state.valueCliente.toLowerCase())
      );
    });
    return newUser.sort((a, b) =>
      a.first_name > b.first_name ? 1 : b.first_name > a.first_name ? -1 : 0
    );
  };

  handleLista = (cliente) => {
    this.setState({ arrayUsuarios: [...this.state.arrayUsuarios, cliente] });
    let newArray = [];
    newArray = this.state.clientes.filter((item) => {
      return item.id !== cliente.id;
    });
    this.setState({ clientes: newArray });
  };

  handleAddClienteCloseModal = (evento) => {
    evento.preventDefault();
    let idUsuario = this.arrayIdCliente();
    if (this.props.ClienteEmpresaStores.ChecksInputs(idUsuario)) {
      this.props.ClienteEmpresaStores.addEmpresa(idUsuario);
      this.handleCloseModal();
    } else {
    }
  };

  arrayIdCliente = () => {
    let dados = [];
    this.state.arrayUsuarios.map((item) => {
      return (dados = [...dados, item.id]);
    });
    return dados;
  };

  render() {
    return (
      <span>
        <Button
          onClick={() => this.handleOpenModal()}
          style={{ marginRight: "20px" }}
          variant="secondary"
          size="sm"
        >
          <FontAwesomeIcon icon={"building"} /> CADASTRAR EMPRESA
        </Button>
        <Modal
          className="Modal_Load"
          style={{
            content: {
              width: "800px",
              height: "700px",
              left: "47%",
              top: "43%",
            },
          }}
          overlayClassName="Overlay_Load"
          isOpen={this.state.isActive}
        >
          <ModalHeard
            icon={"briefcase"}
            titulo={"CADASTRAR EMPRESA"}
            onClick={() => this.handleCloseModal()}
          />
          <br />
          <div>
            <Col md={12}>
              <Form>
                <Form.Group controlId="formBasicEmail">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon={"user-circle"} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      name={"nome_fantasia"}
                      type="text"
                      placeholder="nome fantasia"
                      onChange={(e) =>
                        this.props.ClienteEmpresaStores.onChange(e)
                      }
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon={"user-circle"} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      name={"razao_social"}
                      type="text"
                      placeholder="razão social"
                      onChange={(e) =>
                        this.props.ClienteEmpresaStores.onChange(e)
                      }
                    />
                  </InputGroup>
                </Form.Group>

                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "98%",
                      marginRight: "10px",
                    }}
                  >
                    <div style={{ display: "flex", width: "100%" }}>
                      <InputGroup.Text
                        style={{ height: "41px", width: "42px" }}
                        id="inputGroupPrepend"
                      >
                        <FontAwesomeIcon icon="code" />
                      </InputGroup.Text>
                      <InputMask
                        name={"cnpj"}
                        style={{
                          marginLeft: "-0.7%",
                          width: "100%",
                          border: "1.2px",
                          borderRadius: "4px",
                          height: "41px",
                          borderColor: "#ced4da",
                          borderStyle: "solid",
                        }}
                        mask="99.999.999/9999-99"
                        maskChar={null}
                        placeholder="  cnpj.."
                        onChange={(e) =>
                          this.props.ClienteEmpresaStores.onChange(e)
                        }
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "98%",
                      marginLeft: "10px",
                    }}
                  >
                    <div style={{ display: "flex", width: "100%" }}>
                      <InputGroup.Text
                        style={{ height: "41px", width: "42px" }}
                        id="inputGroupPrepend"
                      >
                        <FontAwesomeIcon icon="phone-square" />
                      </InputGroup.Text>

                      <InputMask
                        name={"phone"}
                        style={{
                          marginLeft: "-0.7%",
                          width: "100%",
                          border: "1.2px",
                          borderRadius: "4px",
                          height: "41px",
                          borderColor: "#ced4da",
                          borderStyle: "solid",
                        }}
                        mask="   (99) 99999-9999"
                        maskChar={null}
                        placeholder="  telefone.."
                        onChange={(e) =>
                          this.props.ClienteEmpresaStores.onChange(e)
                        }
                      ></InputMask>
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: "10px" }}>
                  {" "}
                  {FormLabelModal("lista de usuarios")}
                </div>
                <div
                  className="select"
                  style={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    border: "1px solid #ced4da",
                    maxHeight: "150px",
                    overflow: "auto",
                    minHeight: "40px",
                  }}
                >
                  {this.state.arrayUsuarios.map((user) => {
                    return (
                      <div
                        style={{
                          width: "auto",
                          height: "28px",
                          borderRadius: "7px",
                          background: "#54A0FF",
                          color: "white",
                          padding: "5px 5px 5px 15px",
                          fontSize: "12px",
                          margin: "5px",
                        }}
                      >
                        {user.first_name}
                        <span
                          style={{ marginLeft: "10px" }}
                          onClick={() => this.removarUserLista(user)}
                        >
                          <X
                            className="icon-scale icon-scale:hover"
                            size={"13px"}
                          />
                        </span>
                      </div>
                    );
                  })}
                </div>

                <div style={{ marginTop: "20px" }}>
                  <Form.Group controlId="formBasicEmail">
                    {FormLabelModal("adicionar usuarios")}
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">
                          <FontAwesomeIcon icon={"user-circle"} />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        onClick={(e) => this.handleListaCliente(e)}
                        onChange={(e) => this.onChangeCliente(e)}
                        name={"cliente"}
                        value={this.state.valueCliente}
                        type="text"
                        placeholder="Selecione um Usuario"
                      />
                    </InputGroup>
                  </Form.Group>
                  {this.state.status && (
                    <div className="select-search-box__select_novo box">
                      <ul className="select-search-box__options box">
                        {this.state.loading ? <div style={{ textAlign: 'center', marginTop: '3rem' }}>Carregando...</div>
                          : this.searchClientes(this.state.clientes).map(
                            (usuario) => {
                              return (
                                <DivUser
                                  role="menuitem"
                                  className="select-search-box__option select-search-box__row"
                                  data-value="annie.cruz"
                                  onClick={() => {
                                    this.handleLista(usuario);
                                  }}
                                >
                                  <div>{usuario.first_name}</div>
                                  <div className="user-mail">{usuario.email}</div>
                                </DivUser>
                              );
                            }
                          )}
                      </ul>
                    </div>
                  )}
                </div>
                <div
                  onClick={() => this.handleCloseListaClientes()}
                  style={{ textAlign: "center" }}
                >
                  {this.state.status ? (
                    <ChevronsUp
                      color={"#3a566e"}
                      className="icon-scale icon-scale:hover"
                    />
                  ) : (
                    <ChevronsDown
                      color={"#3a566e"}
                      className="icon-scale icon-scale:hover"
                    />
                  )}
                </div>

                <div style={{ textAlign: "right" }}>
                  <Button
                    variant="outline-success"
                    size="sm"
                    onClick={(e) => this.handleAddClienteCloseModal(e)}
                  >
                    Cadastrar
                  </Button>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => this.handleCloseModal()}
                    style={{ marginLeft: "10px" }}
                  >
                    Fechar
                  </Button>
                </div>
              </Form>
            </Col>
          </div>
          {BottomModal}
        </Modal>
      </span>
    );
  }
}

export default ModalCadEmpresaHome;
