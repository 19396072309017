import React from "react";

const HaerdModal = ({icon, titulo}) => {
    return (
        <div style={{
            borderTopLeftRadius: '5px', borderTopRightRadius: '5px',
            background: '#3a566e', color: 'white', height: '40px', border: '2px solid #3a566e',
            display: 'flex', padding: '9px', justifyContent: 'space-between', fontSize: '14px'
        }}>
            <div>
                <>
                        <span>
                           {icon}
                        </span>
                    &nbsp;&nbsp;{titulo}
                </>
            </div>
        </div>

    )
};
export default HaerdModal