import styled from "styled-components";

export const ContainerContrato = styled.div`
  position: absolute;
  background: #dedede;
  top: 198px;

  border-radius: 10px;
  padding-top: 7px;
  width: 99%;
  height: 77vh;
  box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.31);
`;
