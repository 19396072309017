import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Pagination from "@material-ui/lab/Pagination";
import {
  columnsCliente,
  ContainerTabelaCliente,
  customStyles,
  PaginationOptions,
  Container,
} from "./StyleTabela";
import { styleTable } from "./style/index";
import { AvisoEsclamacao } from "../ComponentsPadrao/MensagemArrayVazio";
import HeaderClientes from "./HeaderClientes";
import { inject, observer } from "mobx-react";

const UserDetails = inject("ClienteStores", "MaquinaDeEstadoStores", "ClienteEmpresaStores")(
  observer(({ ClienteStores, MaquinaDeEstadoStores, ClienteEmpresaStores }) => {
    const {
      users,
      consultaClientes,
      count,
      previousPage,
      setUsers,
      text,
      setText,
      valueSearch,
      resultSearch,
      searchClients,
      countSearch,
      valueInSearch,
      consultaClienteId,
      customerCompanyConsultation
    } = ClienteStores;
    const { redirect } = MaquinaDeEstadoStores;
    return (
      <IndexClientesComStore
        redirect={redirect}
        users={users}
        consultaClientes={consultaClientes}
        count={count}
        previous={previousPage}
        setUsers={setUsers}
        text={text}
        setText={setText}
        valueSearch={valueSearch}
        resultSearch={resultSearch}
        searchClients={searchClients}
        countSearch={countSearch}
        valueInSearch={valueInSearch}
        consultaClienteId={consultaClienteId}
        customerCompanyConsultation={customerCompanyConsultation}
      />
    );
  })
);

const IndexClientesComStore = ({
  users,
  consultaClientes,
  count,
  text,
  setText,
  resultSearch,
  valueSearch,
  searchClients,
  countSearch,
  valueInSearch,
  redirect,
  consultaClienteId,
  customerCompanyConsultation
}) => {
  const [page, setPage] = useState(1);
  const [pageUsers, setPageUsers] = useState(1);

  useEffect(() => {
    consultaClientes(0);
  }, []);

  const handleChangePageTable = (e, value) => {
    setPage(value);
    searchClients(text, value);
  };

  const onClick = () => {
    searchClients(text, null);
  };

  const handleChangePage = (e, value) => {
    setPageUsers(value);
    consultaClientes(Number(value - 1));
  };

  const onRedirect = ({ id }) => {
    console.log(id)
    customerCompanyConsultation(id)
    consultaClienteId(id)
    redirect("detalhes-usuario");
  };

  return (
    <>
      <HeaderClientes
        text={text}
        valueInSearch={valueInSearch}
        onClick={(e) => onClick(e)}
        onChange={setText}
      />
      <Container>
        <ContainerTabelaCliente>
          <DataTable
            fixedHeader
            style={styleTable}
            columns={columnsCliente}
            customStyles={customStyles}
            allowOverflow={true}
            overflowY={true}
            onRowClicked={(row) => onRedirect(row)}
            data={valueSearch ? resultSearch : users}
            pointerOnHover
            striped
            paginationTotalRows={count}
            highlightOnHover
            noDataComponent={
              <AvisoEsclamacao texto={"Clientes não localizados"} />
            }
          />
          <PaginationOptions>
            {valueSearch ? (
              <Pagination
                count={Math.ceil(countSearch / 50)}
                onChange={handleChangePageTable}
                page={page}
                variant="outlined"
                color="primary"
              />
            ) : (
              <Pagination
                count={Math.ceil(count / 50)}
                onChange={handleChangePage}
                page={pageUsers}
                variant="outlined"
                color="primary"
              />
            )}
          </PaginationOptions>
        </ContainerTabelaCliente>
      </Container>
    </>
  );
};

export default UserDetails;
