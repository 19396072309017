import React, { Component } from "react";
import styled from "styled-components";
import { Route, Switch } from "react-router-dom";
import { IndexPlanos } from "./Components/Planos/IndexPlanos";
import { ClienteIndex } from "./Components/Cliente/ClienteIndex";
import UserDetails from "./Components/UserDetails/UserDetails"
import { indexCofiguracoes } from "./Components/Configurações/indexConfiguracoes";
import { EmpresasIndex } from "./Components/Empresas/EmpresasIndex";
import Login from "./Components/Login/login";
import { inject, observer } from "mobx-react";
import Alert from "react-s-alert";
import ModalLoading from "./Components/Modal/ModalLoading";
import { defaultsHeadersAxios } from "./Stores/Conexao/conexao";
import { getToken } from "./LocalStore/Actions";
import MaquinaDeEstadoStores from "./Stores/MaquinaDeEstadoStores";
import { HomeNavBar } from "./Components/Nav/HomeNavBar";
import TabClienteHome from "./Components/Home/TableHome/TabClienteHome";
import IndexClientes from "./Components/Clientes/indexClientes";
import IndexEmpresas from "./Components/Empresa/indexEmpresas";
import IndexContrato from "./Components/Contrato/indexContrato";
import { IndexDashboard } from "./Components/Dashboard/indexDashboard";
import Wavebots from "./Components/Wavebots/Wavebots";
import Graphics from "./Components/Graphics/index";
import GlobalStyle from "./styles/global";

@inject("LoginStores", "ClienteEmpresaStores", "ClienteStores")
@observer
class App extends Component {
  constructor(props) {
    super(props);
    getToken() && defaultsHeadersAxios(getToken());
  }

  componentDidMount() {
    MaquinaDeEstadoStores.setProps(this.props);
    MaquinaDeEstadoStores.props = this.props;
  }

  render() {
    const title = this.props.location.pathname;
    const path = window.location.pathname;
    return (
      <Container path={path}>
        <GlobalStyle />
        {this.props.LoginStores.status_rota ? (
          <>
            <HomeNavBar title={title} />
            <Switch>
              <Route exact path="/dashboard" component={IndexDashboard} />
              <Route exact path="/graficos" component={Graphics} />
              <Route exact path="/wavebots" component={Wavebots} />
              <Route exact path="/clientes" component={IndexClientes} />
              <Route exact path="/empresas" component={IndexEmpresas} />
              <Route path="/contratos" component={IndexContrato} />
              <Route exact path="/produtos" component={IndexPlanos} />
              <Route path="/clientes" component={ClienteIndex} />
              <Route path="/detalhes-usuario" component={UserDetails} />
              <Route
                exact
                path="/configuracoes"
                component={indexCofiguracoes}
              />
              <Route exact path="/empresas" component={EmpresasIndex} />
              <Route exact path="/" component={TabClienteHome} />
            </Switch>
          </>
        ) : (
          <Switch>
            <Route exact="/" component={Login} />
            <Route exact path="/login" component={Login} />
          </Switch>
        )}
        <div style={{ position: "absolute", zIndex: "555555" }}>
          <Alert stack={{ limit: 5, spacing: 0 }} />
        </div>
        <ModalLoading />
      </Container>
    );
  }
}

export default App;

const Container = styled.div`
  height: 100vh;
  overflow: ${(props) =>
    props.path === "/clientes" || props.path === "/empresas"
      ? "hidden"
      : "scroll"};
`;
