import { action, observable } from "mobx";
import { AlertaError, AlertaSuccess } from "./Funcoes/FuncoesGerais";
import Api from "./Conexao/conexao";
import { globalDebug } from "./Funcoes/Debug";
import ModalLoading from "./ModalLoading";

class ClienteStores {
  @observable first_name = "";
  @observable last_name = "";
  @observable email = "";
  @observable password = "";
  @observable repeat_password = "";
  @observable phone = "";
  @observable users = [];
  @observable user = {};
  @observable is_active = false;
  @observable resultSearch = [];
  @observable count = null;
  @observable fornecedor = [];
  @observable pagina_atual = 1;
  @observable length_clientes = 0;
  @observable cliente = {};
  @observable text = "";
  @observable valueSearch = false;
  @observable countSearch = null;
  @observable valueInSearch = false;

  @action setISActive = (event) => {
    if (this.email === 'admin@wavecode.com.br') {
      return AlertaError(
        "Usuário admin não pode ser inativado!"
      );
    }
    this.is_active = event.target.checked
  }

  @action setText = (txt) => {
    this.text = txt;
    this.text ? (this.valueInSearch = true) : (this.valueInSearch = false);
    if (!txt) this.valueSearch = false;
  };

  @action searchClients = async (text, page) => {
    ModalLoading.openModal();
    this.valueSearch = true;
    try {
      const res = await Api.get(
        Number.isInteger(page)
          ? `search_users?filter=${text}&page=${page}`
          : `search_users?filter=${text}`
      );
      this.resultSearch = res.data.results;
      this.countSearch = res.data.count;
    } catch (err) {
      globalDebug("consulta/CONTRATO", err);
    } finally {
      ModalLoading.clouseModal();
    }
  };

  @action consultaClientes = async (value) => {
    ModalLoading.openModal();
    try {
      const res = await Api.get(
        value
          ? `platform_users/?page=${value + 1}&pagina=1`
          : "platform_users/?pagina=1"
      );
      this.users = res.data.results;
      this.count = res.data.count;
    } catch (err) {
      globalDebug("consulta/CONTRATO", err);
    } finally {
      ModalLoading.clouseModal();
    }
  };

  @action consultaClientesSemLoading = async (value) => {
    try {
      const res = await Api.get(
        value
          ? `platform_users/?page=${value + 1}&pagina=1`
          : "platform_users/?pagina=1"
      );
      this.users = res.data.res;
      this.count = res.data.count;
    } catch (err) {
      globalDebug("consulta/CONTRATO", err);
    }
  };

  @action valueDefalutEditar = ({
    id,
    username,
    first_name,
    last_name,
    phone,
    is_active
  }) => {
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = username;
    this.phone = phone;
    this.password = "";
    this.is_active = is_active
  };

  @action consultaClienteId = (id) => {
    ModalLoading.openModal();
    Api.get("platform_user/" + id)
      .then((response) => {
        this.fornecedor = response.data.fornecedor;
        this.user = response.data
      })
      .catch((error) => {
        globalDebug("consulta/CONTRATO", error);
      })
      .finally(() => {
        ModalLoading.clouseModal();
      });
  };

  @action customerCompanyConsultation = (id) => {
    ModalLoading.openModal();
    Api.get(`user/${id}/providers/`)
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        globalDebug("consulta/CONTRATO", error);
      })
      .finally(() => {
        ModalLoading.clouseModal();
      });
  };

  @action addClientes = () => {
    ModalLoading.openModal();
    const data = new FormData()
    data.append("first_name", this.first_name)
    data.append('last_name', this.last_name)
    data.append('phone', this.phone)
    data.append('password', this.password)
    data.append('repeat_password', this.repeat_password)
    data.append('username', this.email)
    data.append('email', this.email)
    Api.post("cadastro_staff/", data)
      .then(() => {
        this.consultaClientes();
        AlertaSuccess(
          "CLIENTE " + this.first_name + " adicionado com sucesso!"
        );
      })
      .catch(() => ModalLoading.clouseModal())
      .finally(() => ModalLoading.clouseModal())
  };

  @action UpdateClientesId = (id, managerial = null) => {
    ModalLoading.openModal();
    const data = {
      first_name: this.first_name,
      last_name: this.last_name,
      phone: this.phone,
      username: this.email,
      is_active: this.is_active,
      gerencial: managerial
    };
    if (this.password) data["password"] = this.password;

    Api.patch(`platform_user/${id}`, data)
      .then((response) => {
        if (this.valueSearch) {
          this.searchClients(this.text, null)
        } else {
          this.consultaClientes();
        }
        AlertaSuccess("CLIENTE " + this.first_name + " editado com sucesso!");

      })
      .catch((error) => {
        globalDebug("consulta/CONTRATO", error);
      })
      .finally(() => {
        ModalLoading.clouseModal();
      });
  };

  @action deletar = (id) => {
    ModalLoading.openModal();
    Api.delete(`platform_user/${id}`)
      .then((response) => {
        AlertaSuccess("CLIENTE " + this.first_name + " deletado com sucesso!");
        this.consultaClientes();
      })
      .catch((error) => {})
      .finally(() => {
        ModalLoading.clouseModal();
      });
  };

  @action ChecksInputs = () => {
    if (
      this.first_name === "" &&
      this.email === "" &&
      this.last_name === "" &&
      this.password === ""
    ) {
      AlertaError("CAMPOS não podem ser em brancos!");
      return false;
    } else {
      if (this.first_name === "") {
        AlertaError("Campo NOME não pode estar em branco!");
        return false;
      }
      if (this.last_name === "") {
        AlertaError("Campo SOBRENOME não pode estar em branco!");
        return false;
      }
      if (this.email === "") {
        AlertaError("Campo EMAIL não pode estar em branco!");
        return false;
      }
      // if (this.password === "") {
      //     AlertaError("Campo PASSWORD não pode estar em branco!");
      //     return (false)
      // }
      // if (this.repeat_password === "") {
      //     AlertaError("Campo PASSWORD não pode estar em branco!");
      //     return (false)
      // }
      return true;
    }
  };

  @action ClearInputs = () => {
    this.first_name = "";
    this.last_name = "";
    this.password = "";
    this.email = "";
    this.repeat_password = "";
    this.phone = "";
  };

  @action onChange = (evento) => {
    const campo = evento.target.name;
    this[campo] = evento.target.value;
  };

  @action ChecksCuurentPage = () => {
    if (this.pagina_atual === 0) {
      this.pagina_atual = 1;
    }
  };

  @action
  onPageChanged = (data) => {
    const { currentPage } = data;
    if (currentPage !== 0) {
      this.pagina_atual = currentPage;
      this.consultaClientes();
    }
  };
}

const clienteStores = new ClienteStores();
export default clienteStores;
