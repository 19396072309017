import React, {Component} from 'react';
import {Button, Col, Form, ButtonGroup, InputGroup} from 'react-bootstrap';
import Modal from 'react-modal';
import {inject, observer} from 'mobx-react'
import {BottomModal} from "../../Modal/BottomModal";
import ModalHeard from "../../Modal/ModalHeard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormLabelModal} from "../../Modal/LabelModal";



@inject('VedStores')
@observer
class ModalCadVendedor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false
        };
    }

    handleCloseModal = () => {
        this.setState({ isActive: false });
        this.props.VedStores.ClearInputs()
    };

    handleOpenModal = () => {
        this.setState({ isActive: true });
    };

    handleAddVendedorCloseModal = (evento) => {
        evento.preventDefault();
        if (this.props.VedStores.ChecksInputs()){
            this.props.VedStores.addVendedor();
            this.setState({ isActive: false });
        }else {
        }
    };


    render() {
        return (
            <span>
                <ButtonGroup aria-label="Basic example">
                    <Button size="sm" disabled variant="outline-primary">&nbsp;+</Button>
                    <Button size="sm" variant="primary"  onClick={() => this.handleOpenModal()}>Vendedor</Button>
                </ButtonGroup>

                 <Modal
                     className="Modal_Load"
                     style={{content: {width: '600px', height: '313px', left: '47%', top: '38%' }}}
                     overlayClassName="Overlay_Load"
                     isOpen={this.state.isActive}>
                        <ModalHeard icon={'users'} titulo={"Cadastrar Vendedor"}  onClick={()=> this.handleCloseModal()}/><br/>
                        <div>
                            <Col md={12} style={{marginTop: '1%'}}>
                                <Form>
                                    <Form.Group>
                                             {FormLabelModal("nome vendedor")}
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend"><FontAwesomeIcon icon="users"/></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            name="name"
                                            type="text"
                                            placeholder="..."
                                            onChange={(evento) => this.props.VedStores.onChange(evento)}
                                            aria-describedby="inputGroupPrepend"/>
                                    </InputGroup>
                                    </Form.Group>

                                    <Form.Group>
                                         {FormLabelModal("email")}
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            name="email"
                                            type="email"
                                            placeholder="..."
                                            onChange={(evento) => this.props.VedStores.onChange(evento)}
                                            aria-describedby="inputGroupPrepend"/>
                                    </InputGroup>
                                    </Form.Group>
                                    <div style={{textAlign: 'right'}}>
                                        <Button variant="outline-success" size={'sm'} onClick={(evento) => this.handleAddVendedorCloseModal(evento)}>Cadastrar</Button>
                                        <Button variant="outline-danger"  size={'sm'} onClick={() => this.handleCloseModal()} style={{marginLeft: '10px'}}>Fechar</Button>
                                    </div>
                                </Form>
                            </Col>
                        </div>
                        {BottomModal}
                </Modal>
            </span>

        );
    }
}

export default ModalCadVendedor;

