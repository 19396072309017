import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background: ${props => props.status === '#c41d1d' ? '#c41d1d' : 'white'} ;
  color:  ${props => props.status === '#c41d1d' ? 'white' : props.status === 'orange' && '#929292'} ;
  align-items: center;
  z-index: -1;
  justify-content: center;
  justify-items: center;
  width: 47%;
  height: 70px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 12px 14px 12px -9px rgba(0,0,0,0.15);
  animation: flip-in-hor-bottom 0.59s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

  @keyframes flip-in-hor-bottom {
      0% {
        -webkit-transform: rotateX(80deg);
                transform: rotateX(80deg);
        opacity: 0;
      }
      100% {
        -webkit-transform: rotateX(0);
                transform: rotateX(0);
        opacity: 1;
      }
    }

`;

export const Status = styled.div`
  display: flex;
  background: ${props => props.status};
  width: 25px;
  height: 100%;
  border-radius: 10px 0 0 10px ;
`;

export const Portal = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  padding: 10px;
  font-weight: bold;
  font-size: 18px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 10px 0 0 10px ;
  border-right: 1px solid #8f8f8f;
  
    @media (max-width: 1200px) {
    font-size: 12px;
   }  
`;

export const NumeroCapitura = styled.div`
  display: flex;
  width: 40%;
  height: 100%;
  padding: 5px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-right: 1px solid #8f8f8f;
  font-size: 40px;
  font-weight: bold;
  
  .texto{
   font-size: 11px;
   font-weight: lighter;
   margin-top: -10px;
  }
  
    .texto-error{
       font-size: 10px;
       font-weight: lighter;
       text-transform: none;
       margin-top: -10px;
  }
  .wrapper{
   width: 110px;
  }
  .edital{
    letter-spacing: -2px;
    color: ${props => props.status === 'orange' ? '#929292' : '#682d73'};

    font-weight: bold;
    margin: 0;
    padding: 0;
  }
  .item{
    letter-spacing: -2px;
    color: ${props => props.status === 'orange' ? '#929292' : '#4568d0'};

    margin: 0;
    padding: 0;
    
    
    
    
  }
  
  
   @media (max-width: 1200px) 
  {
   font-size: 30px;
   }  
`;

export const Tempo = styled.div`
 
  flex-direction: column;
  width: 130px;
  height: 95%;

 
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: lighter;
  font-size: 13px;
  
  

  .duracao{
    font-style: italic;
    font-weight: bold;
    font-size: 18px;
    
    div{
        font-weight: normal;
        font-size: 11px;
        font-style: normal;
        }
  }
  
  @media (max-width: 1200px) {
    font-size: 14px;
    .duracao{
          font-size: 15px;
       }
   } 
`;
