export function dataAtual() {
    let data =  new Date();
    let dia = data.getDate();
    let mes = data.getMonth() + 1;
    let ano = data.getFullYear();
    if (dia < 10){
        dia = '0'+String(dia)+'/'
    }else{
        dia = String(dia)+'/'
    }
    if (mes < 10){
        mes= '0'+String(mes)+'/'
    }else{
        mes= String(mes)+'/'
    }
    return dia+mes+ano
}

export function minutoAtual() {
    let data =  new Date();
    return data.getMinutes()
}

export function horaAtual() {
    let data =  new Date();
    let hora = data.getHours();
    let minuto = data.getMinutes();
    let segundos = data.getSeconds();

    return hora+':'+minuto+':'+segundos
}