import React, {Component} from 'react';
import {ArrowDownCircle, ArrowUpCircle} from "react-feather";

class BodyContratos extends Component {
    render() {
        const {combo_fk:{plano_fk}} = this.props.contrato;
        return (
            <>
            {plano_fk.map((plano) => {
                    return (
                        <div style={{margin: '10px'}}>
                            <Modulos plano={plano}/>
                        </div>
                    )
                })}
                </>
        );
    }
}
export default BodyContratos;


class Modulos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
        };
    };

    handleCard = () => {
        this.setState({isActive: !this.state.isActive})
    };

    render() {
        const {isActive} = this.state;
        const {name, value, portal_fk, product_fk, feature_fk} = this.props.plano;
        return (
            <div style={{
                border: '1px solid #dfdfdf',
                padding: '20px',
                marginBottom: '5px',
                borderRadius: '5px',
            }}>
                <div style={{background: '#fff', borderRadius: '7px',}}>
                    <div style={{
                        display: 'flex',
                        textTransform: 'uppercase',
                        fontSize: '12px',
                        fontWeight: 'bolder',
                    }}>

                        <div style={{width: '30%'}}>
                            <SubTitulo titulo={name}/>
                        </div>
                        <div style={{width: '25%'}}>
                            <SubTitulo titulo={'portal'}/>
                        </div>

                        <div style={{width: '15%'}}>
                            <SubTitulo titulo={'produto'}/>
                        </div>
                        <div style={{width: '30%'}}>
                            <SubTitulo titulo={'feature'}/>
                        </div>
                        <div style={{width: '30px'}} onClick={() => this.handleCard()}>
                            {!isActive && <ArrowDownCircle/>}
                        </div>
                    </div>

                    {isActive &&
                    <div style={{
                        display: 'flex',
                        textTransform: 'uppercase',
                        fontSize: '12px',
                        padding: '10px',
                        marginBottom: '10px'
                    }}>
                        <div style={{width: '30%'}}>
                            <div>{name}</div>
                            <div> Valor: R$ {value}</div>
                        </div>
                        <div style={{width: '25%'}}>
                            <Portal portal_fk={portal_fk}/>
                        </div>
                        <div style={{width: '15%'}}>
                            <Produto product_fk={product_fk}/>
                        </div>
                        <div style={{width: '30%'}}>
                            <Feature feature_fk={feature_fk}/>
                        </div>
                        <div style={{width: '30px'}} onClick={() => this.handleCard()}>
                            <ArrowUpCircle/>
                        </div>
                    </div>
                    }
                </div>
            </div>
        );
    }
}


const SubTitulo = ({titulo}) => {
    return (
        <span style={{
            border: '1px solid #268ccf',
            color: '#268ccf',
            fontSize: '11px',
            padding: '5px 10px',
            borderRadius: '5px'
        }}>{titulo}</span>
    )
};

const Produto = (({product_fk: {name}}) =>  <div>{name}</div>);

const Portal = (({portal_fk}) => {
    return (
        <>
            {portal_fk.map(({nome}) => {
                return (<div>{nome}</div>)
            })}
        </>
    )
});

const Feature = (({feature_fk}) => {
    return (
        <>
            {feature_fk.map(({name}) => {
                return (<div>{name}</div>)
            })}
        </>
    )
});
