import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faCheckSquare,
    faPen,
    faTimes,
    faRetweet,
    faBell,
    faConciergeBell,
    faLock,
    faCalendarAlt,
    faGavel,
    faHome,
    faComments,
    faTv,
    faCog,
    faCogs,
    faSearchPlus,
    faPaste,
    faKey,
    faGlobe,
    faBriefcase,
    faMap,
    faEye,
    faDownload,
    faPlus,
    faList,
    faFilter,
    faUsers,
    faArrowsAltH,
    faTrash,
    faEdit,
    faEnvelope,
    faPhone,
    faPlay,
    faCheck,
    faCalendar,
    faSave,
    faSpinner,
    faClock,
    faStar,
    faExclamationCircle,
    faAt,
    faMapMarkerAlt,
    faEraser,
    faTimesCircle,
    faCheckCircle,
    faCode,
    faDoorOpen,
    faSync,
    faAngleUp,
    faAddressCard,
    faEyeSlash,
    faArrowAltCircleLeft,
    faQuestionCircle,
    faExclamation,
    faIndustry,
    faUserPlus,
    faUnlink,
    faInfoCircle,
    faUserTimes,
    faDatabase,
    faClipboardList,
    faDiceD6,
    faAlignCenter,
    faBookmark,
    faProjectDiagram,
    faListAlt,
    faBoxes,
    faPhoneSquare,
    faUserCircle,
    faUserTie,
    faUserEdit,
    faCircleNotch,
    faSearch,
    faHandshake,
    faTachometerAlt,
    faBuilding,
    faUserShield,
    faIdCard,



} from '@fortawesome/free-solid-svg-icons'

library.add(
    faCheckSquare,
    faPen,
    faTimes,
    faRetweet,
    faBell,
    faConciergeBell,
    faGavel,
    faCalendarAlt,
    faHome,
    faComments,
    faTv,
    faCog,
    faCogs,
    faSearchPlus,
    faPaste,
    faLock,
    faKey,
    faGlobe,
    faBriefcase,
    faMap,
    faEye,
    faDownload,
    faPlus,
    faList,
    faFilter,
    faUsers,
    faArrowsAltH,
    faTrash,
    faEdit,
    faEnvelope,
    faPhone,
    faPlay,
    faCheck,
    faCalendar,
    faSave,
    faSpinner,
    faClock,
    faStar,
    faExclamationCircle,
    faAt,
    faMapMarkerAlt,
    faEraser,
    faTimesCircle,
    faCheckCircle,
    faCode,
    faDoorOpen,
    faSync,
    faAngleUp,
    faAddressCard,
    faEyeSlash,
    faArrowAltCircleLeft,
    faQuestionCircle,
    faExclamation,
    faIndustry,
    faUserPlus,
    faUnlink,
    faInfoCircle,
    faUserTimes,
    faDatabase,
    faClipboardList,
    faDiceD6,
    faAlignCenter,
    faCode,
    faBookmark,
    faProjectDiagram,
    faListAlt,
    faBoxes,
    faPhoneSquare,
    faUserCircle,
    faUserTie,
    faUserEdit,
    faCircleNotch,
    faSearch,
    faHandshake,
    faTachometerAlt,
    faBuilding,
    faUserShield,
    faIdCard

);