import React, { Component } from "react";
import Modal from "react-modal";
import { inject, observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, ButtonGroup, InputGroup } from "react-bootstrap";

import ModalHeard from "../../Modal/ModalHeard";
import { BottomModal } from "../../Modal/BottomModal";
import { FormLabelModal } from "../../Modal/LabelModal";

@inject("ContratosStores", "ComboStores", "MaquinaDeEstadoStores")
@observer
class ModalEditarContrato extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
  }

  handleCloseModal = () => {
    this.setState({ isActive: false });
  };
  handleOpenModal = () => {
    console.log("ModalEditarContrato");
    const { id } = this.props.contratos;

    this.props.ContratosStores.ends_in = this.props.ends_in.substr(0, 10);
    this.props.ContratosStores.combo_fk = id;

    // this.props.ContratosStores.consultaPlanoNovo();
    if (!this.props.ComboStores.combos.length) {
      this.props.ComboStores.consultaCombo();
    }
    setTimeout(() => {
      this.setState({ isActive: true });
    }, 1000);
  };

  handleUpdateContratoCloseModal = (evento) => {
    const idContrato = this.props.idContrato;
    evento.preventDefault();
    if (this.props.ContratosStores.ChecksInputs()) {
      this.props.ContratosStores.UpdateContratoId(idContrato);
      this.setState({ isActive: false });
    } else {
    }
  };

  render() {
    const { id } = this.props.contratos;
    // const {empresa:{nome_fantasia}} = this.props.MaquinaDeEstadoStores;
    const { combos } = this.props.ComboStores;
    const { onChange, dadosEmpresa } = this.props.ContratosStores;
    return (
      <span>
        <ButtonGroup aria-label="Basic example">
          <Button
            style={{ fontSize: "13px" }}
            variant="warning"
            title="EDITAR REGISTRO"
            onClick={() => this.handleOpenModal()}
          >
            <FontAwesomeIcon style={{ color: "white" }} icon={"edit"} />
          </Button>
        </ButtonGroup>
        <Modal
          className="Modal_Load"
          style={{
            content: {
              width: "600px",
              height: "420px",
              left: "47%",
              top: "38%",
            },
          }}
          overlayClassName="Overlay_Load"
          isOpen={this.state.isActive}
        >
          <ModalHeard
            icon={"clipboard-list"}
            titulo={"EDITAR CONTRATO"}
            onClick={() => this.handleCloseModal()}
          />
          <br />
          <div>
            <Col md={12} style={{ marginTop: "1%" }}>
              <Form>
                <Form.Group>
                  {FormLabelModal("empresa")}
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon="clipboard-list" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      disabled={true}
                      name="company_fk"
                      type="text"
                      placeholder="data"
                      defaultValue={dadosEmpresa.nome_fantasia}
                      aria-describedby="inputGroupPrepend"
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  {FormLabelModal("combo")}
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon="dice-d6" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      as="select"
                      name="combo_fk"
                      defaultValue={id}
                      onChange={(e) => onChange(e)}
                    >
                      <option disabled={false} value={""}>
                        Selecione um combo
                      </option>

                      {combos.length &&
                        combos.map((combo, i) => {
                          return (
                            <option key={i} value={combo.id}>
                              {combo.name}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  {FormLabelModal("data")}
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon="calendar" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      name="ends_in"
                      onChange={(e) => onChange(e)}
                      defaultValue={this.props.ends_in.substr(0, 10)}
                      type="date"
                      aria-describedby="inputGroupPrepend"
                    />
                  </InputGroup>
                </Form.Group>
                <div style={{ textAlign: "right" }}>
                  <Button
                    variant="outline-success"
                    size={"sm"}
                    onClick={(e) => this.handleUpdateContratoCloseModal(e)}
                  >
                    Editar
                  </Button>
                  <Button
                    variant="outline-danger"
                    size={"sm"}
                    onClick={() => this.handleCloseModal()}
                    style={{ marginLeft: "10px" }}
                  >
                    Fechar
                  </Button>
                </div>
              </Form>
            </Col>
          </div>
          {BottomModal}
        </Modal>
      </span>
    );
  }
}

export default ModalEditarContrato;
