import React, {Component} from 'react';
import {Card, Col,} from "react-bootstrap";
import {Line} from "react-chartjs-2";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  AlertaError,
  HandleMinutoDefault,
  HandleMinutoStart
} from "../../Stores/ControllerMinuto";
import {minutoAtual} from "../../Stores/ControllerData";
import {
  dadosMinutoComprasNet,
  dadosMinutoMsgComprasNet,
  optionsGragicos
} from "../../Themes/StyleGraficos";

class ComprasNetMinuto extends Component {
  state = {
    registroMinuto: [],
    licitacaoMinuto: dadosMinutoComprasNet(),
    msgAdicionada: dadosMinutoMsgComprasNet(),
    toLimit: 450,
  };

  licitacaoProcessada;
  licitacaoMonitorada;
  msgAdicionada;
  tempo;
  hora;

  atualizaKey() {
    let {licitacaoMinuto, msgAdicionada} = this.state;
    licitacaoMinuto.key = Math.floor(Math.floor(Math.random() * 10000));
    msgAdicionada.key = Math.floor(Math.floor(Math.random() * 10000));
    // this.setState({licitacaoMinuto: licitacaoMinuto});

    this.setState({
      licitacaoMinuto: licitacaoMinuto,
      msgAdicionada: msgAdicionada
    });
  }

  componentDidMount() {
    // carrega os minutos passados, esta função é chamada uma unica vez //
    HandleMinutoDefault('registros', (dataRegistros) =>
      this.setState({registroMinuto: dataRegistros,}),
    );

    // esta função é chamada sempre que o firebase recebe dados //
    setTimeout(() => {
      HandleMinutoStart('registros', (dataRegistros) =>
        this.setState({registroMinuto: dataRegistros,}),);
    }, 11000);

    //atuliaza a key para rederizar o novo grafico //
    setInterval(() => {
      this.atualizaKey();
      this.handleMinuto();
    }, 10000)
  }

  handleLimit(limit) {
    if (limit > this.state.toLimit) {
      this.setState({toLimit: limit + 100,})
    }
  }

  DefaultStatus(index) {
    let dados = this.state.licitacaoMinuto;
    dados.datasets[index].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.setState({licitacaoMinuto: dados});
  }

  DefaultStatusMsg(index) {
    let dados = this.state.msgAdicionada;
    dados.datasets[index].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.setState({msgAdicionada: dados});
  }

  saveDate(index, indexLabels, licitacao,) {
    let dados = this.state.licitacaoMinuto;
    dados.datasets[index].data[indexLabels] = licitacao;
    this.setState({licitacaoMinuto: dados});
  }

  saveDateMsg(index, indexLabels, licitacao,) {
    let dados = this.state.msgAdicionada;
    dados.datasets[index].data[indexLabels] = licitacao;
    this.setState({msgAdicionada: dados});
  }

  handleMinuto() {
    const {registroMinuto} = this.state;
    for (let i = 0; i < registroMinuto.length; i++) {

      let licitacaoProcessada = [];
      let licitacaoMonitorada = [];
      let tempo = [];
      let msgAdicionada = [];

      licitacaoProcessada.push(registroMinuto[i].licitacaoProcessada);
      tempo.push(registroMinuto[i].tempo);
      licitacaoMonitorada.push(registroMinuto[i].licitacaoMonitorada);
      msgAdicionada.push(registroMinuto[i].msgAdicionada);

      this.handleLimit(registroMinuto[i].licitacaoProcessada);
      this.handleLimit(registroMinuto[i].tempo);
      this.handleLimit(registroMinuto[i].licitacaoMonitorada);

      if (registroMinuto[i].tempo === 0) {
        AlertaError(registroMinuto[i])
      }

      let minutoAbreviado = parseInt(registroMinuto[i].hora.substr(-2, 2));
      const indexLabels = this.state.licitacaoMinuto.labels.indexOf(minutoAbreviado);

      if (indexLabels !== -1) {
        this.saveDate(0, indexLabels, licitacaoProcessada);
        this.saveDate(1, indexLabels, licitacaoMonitorada);
        this.saveDate(2, indexLabels, tempo);
        this.saveDateMsg(0, indexLabels, msgAdicionada);
      }
      if (minutoAtual() === 0) {
        this.DefaultStatus(0);
        this.DefaultStatus(1);
        this.DefaultStatus(2);
        this.DefaultStatusMsg(0);
      }
    }
  }



  render() {
    console.log(this.state.licitacaoMinuto)
    return (
      <>
        <Col md={12} style={{marginBottom: '10px'}}>
          <Card md={3} style={{background: '#e7e7e7'}}>
            <Col style={{marginTop: '5px', marginLeft: '12px'}}>
              <FontAwesomeIcon icon="chart-area"/>&nbsp;&nbsp; COMPRAS-NET
              MINUTO
            </Col>
            <Card.Body>
              <Line
                data={this.state.licitacaoMinuto}
                options={optionsGragicos(this.state.toLimit, 10)}
                largura={100} height={75}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={12} style={{marginBottom: '10px'}}>
          <Card md={3} style={{background: '#e7e7e7'}}>
            <Col style={{marginTop: '5px', marginLeft: '12px'}}>
              <FontAwesomeIcon icon="chart-area"/>&nbsp;&nbsp; COMPRAS-NET
              minuto
            </Col>
            <Card.Body>
              <Line data={this.state.msgAdicionada}
                    options={optionsGragicos(5, 25)}
                    largura={100} height={75}/>
            </Card.Body>
          </Card>
        </Col>
      </>
    );
  }
}

export default ComprasNetMinuto;
