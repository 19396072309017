import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons/index.es";
import { inject, observer } from "mobx-react";

const grad2 = {
  height: "130px",
  color: "white",
  background: "linear-gradient(141deg, #2cb5e8 0%, #1fc8db 51%, #0fb8ad 75%)",
  marginTop: "3.6%",
  marginLeft: "-0.8%",
  width: "101.6%",
};

@inject("MaquinaDeEstadoStores")
@observer
class InfoHeadEmpresa extends Component {
  render() {
    const { nome_fantasia, cnpj } = this.props.MaquinaDeEstadoStores.empresa;

    return (
      <Container fluid style={grad2}>
        <Row>
          <Col md={4} style={{ marginLeft: "30px", marginTop: "40px" }}>
            <h5>
              <FontAwesomeIcon icon={faBriefcase} />
              &nbsp; {nome_fantasia}
            </h5>
            <h6>CNPJ: &nbsp; {cnpj}</h6>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default InfoHeadEmpresa;
