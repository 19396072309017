import React, {Component} from 'react';
import {Line} from "react-chartjs-2";
import {Card, Col} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {dadosHora, optionsGragicos} from "../../Themes/StyleGraficos";
import {minutoAtual} from "../../Stores/ControllerData";
import {HandleHora} from "../../Stores/ControllerHora";

class ComprasNetHora extends Component {
    state = {
        registroHora: [],
        licitacaoHora: dadosHora(),
        toLimit: 80,
    };

    atualizaKey(){
        let atualizada = this.state.licitacaoHora;
        atualizada.key = Math.floor(Math.floor(Math.random() * 10000));
        this.setState({licitacaoHora: atualizada});
    }

    atualizaDados(){
        HandleHora('registros', (dataRegistros) =>
            this.setState({registroHora: dataRegistros})
        );
    }

    componentDidMount() {
        this.atualizaDados();
        // atuliaza a key para rederizar o novo grafico //
        setInterval(() => {
            // esta função é chamada sempre a hora muda //
            if (minutoAtual() === 0){
                this.atualizaDados()
            }
            this.atualizaKey();
            this.handleHora();
        },3000)
    }

    handleHora(){
        let licitacaoHora = this.state.licitacaoHora;
        licitacaoHora.datasets[0].data = this.state.registroHora;
        this.setState({licitacaoHora: licitacaoHora});

        for(let i = 0; i < this.state.registroHora.length; i++){
            if (this.state.registroHora[i] > this.state.toLimit){
                this.setState({toLimit:this.state.registroHora[i]+20})
            }
        }
    }

    render() {
        return (
            <Col md={12}>
                <Card md={3} style={{ background: '#e7e7e7'}}>
                    <Col style={{marginTop: '5px' }} >
                        <FontAwesomeIcon icon="chart-area" />&nbsp;&nbsp; COMPRAS-NET hora
                    </Col>
                    <Card.Body>
                        <Line data={this.state.licitacaoHora} options={optionsGragicos(this.state.toLimit, 10)}
                              largura = { 100 } height = { 75 }/>
                    </Card.Body>
                </Card>
            </Col>
        );
    }
}

export default ComprasNetHora;
