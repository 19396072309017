import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import TabelaDeContratos from "../Empresas/Contratos/TabelaDeContratos";
import { HeaderContrato } from "./HeaderContrato";
import { ContainerContrato } from "./styleContrato";
import UsersTable from "../Empresas/Contratos/UsersTable";

@inject("ClienteStores", "ContratosStores")
@observer
class IndexContrato extends Component {
  constructor(props) {
    super(props);
    this.state = {
      texto: "",
    };
  }

  render() {
    // const {users} = this.props.ClienteStores;
    const {
      razao_social,
      cnpj,

    } = this.props.ContratosStores.dadosEmpresa;
    return (
      <>
        <HeaderContrato
          razao_social={razao_social}
          cnpj={cnpj}

        />
        <div style={{ padding: "10px", height: "auto", overflow: "hidden" }}>
          <ContainerContrato>
            <div style={{ padding: "10px" }}>
              <TabelaDeContratos />
              <h3  style={{paddingLeft: '10px', color: '#585858'}}>Usuários:</h3>
              <UsersTable/>
            </div>
          </ContainerContrato>
        </div>
      </>
    );
  }
}

export default IndexContrato;
