import React from "react";
import {AlertCircle} from "react-feather";
import {ContainerAvisos} from "./StyleComponents";

export const AvisoEsclamacao = ({texto}) => {
    return (
        <ContainerAvisos>
            <AlertCircle color={"#3A566E"} size={"5rem"} />
            {texto}
        </ContainerAvisos>
    );
};
