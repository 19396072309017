import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import HendlerContrato from "./HeadlerContrato";
import BodyContratos from "./BodyContratos";
import { AvisoEsclamacao } from "../../ComponentsPadrao/MensagemArrayVazio";

@inject(
  "ContratosStores",
  "ComboStores",
  "ClienteEmpresaStores",
  "PlanosStores",
  "ClienteStores",
  "MaquinaDeEstadoStores"
)
@observer
class TabelaDeContratos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
  }

  render() {
    const { contratos, dadosEmpresa } = this.props.ContratosStores;
    return (
      <div>
        {contratos.length > 0 ? (
          contratos.map((contrato) => {
            return (
              <div
                style={{
                  background: "white",
                  padding: "10px",
                  borderRadius: "10px",
                  border: "1px solid rgb(223, 223, 223)",
                  marginBottom: "20px",
                  boxShadow: "0px 1px 8px 1px rgba(0,0,0,0.31)",
                }}
              >
                <HendlerContrato contrato={contrato} dadosEmpresa={dadosEmpresa}  />
                <BodyContratos contrato={contrato} />
              </div>
            );
          })
        ) : (
          <AvisoEsclamacao texto={"Empresa não possui contrato!"} />
        )}
      </div>
    );
  }
}

export default TabelaDeContratos;
