import React, { Component } from "react";
// import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
// import { faChartLine } from "@fortawesome/free-solid-svg-icons/faChartLine";
// import { faRobot } from "@fortawesome/free-solid-svg-icons/faRobot";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { inject, observer } from "mobx-react";
import { ContainerNavInfo } from "./styleNav";

@inject("ClienteStores", "ClienteEmpresaStores")
@observer
class InfoNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      texto: "",
    };
  }

  render() {
    return (
      <ContainerNavInfo height={this.props.height ? this.props.height : false}>
        <div style={{ marginTop: "10px" }}>
          <h4>
            {this.props.getIcon()} {this.props.title}
          </h4>
        </div>
      </ContainerNavInfo>
    );
  }
}

export default InfoNav;
