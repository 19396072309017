import styled from "styled-components";

export const Container = styled.div`
    display: flex;
	justify-content: center;
	justify-items: center;
	align-items: center;
	align-content: center;
    width: 100%;
    /* height: 100%; */
    padding: 25px 25px;
    flex-wrap: wrap;
`;
