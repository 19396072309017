import Api from "./Conexao/conexao";
import { observable, action } from "mobx";

import { AlertaError, AlertaSuccess } from "./Funcoes/FuncoesGerais";
import { globalDebug } from "./Funcoes/Debug";
import ModalLoading from "./ModalLoading";

class FeaturesStores {
  @observable description = "";
  @observable name = "";
  @observable features = [];
  @observable limit = "";
  @observable product_fk = "";
  @observable features_total = 0;
  @observable pagina_atual = 1;
  @observable allFeatures = []

  @action setCurrentPage = page => {
    this.pagina_atual = page
  }

  @action handleChangePageTable = (event, newPage) => {
    this.setCurrentPage(newPage);
    this.consultaFeatures();
  }; 

  @action consultaFeatures = async () => {
    ModalLoading.openModal();
    this.ChecksCuurentPage();
    try {
      const res = await Api.get("platform_features?page=" + this.pagina_atual)
      this.features_total = res.data.count;
      this.features = res.data.results; 
    }
    catch (err) {
      globalDebug("CONSULTA/FEATURES", err);
    }
    finally {
      ModalLoading.clouseModal();
    }
  };

  @action seeAllFeatures = async () => {
    ModalLoading.openModal();
    try {
      const res = await Api.get("platform_features_all")
      this.allFeatures = res.data; 
    }
    catch (err) {
      globalDebug("CONSULTA/FEATURES", err);
    }
    finally {
      ModalLoading.clouseModal();
    }
  };

  @action addFeatures = () => {
    Api.post("feature/add", {
      name: this.name,
      description: this.description,
      limit: this.limit,
      product_fk: this.product_fk,
    })
      .then(() => {
        AlertaSuccess("FEATURE " + this.name + " adicionado com sucesso!");
        this.consultaFeatures();
        this.ClearInputs();
      })
      .catch((error) => {
        globalDebug("ADD/FEATURES", error);
      });
    this.consultaFeatures();
  };

  @action UpdateFeaturesId = (id) => {
    if (this.ChecksInputs())
      Api.patch("feature/" + id, {
        name: this.name,
        description: this.description,
        limit: this.limit,
        product_fk: this.product_fk,
      })
        .then(() => {
          AlertaSuccess("FEATURE " + this.name + " editado com sucesso!");
          this.consultaFeatures();
          this.ClearInputs();
        })
        .catch((error) => {
          globalDebug("UpDate/FEATURE", error);
        });
  };

  @action delFeatures = (id, nome) => {
    Api.delete("feature/" + id)
      .then(() => {
        this.onPageChanged({ currentPage: 1 });
        AlertaSuccess("FEATURE  " + nome + " excluido com sucesso!");
        this.consultaFeatures();
      })
      .catch((error) => {
        globalDebug("DEL/FEATURES", error);
      });
  };

  @action ChecksInputs = () => {
    if (this.name === "" && this.limit === "" && this.description === "") {
      AlertaError("Campos NOME LIMIT E DESCRIÇÃO não podem ser em brancos!");
      return false;
    } else {
      if (this.name === "") {
        AlertaError("Campo NOME não pode ser em branco!");
        return false;
      }
      if (this.limit === "") {
        AlertaError("Campo Limite não pode ser em branco!");
        return false;
      }
      if (this.description === "") {
        AlertaError("Campo DESCRIÇÃO não pode ser em branco!");
        return false;
      }
      if (this.product_fk.length === 0) {
        AlertaError("Campo Poduto não pode ser em branco!");
        return false;
      }
      return true;
    }
  };

  @action ClearInputs = () => {
    this.name = "";
    this.description = "";
    this.limit = "";
    this.product_fk = [];
  };

  @action onChange = (evento) => {
    const campo = evento.target.name;
    this[campo] = evento.target.value;
  };

  @action ChecksCuurentPage = () => {
    if (this.pagina_atual === 0) {
      this.pagina_atual = 1;
    }
  };

  @action onPageChanged = (data) => {
    const { currentPage } = data;
    this.pagina_atual = currentPage;
    // this.consultaFeatures();
  };
}

const featuresStores = new FeaturesStores();
export default featuresStores;
