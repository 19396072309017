const debug = false;
// const debug = true;

export function globalDebug(nameApi, error) {
    if (debug)
        console.log("NÃO foi possivel conectar a: "+nameApi+ ' '+error.response.request.response)
}





