import React from "react";
import {Badge} from "react-bootstrap";
import ModalEditarEmpresaHome from "./ModalEmpresas/ModalEditarEmpresaHome";
import ModalExcluirBase from "../Modal/ModalExcluirBase";
import ClienteEmpresaStores from "../../Stores/ClienteEmpresaStores"

export const columnsEmpresa = [
  {
    name: 'ID',
    selector: 'id',
    sortable: true,
    hide: 'md',
    width: '100px',
    style: {
      justifyContent: 'center'
    },
  },
  {
    name: 'EMPRESA',
    selector: 'razao_social',
    sortable: true,
    style: {
      justifyContent: 'center'
    },
  },
  {
    name: 'CNPJ',
    selector: 'cnpj',
    sortable: true,
    style: {
      justifyContent: 'center'
    },
  },
  {
    name: 'TELEFONE',
    selector: 'phone',
    sortable: true,
    style: {
      justifyContent: 'center'
    },
  },
  {
    name: 'CLIENTES',
    style: {
      justifyContent: 'center'
    },
    cell: (row) => {
      return (row.user.map((item) => {
        return (
          <p
            key={item.id}
            style={{fontSize: '15px', padding: '5px'}}
            onClick={ClienteEmpresaStores.getUserInfo(item.id)}
          >
            <Badge
              pill
              variant={item.is_superuser ? 'success' : 'info'}
            >
              {item.first_name}
            </Badge>
          </p>
        )
      }))

    }
  },
  {
    name: 'EDITAR/EXCLUIR',
    style: {
      justifyContent: 'center'
    },
    cell: (row) =>
      <span>
        <ModalEditarEmpresaHome 
          empresa={row}
        />
        
        &nbsp;
        
        <ModalExcluirBase 
          nome={row.razao_social} 
          id={row.id}
          store={'ClienteEmpresaStores'}
        />
      </span>

  },
];


export const customStyles = {
  headRow: {
    style: {
      // borderTop: '1.2px solid #a5a5a5',
      borderBottom: '1.2px solid #a5a5a5',
    },
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
      textAlign: 'center',
      fontWeight: 'bolder',
      justifyContent: 'center'
    },
  },
  cells: {
    style: {
      textAlign: 'center'
    }
  },

  rows: {
    style: {
      textAlign: 'center'
    },
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(230, 244, 244)',
      borderBottomColor: '#FFFFFF',
      borderRadius: '10px',
      outline: '1px solid #FFFFFF',
    },
  },
  pagination: {
    style: {
      color: 'black'
    },
  },
};

export const paginationOptions = {
  rowsPerPageText: 'Filas por página',
  rangeSeparatorText: 'de'
};
