import { spawn, all, call } from 'redux-saga/effects';
import {editalSagas} from "../redux/loginRedux";

function* rootSagas() {
  const allSaga = [editalSagas];
  yield all(
    allSaga.map(saga =>
      spawn(function*() {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {

          }
        }
      })
    )
  );
}

export default rootSagas;
