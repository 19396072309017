import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import "@fortawesome/fontawesome-free";
import "./Themes/Icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Themes/ModalCSS.css";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import "react-s-alert/dist/s-alert-css-effects/scale.css";
import "react-s-alert/dist/s-alert-css-effects/bouncyflip.css";
import "react-s-alert/dist/s-alert-css-effects/flip.css";
import "react-s-alert/dist/s-alert-css-effects/genie.css";
import "react-s-alert/dist/s-alert-css-effects/jelly.css";
import "react-s-alert/dist/s-alert-css-effects/stackslide.css";
import "react-s-alert/dist/s-alert-css-effects/genie.css";
import "react-s-alert/dist/s-alert-default.css";

import { Provider } from "mobx-react";
import VedStores from "./Stores/VedStores";
import PortaisStores from "./Stores/PortaisStores";
import TiposPlanosStores from "./Stores/TiposPlanosStores";
import ProdutosStores from "./Stores/ProdutosStores";
import FeaturesStores from "./Stores/FeaturesStores";
import ComboStores from "./Stores/ComboStores";
import ClienteStores from "./Stores/ClienteStores";
import ClienteEmpresaStores from "./Stores/ClienteEmpresaStores";
import CredenciaisStores from "./Stores/CredenciaisStores";
import ContratosStores from "./Stores/ContratosStores";
import PlanosStores from "./Stores/PlanosStores";
import LoginStores from "./Stores/LoginStores";
import Login from "./Components/Login/login";
import EmpresaContratoStores from "./Stores/EmpresaContratoStores";
import ModalLoading from "./Stores/ModalLoading";
import MaquinaDeEstadoStores from "./Stores/MaquinaDeEstadoStores";
import ModalStores from "./Stores/ModalStores";

const stores = {
  ModalStores,
  MaquinaDeEstadoStores,
  ModalLoading,
  VedStores,
  PortaisStores,
  TiposPlanosStores,
  ProdutosStores,
  FeaturesStores,
  ComboStores,
  ClienteStores,
  ClienteEmpresaStores,
  CredenciaisStores,
  ContratosStores,
  PlanosStores,
  LoginStores,
  EmpresaContratoStores,
};

ReactDOM.render(
  <Provider {...stores}>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route render={(props) => <App {...props} />} />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
