import React, { useState } from "react";
import { useList } from "react-firebase-hooks/database";
import { Container } from "./styles";
import Nav from "../Header";
import { firebaseDatabase } from "../../services/firebaseUtils";
import BadegPortais from "./contexto/badegPortais";
import { format } from "date-fns";
import InfoNav from "../../../Nav/InfoNav";
import { faRobot } from "@fortawesome/free-solid-svg-icons/faRobot";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Portais = () => {
  const dataAtual = format(new Date(), "dd/MM/yyyy");
  const [data, setData] = useState(dataAtual);
  const ref = firebaseDatabase
    .ref()
    .orderByChild("date_start")
    .equalTo(data);
  const [snapshots, loading] = useList(ref);

  const upadteData = (data) => {
    setData(data);
  };

  return (
    <>
      <InfoNav
        title="Wavebots portais"
        height={"100px"}
        getIcon={() => <FontAwesomeIcon icon={faRobot} />}
      />
      <Nav status={snapshots} upadteData={upadteData} dia={data} />
      <Container>
        <BadegPortais data={snapshots} loading={loading} />
      </Container>
    </>
  );
};

export default Portais;
