import { firebaseDatabase } from "../utils/firebaseUtils";
import { dataAtual } from "./ControllerData";

import Alert from "react-s-alert";

export function AlertaError(dados) {
  let msg =
    "<span style='text-align: center'>DATA: <strong style='font-size: 20px'>" +
    dados.data +
    "</strong></span>" +
    "<br/>" +
    "HORA: <strong style='font-size: 20px'>" +
    dados.hora +
    "</strong style='font-size: 20px'></span><br/>" +
    "TEMPO: <strong style='font-size: 20px'>" +
    dados.tempo +
    "</strong style='font-size: 20px'></span>";
  return Alert.error(msg, {
    position: "bottom-right",
    effect: "genie",
    timeout: 300000,
    html: true,
    beep: "/somz.wav",
  });
}

export function HandleMinutoDefault(nodePath, callback) {
  let data = new Date();
  let query = firebaseDatabase
    .ref(nodePath)
    .orderByChild("data")
    .equalTo(dataAtual());
  query.once("value", (dataSnapshot) => {
    let items = [];
    dataSnapshot.forEach((childSnapshot) => {
      let item = childSnapshot.val();
      let horaAbreviada = parseInt(item.hora.substr(0, 2)) - 3;
      if (data.getHours() === horaAbreviada) {
        item["key"] = childSnapshot.key;
        items.push(item);
      }
    });
    callback(items);
    console.log("HandleMinutoDefault");
  });
  return query;
}

export function HandleMinutoStart(nodePath, callback, size = 1) {
  let query = firebaseDatabase.ref(nodePath).limitToLast(size);
  query.on("value", (dataSnapshot) => {
    let items = [];
    dataSnapshot.forEach((childSnapshot) => {
      let item = childSnapshot.val();
      item["key"] = childSnapshot.key;
      items.push(item);
    });
    callback(items);
  });
  return query;
}
