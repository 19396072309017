import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Header } from "./Header";

@inject("ClienteStores", "ContratosStores", "MaquinaDeEstadoStores")
@observer
class IndexContrato extends Component {
  constructor(props) {
    super(props);
    this.state = {
      texto: "",
    };
  }

  render() {
    const {
      user
    } = this.props.ClienteStores;

    window.onload = () => {
      const { redirect } = this.props.MaquinaDeEstadoStores;
      if (!user.email) {
        redirect("clientes");
      }
    }
    
    console.log(user)

    return (
      <>
        <Header
          name={`${user.first_name} ${user.last_name}`}
          email={user.email}
          phone={user.phone}
        />
        <div style={{ padding: "10px", height: "auto", overflow: "hidden" }}>
            <div style={{ padding: "10px" }}>
              {user.fornecedor && user.fornecedor.length ? user.fornecedor.map((business) => {
                return (
                  <div
                    style={{
                      background: "white",
                      padding: "10px",
                      borderRadius: "10px",
                      border: "1px solid rgb(223, 223, 223)",
                      marginBottom: "20px",
                      boxShadow: "0px 1px 8px 1px rgba(0,0,0,0.31)",
                    }}
                  >
                    <div
                      style={{
                        minHeight: "30px",
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "15px",
                        borderRadius: "7px",
                        color: "rgb(6,81,104)",
                        textTransform: "uppercase",
                      }}
                    >
                      <div style={{marginTop: "7px"}}>
                        <h6>{business.nome_fantasia}</h6>
                      </div>
                    </div>
                    <div style={{margin: '10px'}}>
                      <div style={{
                        border: '1px solid #dfdfdf',
                        padding: '20px',
                        marginBottom: '5px',
                        borderRadius: '5px',
                      }}>
                        <div style={{background: '#fff', borderRadius: '7px',}}>
                          <div style={{
                            display: 'flex',
                            fontSize: '12px',
                            fontWeight: 'bolder',
                          }}>
                            <div style={{width: '30%'}}>
                              {business.razao_social && <SubTitulo titulo={business.razao_social}/>}
                            </div>
                            <div style={{width: '30%'}}>
                              {business.cnpj && <SubTitulo titulo={business.cnpj}/>}
                            </div>
                            <div style={{width: '30%'}}>
                              {business.phone && <SubTitulo titulo={business.phone}/>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }) : (
                <h1 style={{
                  textAlign: 'center',
                  fontSize: '21px',
                  color: '#777777'
                }}
                >
                  Cliente não possui empresas
                </h1>
              )}
            </div>
        </div>
      </>
    );
  }
}

const SubTitulo = ({titulo}) => {
  return (
      <span style={{
          border: '1px solid #268ccf',
          color: '#268ccf',
          fontSize: '11px',
          padding: '5px 10px',
          borderRadius: '5px'
      }}>{titulo}</span>
  )
};

export default IndexContrato;
