import { Alert, Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import Modal from "react-modal";
import { BottomModal } from "../../Modal/BottomModal";
import React, { Component } from "react";
import MoldaHeardDanger from "../../Modal/MoldaHeardDanger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";

@inject("ContratosStores", "ComboStores", "MaquinaDeEstadoStores")
@observer
class ModalExcluirEmpresasContratos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
  }

  handleCloseModal = () => {
    this.setState({ isActive: false });
  };
  handleOpenModal = () => {
    this.setState({ isActive: true });
  };

  excluirContrato = () => {
    const { id, name } = this.props;
    this.props.ContratosStores.delContrato(id, name);
    this.setState({ isActive: false });
  };

  render() {
    const { name } = this.props;
    return (
      <span>
        <ButtonGroup aria-label="Basic example">
          <Button
            onClick={() => this.handleOpenModal()}
            style={{ fontSize: "13px", miniWidth: "70px" }}
            variant="danger"
            title="EDITAR REGISTRO"
          >
            <FontAwesomeIcon style={{ color: "white" }} icon={"trash"} />{" "}
          </Button>
        </ButtonGroup>

        <Modal
          className="Modal_Load"
          style={{
            content: {
              width: "520px",
              height: "350px",
              left: "47%",
              top: "38%",
            },
          }}
          overlayClassName="Overlay_Load"
          isOpen={this.state.isActive}
        >
          <MoldaHeardDanger
            icon={"trash"}
            titulo={"Excluir"}
            onClick={() => this.handleCloseModal()}
          />
          <br />
          <div>
            <Col md={12} style={{ textAlign: "center", marginTop: "1%" }}>
              <span style={{ color: "#EE5666", fontSize: "250%" }}>
                <FontAwesomeIcon icon={"user-times"} />
              </span>
              <h4>Deseja excluir o registro REGITRO da tabela? </h4>
              <Row className="justify-content-md-center">
                <Col
                  md={10}
                  style={{
                    textAlign: "center",
                    marginTop: "1%",
                    alignItems: "center",
                  }}
                >
                  <Alert
                    style={{ textAlign: "center", marginTop: "1%" }}
                    variant="info"
                  >
                    {name}
                  </Alert>
                </Col>
              </Row>
            </Col>
          </div>
          <Row>
            <Col md={12} className="text-center" style={{ marginTop: "20px" }}>
              <Button
                variant="outline-danger"
                onClick={() => this.handleCloseModal()}
              >
                Não
              </Button>
              <Button
                onClick={() => this.excluirContrato()}
                variant="outline-primary"
                style={{ marginLeft: "10px" }}
              >
                Sim
              </Button>
            </Col>
          </Row>
          {BottomModal}
        </Modal>
      </span>
    );
  }
}

export default ModalExcluirEmpresasContratos;
