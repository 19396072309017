import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  background: ${props => props.background};
  color: ${props => props.color};
  font-weight: bold;
  border-radius: 10px 0;
  width: 100%;
  height: 100%;
  padding: 10px 2px;
  margin-right: 13px;
  
  .icon{
  
    padding: 10px;
    text-align: center;
        svg{
        width: 30px;
        height: 30px;
    }
  }
  .textos{
    display: flex;
    flex-direction: column;
    min-width: 80px;
    padding-right: 5px;
    
    .number{
    color: white;
    font-size: 30px;
    }
    .name{
        font-size: 12px;
        font-weight: 600;
    }
    }
    
  @media (max-width: 1200px) 
  {
    .textos{
        width: auto;
    }
  }
  .number{
    font-size: 20px;
  }
  
  .name{
  font-size: 10px;
  }
`;

export const ContainerHora = styled.div`
  display: flex;
  color: white;
  flex-direction: column;
  
  .hora{
    font-weight: bold;
    font-size: 19px;
  }
  @media (max-width: 1200px) 
  {
   font-size: 14px;
  .hora
   {
       font-size: 14px;
   }
}
  
  
  
`;

export const ContainerSearch = styled.div`
  display: flex;
  color: white;
  width: 210px;
  z-index: -1;
`;


