import React, {Component} from 'react';
import {inject, observer} from 'mobx-react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Form, InputGroup} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {columnsCliente, customStyles, paginationOptions} from "../../Clientes/StyleTabela";
// import ModalCadastroClienteCompleto from "../../Clientes/ModalCliente/ModalCadastroClienteCompleto";
// import ModalCadClienteHome from "../../Clientes/ModalCliente/ModalCadCliente";
// import {AlertCircle, Users} from "react-feather";
import {ContainerNavInfo} from "../../Nav/styleNav";
import {AvisoEsclamacao} from "../../ComponentsPadrao/MensagemArrayVazio";


@inject('ClienteStores')
@observer
class TabClienteHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            texto: ''
        };
    };

    onChange = (e) => {
        this.setState({texto: e.target.value})
    };

    searchClientes = (user) => {
        return user.filter((item) => {
            return (item.first_name.toLowerCase().includes(this.state.texto.toLowerCase()))
                || (item.username.toLowerCase().includes(this.state.texto.toLowerCase()))
                || (item.phone.toLowerCase().includes(this.state.texto.toLowerCase()))
        })
    };

    render() {
        const {users} = this.props.ClienteStores;
        return (
            <>


                <ContainerNavInfo>
                    <div style={{marginBottom: '50px'}}>
                        <h4><FontAwesomeIcon icon={'users'}/> Clientes Cadastrados</h4>
                    </div>


                    <div style={{marginBottom: '40px', maxWidth: '500px', width: '30vw', marginRight: '22px'}}>
                        <Form.Group controlId="formBasicEmail">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend"><FontAwesomeIcon
                                        icon={'search'}/></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" placeholder="pesquisar cliente"
                                              value={this.state.texto}
                                              onChange={(e) => this.onChange(e)}/>
                            </InputGroup>
                        </Form.Group>
                    </div>

                    <div style={{marginBottom: '50px'}}>

                        <Button style={{marginRight: '20px'}} variant="success" size="sm">
                            <FontAwesomeIcon icon={'user-tie'}/> CADASTRO COMPLETO
                        </Button>

                        <Button variant="primary" size="sm">
                            <FontAwesomeIcon icon={'user-tie'}/> CADASTRAR CLIENTE
                        </Button>

                    </div>


                </ContainerNavInfo>

                <div style={{padding: '10px', height: 'auto', overflow: 'hidden',}}>


                    <div style={{
                        position: 'absolute',
                        top: '169px',
                        background: "white",
                        borderRadius: "10px",
                        paddingTop: '7px',
                        width: "99%",
                        height: "82vh",
                        boxShadow: "0px 1px 8px 1px #00000091"
                    }}>

                        <DataTable
                            columns={columnsCliente}
                            customStyles={customStyles}
                            allowOverflow={false}
                            overflowY={false}
                            data={this.searchClientes(users)}
                            pointerOnHover
                            striped
                            highlightOnHover
                            pagination
                            paginationRowsPerPageOptions={[14, 20, 50, 100, 200, 900]}
                            paginationPerPage={14}
                            noDataComponent={<AvisoEsclamacao texto={'Cliente não localizado'}/>}
                            paginationComponentOptions={paginationOptions}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default TabClienteHome;

