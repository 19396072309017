import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Row, Table } from "react-bootstrap";
import ModalExcluirBase from "../../Modal/ModalExcluirBase";
import Pagination from "@material-ui/lab/Pagination";
import MoldaEditarPortal from "../ModalConfiguracoes/MoldaEditarPortal";

const TabPortaisConf = inject("PortaisStores")(
  observer(({ PortaisStores }) => {
    const {
      consultaPortal,
      portal,
      delPortal,
      pagina_atual,
      portal_total,
    } = PortaisStores;
    return (
      <TabelaPortais
        consultaPortal={consultaPortal}
        portal={portal}
        delPortal={delPortal}
        pagina_atual={pagina_atual}
        portal_total={portal_total}
      />
    );
  })
);

const TabelaPortais = ({
  consultaPortal,
  portal_total,
  portal,
  delPortal,
  pagina_atual,
}) => {
  useEffect(() => {
    consultaPortal(Number(1));
  }, []);

  const onPageChanged = (e, value) => {
    consultaPortal(value);
  };

  return (
    <Row
      style={{
        paddingLeft: "0.8%",
        paddingRight: "0.8%",
        marginTop: "1%",
        marginBottom: "5%",
        fontSize: "15px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Table striped bordered condensed hover>
        <thead style={{ verticalAlign: "middle", textAlign: "center" }}>
          <tr>
            <th style={{ width: "5%", textAlign: "center" }}>#</th>
            <th style={{ width: "15%" }}>NOME TIPO</th>
            <th style={{ width: "40%" }}>DESCRIÇÃO</th>
            <th style={{ width: "15%" }}>URL</th>
            <th style={{ width: "10%", textAlign: "center" }}>AÇÃO</th>
          </tr>
        </thead>
        <tbody>
          {portal.map((portal, i) => {
            return (
              <tr key={portal.id}>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  {portal.id}
                </td>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  {portal.nome}
                </td>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  {portal.descricao}
                </td>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  {portal.url}
                </td>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  <MoldaEditarPortal portal={portal} />
                  &nbsp;
                  <ModalExcluirBase
                    nome={portal.nome}
                    onClick={() => delPortal(portal.id, portal.nome)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Pagination
        count={Math.ceil(portal_total / 50)}
        onChange={onPageChanged}
        variant="outlined"
        color="primary"
      />
    </Row>
  );
};

// @inject("PortaisStores")
// @observer
// class TabPortaisConf extends Component {
//   componentDidMount() {
//     this.props.PortaisStores.consultaPortal();
//   }

//   render() {
//     return (
//       <Row
//         style={{
//           paddingLeft: "0.8%",
//           paddingRight: "0.8%",
//           marginTop: "1%",
//           marginBottom: "5%",
//           fontSize: "15px",
//         }}
//       >
//         <Table striped bordered condensed hover>
//           <thead style={{ verticalAlign: "middle", textAlign: "center" }}>
//             <tr>
//               <th style={{ width: "5%", textAlign: "center" }}>#</th>
//               <th style={{ width: "15%" }}>NOME TIPO</th>
//               <th style={{ width: "40%" }}>DESCRIÇÃO</th>
//               <th style={{ width: "15%" }}>URL</th>
//               <th style={{ width: "10%", textAlign: "center" }}>AÇÃO</th>
//             </tr>
//           </thead>
//           <tbody>
//             {this.props.PortaisStores.portal.map((portal, i) => {
//               return (
//                 <tr key={portal.id}>
//                   <td style={{ textAlign: "center", verticalAlign: "middle" }}>
//                     {portal.id}
//                   </td>
//                   <td style={{ textAlign: "center", verticalAlign: "middle" }}>
//                     {portal.nome}
//                   </td>
//                   <td style={{ textAlign: "center", verticalAlign: "middle" }}>
//                     {portal.descricao}
//                   </td>
//                   <td style={{ textAlign: "center", verticalAlign: "middle" }}>
//                     {portal.url}
//                   </td>
//                   <td style={{ textAlign: "center", verticalAlign: "middle" }}>
//                     <MoldaEditarPortal portal={portal} />
//                     &nbsp;
//                     <ModalExcluirBase
//                       nome={portal.nome}
//                       onClick={() =>
//                         this.props.PortaisStores.delPortal(
//                           portal.id,
//                           portal.nome
//                         )
//                       }
//                     />
//                   </td>
//                 </tr>
//               );
//             })}
//           </tbody>
//         </Table>
//         <Pagination
//           totalRecords={this.props.PortaisStores.portal_total}
//           pageLimit={20}
//           pageNeighbours={1}
//           defaultCurrent={this.props.PortaisStores.pagina_atual}
//           onPageChanged={this.props.PortaisStores.onPageChanged}
//           key={this.props.PortaisStores.portal_total}
//         />
//       </Row>
//     );
//   }
// }

export default TabPortaisConf;
